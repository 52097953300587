import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DoormanDialogData } from 'src/app/components/support/building/doorman/doorman.component'
import { DoormanService } from 'src/app/core/services/doorman.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-doorman',
  templateUrl: './create-doorman.component.html'
})
export class CreateDoormanComponent implements OnInit {

  formGroup: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder, private doormanService: DoormanService, @Inject(MAT_DIALOG_DATA) public data: DoormanDialogData, public dialogRef: MatDialogRef<CreateDoormanComponent>) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'first_name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'last_name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'cellphone': [null, [Validators.required, Validators.maxLength(15), Validators.minLength(10)]],
      'document_id': [null, [Validators.required, Validators.maxLength(15), Validators.minLength(6)]],
    });
  }

  get firstName() {
    return this.formGroup.get('first_name') as UntypedFormControl
  }

  get lastName() {
    return this.formGroup.get('last_name') as UntypedFormControl
  }

  get cellphone() {
    return this.formGroup.get('cellphone') as UntypedFormControl
  }

  get documentId() {
    return this.formGroup.get('document_id') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.doormanService.createDoorman(form, this.data.id).subscribe(
      data => {
        this.formGroup.reset()
        Swal.close()
        showMessage('¡Completo!', data.message, true)
        this.dialogRef.close(data.doorman)
      }, error => {
        showMessage("¡Error!", error, false)
      })
  }

  close(): void {
    this.dialogRef.close(false)
  }

}