import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Payment } from '../payment.component';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html'
})
export class PaymentDetailComponent implements OnInit {
  @Input() payment: Payment

  formGroup: UntypedFormGroup
  titleAlert: string = "Este espacio es requerido"

  constructor(private fb: UntypedFormBuilder) { 
  }
  
  ngOnInit(): void {
  }

}
