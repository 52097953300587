import { HttpErrorResponse } from "@angular/common/http"
import { ErrorHandler, Injectable } from "@angular/core"
import { Observable, throwError } from "rxjs"
import Swal from "sweetalert2"
import { Admin } from "../models/admin"
import { Apartment } from "../models/apartment"
import { Block } from "../models/block"
import { Building } from "../models/building"
import { City } from "../models/city"
import { Place } from "../models/place"

export interface DataType {
    id: number;
    name: string;
}

export interface BuildingDialogData {
    id: number,
    cities: City[],
    building: Building,
    block: Block,
    apartment: Apartment,
    admin: Admin
}

export interface PlaceDialogData {
    id: number,
    building: Building,
    place: Place
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler { 

    handleError(error: HttpErrorResponse): Observable<any> {
        console.log(error)
        if (error.error instanceof ErrorEvent) {
            console.log("error 1")
            return throwError('No se pudo conectar al servidor, por favor intente nuevamente o consulte con soporte técnico.')
        } else if(error.status !== undefined) {
            if(error.status == 0 || error.status === undefined || error.status == 500 || error.status == 404){
                console.log("error 2")
                return throwError('No se pudo conectar al servidor, por favor intente nuevamente o consulte con soporte técnico.')
            }
            console.log("error 3")
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`)
            return throwError(`${error.error.message}`)
        }
        console.log("error 4")
        return throwError('No se pudo conectar al servidor, por favor intente nuevamente o consulte con soporte técnico.')
    }
}

export function showMessage(title: string, text: string, success: boolean ) {
    if(success){
        Swal.fire({
            icon: 'success',
            title: title,
            text: text
        })
    } else {
        Swal.fire({
            icon: 'error',
            title: title,
            text: text
        })
    }
}