<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <h1>Mensajes</h1>
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <button type="button" class="btn" (click)="createMessage()">Nuevo +</button>
            </div>
        </div>
        <div class="card-body messages">
            <div class="row">
                <div class="col-md-3 list">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let message of messages" (click)="getMessage(message)">
                            <span>{{ message.getDate() }}<br>{{ message.getTime() }}</span>
                            <p>{{ message.subject }}</p>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9 detail">
                    <mat-spinner *ngIf="message.id < 0" [diameter]="50"></mat-spinner>
                    <div class="container" *ngIf="message.id > 0">
                        
                        <div class="info">
                            <div class="row">
                                <label for="subject" class="col-sm-3 col-form-label">Remitente:</label>
                                <p class="col-sm-9" id="subject">{{ message.admins }}</p>
                            </div>
                            <div class="row">
                                <label for="subject" class="col-sm-3 col-form-label">Fecha:</label>
                                <p class="col-sm-9" id="subject">{{ message.getDate() }}</p>
                            </div>
                            <div class="row">
                                <label for="subject" class="col-sm-3 col-form-label">Asunto:</label>
                                <p class="col-sm-9 mb-0" id="subject">{{ message.subject }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="message-body">
                            <p>{{ message.message }}</p>
                            <ul class="list-group files">
                                <li class="list-group-item" *ngFor="let file of files">
                                    <a [href]="file.file" target="_blank">
                                        <div class="preview" [style.background-image]="'url(&quot;' + file.file + '&quot;)'" *ngIf="file.checkImage()"></div>
                                        <div class="preview" [style.background-image]="'url(&quot;' + file.getFileIcon() + '&quot;)'" *ngIf="file.checkFile()"></div>
                                        <h2>{{ file.name }}</h2>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!--div class="form-group row message-body">
                            <label for="subject" class="col-sm-2 col-form-label mb-1">Fecha:</label>
                            <p class="col-sm-10" id="subject">
                              {{ message.getDate() }}
                            </p>
                        </div>
                        <hr>
                        <div class="message-body">
                            
                            
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>