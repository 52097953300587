import {Desearizable} from './desearizable';

export enum Role {
  SuperAdmin = 1,
  Admin = 2,
  Support = 3
}

export class Support implements Desearizable {
  public id: number;
  public email: string;
  public password: string;
  public first_name: string;
  public last_name: string;
  public role: number;
  public status: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getType(): string {
    switch (this.role) {
      case Role.SuperAdmin:
        return "super administrador";
      case Role.Admin:
        return "administrador";
      case Role.Support:
        return "soporte";
      default:
        return "";
    }
  }

  getStatus(): string {
    if (this.status) {
      return 'activo';
    } else {
      return 'inactivo';
    }
  }
}

