<div class="container modal-ag">
    <h2>Editar Portero</h2>
    <form [formGroup]="formGroup" (ngSubmit)= "onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="first_name">
            <mat-error *ngIf="!first_name.valid && first_name.touched">
                Debe ingresar un nombre válido.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="last_name">
            <mat-error *ngIf="!last_name.valid && last_name.touched">
                Debe ingresar un apellido válido.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Celular</mat-label>
            <ngx-mat-intl-tel-input
                [preferredCountries]="['co']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                describedBy="phoneInput"
                formControlName="cellphone">
            </ngx-mat-intl-tel-input>
            <mat-error *ngIf="!cellphone.valid && cellphone.touched">
                Debe ingresar un número de celular válido.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Documento</mat-label>
            <input matInput formControlName="document_id">
            <mat-error *ngIf="!document_id.valid && document_id.touched">
                Debe ingresar un número de documento válido.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state" >
                <mat-option *ngFor="let state of states" [value]="state.id">
                    {{ state.name }}
                </mat-option>
            </mat-select>
          <mat-error *ngIf="!state.valid && state.touched">
            Por favor seleccione un estado
          </mat-error>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form>
</div>
