import { Component, OnInit } from '@angular/core'
import { DataType, showMessage } from 'src/app/core/util/common'
import { MessageService } from 'src/app/core/services/message.service'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { MatDialogRef } from '@angular/material/dialog'
import { Block } from 'src/app/core/models/block'
import { BuildingService } from 'src/app/core/services/building.service'
import { Building } from 'src/app/core/models/building'
import { Apartment } from 'src/app/core/models/apartment'
import { ApartmentService } from 'src/app/core/services/apartment.service'

@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html'
})
export class CreateMessageComponent implements OnInit {

  types: DataType[] = [
    {id: 1, name: 'Todos'},
    {id: 2, name: 'Torres'},
    {id: 3, name: 'Apartamentos'}
  ]

  userTypes: DataType[] = [
    {id: 1, name: 'Arrendador'},
    {id: 2, name: 'Arrendatario'},
    {id: 3, name: 'Ambos'}
  ];

  buildingBlocks: Block[] = []
  blockApartments: Apartment[] = []
  files: any[] = []

  formGroup: UntypedFormGroup

  constructor(private buildingService: BuildingService, private apartmentService: ApartmentService, private messageService: MessageService, private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CreateMessageComponent>) {
    this.createForm()
    buildingService.getBuilding().subscribe((data: Building) => {
      this.buildingBlocks = data.blocks
      this.buildingBlocks.forEach(block => {
        this.blocks.push(formBuilder.control(0))
      })
    })
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      type: [1],
      subject: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(200)]],
      message: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(600)]],
      blocks: this.formBuilder.array([]),
      apartments: this.formBuilder.array([]),
      block_id: [null],
      role: [null, Validators.required],
      file: [null],
      fileSource: this.formBuilder.array([]),
    })
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      console.log(file)
      this.files.push(file)
      this.tfiles.controls.push(this.formBuilder.control(false))

      //this.formGroup.patchValue({
      //  fileSource: file,
      //})

      //console.log(this.tfiles)
    }
  }

  get subject() {
    return this.formGroup.get('subject') as UntypedFormControl
  }

  get message() {
    return this.formGroup.get('message') as UntypedFormControl
  }

  get type() {
    return this.formGroup.get('type') as UntypedFormControl
  }

  get blocks(): UntypedFormArray {
    return this.formGroup.get('blocks') as UntypedFormArray
  }

  get apartments(): UntypedFormArray {
    return this.formGroup.get('apartments') as UntypedFormArray
  }

  get file() {
    return this.formGroup.get('file') as UntypedFormControl
  }

  get tfiles(): UntypedFormArray {
    return this.formGroup.get('fileSource') as UntypedFormArray
  }

  loadApartments(id) {
    this.blockApartments = []
    this.apartments.clear()
    this.apartmentService.getApartmentBlock(id).subscribe(data => {
      this.blockApartments = data
      this.blockApartments.forEach(block => {
        this.apartments.push(this.formBuilder.control(0))
      })
    }, error => {
      
    })
  }

  changeBlock(checkboxValue, i, id) {
    this.blocks.controls[i].setValue(checkboxValue ? id : 0)
  }

  changeApartment(checkboxValue, i, id) {
    this.apartments.controls[i].setValue(checkboxValue ? id : 0)
  }

  onSubmit(form) {
    Swal.showLoading()
    let selectedBlocks = this.blocks.value.filter(id => id != 0)
    let selectedApartments = this.apartments.value.filter(id => id != 0)

    if(this.type.value == 2 && selectedBlocks.length == 0){
      Swal.close()
      showMessage('Error', 'Debe seleccionar al menos una torre.', false)
      return
    }

    if(this.type.value == 3 && selectedApartments.length == 0){
      Swal.close()
      showMessage('Error', 'Debe seleccionar al menos un apartamento.', false)
      return
    }

    this.messageService.sendMessage(form, selectedBlocks, selectedApartments, this.files)
      .subscribe(data => {
        Swal.close()
        showMessage('Enviado', data.message, true)
        this.dialogRef.close(data.mmessage)
      }, error => {
        showMessage('¡Error!', error, true)
      })
  }

  close() {
    this.dialogRef.close(false)
  }

}
