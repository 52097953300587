import { Directive, Input, HostListener } from '@angular/core'

@Directive({
    selector: '[validateOnBlur]', 
})
export class FocusDirective {

    @Input('validateFormControl') validateFormControl

    constructor() { }

    @HostListener('focus', ['$event.target'])
    onFocus(target) {
        this.validateFormControl.markAsTouched()
    }

    @HostListener('focusout', ['$event.target'])
    onFocusout(target) {
      this.validateFormControl.markAsTouched()
    }

}