<div class="container modal-ag">
    <h2>Editar Dependencia</h2>
    <!--form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off">
            <div *ngIf="!name.valid && name.touched">
                <mat-error *ngIf="name.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="name.errors.minlength">El nombre ingresado debe contener al menos 4 caracteres.</mat-error>
                <mat-error *ngIf="name.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form-->
</div>