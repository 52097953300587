import { DatePipe } from '@angular/common'
import { Desearizable } from './desearizable'

export class RequestMessage implements Desearizable {

    public id: number
    public requestId: number
    public message: string
    public user_id: number
    public admin_id: number
    public created_at: string
    
    public user: string
    public admin: string
    public files: string

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getDate(): string{
        let datepipe = new DatePipe('en-US')
        return datepipe.transform(this.created_at, 'dd-MM-yyyy')
    }

    getTime(): string{
        let datepipe = new DatePipe('en-US')
        return datepipe.transform(this.created_at, 'h:mm a')
    }

    getMDate(): string{
        return this.created_at.replace(" ", "<br>")
    }

    getFiles(): string[] {
        if(this.files) {
            return this.files.split(',')
        }
        return []
    }

}