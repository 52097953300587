<app-website-header [isHome]="false"></app-website-header>

<div class="website container">
    <article>
        <h1>El origen.</h1>
        <div class="about-bck">

        </div>
        <h2>Visión</h2>
        <p>Biru hace parte de Solutech Holding SAS, surge como una solución para digitalización de documentación en las propiedades horizontales. Siendo los usuarios nuestro principal enfoque a la hora de mejorar los procesos de comunicación y de seguridad.</p>

        <h2>Equipo</h2>
        <p>Contamos con un apasionado grupo de programadores y diseñadores que buscan revolucionar las soluciones técnologicas en latinomarican. </p>

    </article>
</div>

<app-website-footer></app-website-footer>