import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { GlobalErrorHandler } from '../util/common'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { map, catchError } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { BlogResponse } from '../reponse/data.response'
import { Blog } from '../models/blog'

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  httpOptions: {}
  errorHandler = new GlobalErrorHandler()
  token = ''
  blog: Blog

  selectedBlog = new EventEmitter<Blog>()
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : "")
    });
    this.updateToken(localStorage.getItem("token"))
  }

  updateToken(token) {
    this.token = token
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getUploadToken() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': this.token
      })
    };
  }

  getUploadFileToken() {
    return {
      headers: new HttpHeaders({
        'access-token': this.token,
        'enctype': 'multipart/form-data'
      })
    };
  }

  getBlogs(): Observable<Blog[]> {

    return this.http.get<Blog[]>(
      `${AppSettings.url}/post/all`, this.getUploadToken()
    ).pipe(

      map(data => {
        return data
      },
        catchError(error => {
          return this.errorHandler.handleError(error)
        }))
    )
  }

  getBlogBySlug(slug: string): Observable<Blog> {
    return this.http.get<Blog>(
      `${AppSettings.url}/post/slug/${slug}`, this.getUploadToken()
    ).pipe(
      map(data => {
        return data
      },
        catchError(error => {
          return this.errorHandler.handleError(error)
        }))
    )
  }

  createBlog(form): Observable<BlogResponse> {
    console.log(form.fileSource)
    const formData = new FormData()
    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('content', form.content)
    formData.append('status', form.status)
    formData.append('image', form.fileSource)
    formData.append('tag', form.tag)
    formData.getAll('title')
    console.log(formData.getAll('title'))
    return this.http.post<Blog>(
      `${AppSettings.url}/post`, formData, this.getUploadFileToken()
    ).pipe(
      map(data => {
        const blog = data["blog"]
        const message = data['meesage']
        return new BlogResponse(blog, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}


