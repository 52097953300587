import { Desearizable } from './desearizable'

export class ContactMail implements Desearizable {

    public id: number = -1
    public email: string
    public note: string
    public contact_id: number
    
    deserialize(input: any): this {
      Object.assign(this, input)
      return this
    }
  
}