<div class="container admin-container">
  <div class="card">
    <div class="card-header">
      <h1>Subscripciones</h1>
      <!--div class="options">
        <mat-form-field class="angularformcss no-line " appearance="none" floatLabel="never">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" autocomplete="off">
        </mat-form-field>
      </div-->
    </div>

    <!--div class="card-body">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="building">
          <th mat-header-cell *matHeaderCellDef>Edificio</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="date_start">
          <th mat-header-cell *matHeaderCellDef> Fecha inicio</th>
          <td mat-cell *matCellDef="let element"> {{element.date_start}} </td>
        </ng-container>

        <ng-container matColumnDef="date_end">
          <th mat-header-cell *matHeaderCellDef> Fecha final</th>
          <td mat-cell *matCellDef="let element"> {{element.date_end}} </td>
        </ng-container>

        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef> Plan</th>
          <td mat-cell *matCellDef="let element"> {{element.plan}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Estado</th>
          <td mat-cell *matCellDef="let element"> {{getStatus(element.status)}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let subscription">
            <button class="btn btn-link" type="button" data-toggle="modal" data-target="#exampleModal">
              <mat-icon aria-hidden="false">more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div-->
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Pagos Realizados</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <!--li class="list-group-item " *ngFor="let subcription of subcriptions">{{subcription.number_billing}} <br> <p> {{subscription.date_billing}} </p> <br> <p> {{subscription.billing_status}} <br> <p> {{subscription.date_payments}} </p> </p>
          Aca toca cuadrar el service, para que funcione el arreglo
          </li-->
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
