import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AdminService } from '../../../../../core/services/admin.service'
import { AdminDialogData } from '../../building-admin/building-admin.component'
import Swal from 'sweetalert2'
import { DataType, showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-create-building-admin',
  templateUrl: './create-building-admin.component.html'
})
export class CreateBuildingAdminComponent implements OnInit {

  titles: DataType[] = [
    {id: 1, name: 'Señor'},
    {id: 2, name: 'Señora'}
  ]

  formGroup: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder, private adminService: AdminService, @Inject(MAT_DIALOG_DATA) public data: AdminDialogData, public dialogRef: MatDialogRef<CreateBuildingAdminComponent>) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'title': [null, [Validators.required]],
      'first_name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'last_name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'email': [null, [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
  }

  get firstName() {
    return this.formGroup.get('first_name') as UntypedFormControl
  }

  get lastName() {
    return this.formGroup.get('last_name') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.adminService.createAdmin(form, this.data.id).subscribe(
      data => {
        this.formGroup.reset()
        Swal.close()
        showMessage('Exito', 'Se ha creado con exito el administrador.', true)
        this.dialogRef.close(data)
      }, error => {
        showMessage("Error", error.error.message, false)
      })
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
