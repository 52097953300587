import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Place } from '../models/place'
import { GlobalErrorHandler } from '../util/common'
import { PlaceResponse } from '../reponse/data.response'

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  
  userSelected = new EventEmitter<Place>()
  errorHandler = new GlobalErrorHandler()
  url = ''
  token = ''
  httpOptions: {}

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    });
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    this.token = token
  }

  getUploadImageToken() {
    return  {
      headers: new HttpHeaders({
        'access-token': this.token,
        'enctype': 'multipart/form-data'
      })
    };
  }

  getUploadToken() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': this.token
      })
    };
  }

  getPlaceID(id: number): Observable<Place> {
    return this.http.get<Place>(
      `${this.url}/building/place/one/${id}`, this.getUploadToken()
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  getPlaces(): Observable<Place[]>{
    console.log("Places")
    return this.http.get<Place[]>(
      `${this.url}/building/places/all`, this.getUploadToken()
    ).pipe(
      map(resp => {
        return resp.map(place => new Place().deserialize(place))
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createPlace(form, building_id: number): Observable<PlaceResponse> {
    const formData = new FormData()
    formData.append('building_id', building_id.toString())
    formData.append('name', form.name)
    formData.append('description', form.description)
    formData.append('price_type', form.price_type)
    formData.append('image', form.fileSource)
    formData.append('price', form.price)
    formData.append('cancellation_type', form.cancellation_type)
    formData.append('cancellation_minimum', form.cancellation_minimum)

    return this.http.post<Place>(
      `${this.url}/building/place`, formData, this.getUploadImageToken()
    ).pipe(
      map(data => {
        const place = new Place().deserialize(data["place"])
        const message = data["message"]
        return new PlaceResponse(place, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updatePlace(form, id: number): Observable<PlaceResponse> {
    const formData = new FormData()
    formData.append('name', form.name)
    formData.append('description', form.description)
    formData.append('place_type_id', "1")
    formData.append('price_type', form.price_type)
    formData.append('image', form.fileSource)
    formData.append('price', form.price)
    formData.append('cancellation_type', form.cancellation_type)
    formData.append('cancellation_minimum', form.cancellation_minimum)

    return this.http.put<Place>(
      `${this.url}/building/place/${id}`, formData, this.getUploadImageToken()
    ).pipe(
      map(data => {
        const place = new Place().deserialize(data["place"])
        const message = data["message"]
        return new PlaceResponse(place, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }


  putPlace(form, id: number): Observable<Place> {
    return this.http.put<Place>(
      `${AppSettings.url}/building/places/${id}`, form, this.getUploadImageToken()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }
}
