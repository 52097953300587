import { Injectable } from '@angular/core'
import { Support } from '../models/support.modules'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable } from 'rxjs'
import { catchError, map} from 'rxjs/operators'
import { Router } from '@angular/router'
import { User } from '../models/users'
import { MessageResponse, UserResponse } from '../reponse/data.response'
import { GlobalErrorHandler } from '../util/common'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  errorHandler = new GlobalErrorHandler()
  httpOptions: {}
  url = ""

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    });
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    //this.url = AppSettings.url + (sessionType ? `/${String(sessionType).split('"').join('')}` : "")
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  updateDefaultToken(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token,
        'Authorization': `Bearer ${token}`
      })
    }
  }

  getSupport(): Observable<Support[]> {
    return this.http.get<Support[]>(
      AppSettings.url + '/support', this.httpOptions
    ).pipe(
      map(data => data.map(data => new Support().deserialize(data)))
    );
  }

  getSupportID(id: number): Observable<Support> {
    return this.http.get<Support>(
      `${AppSettings.url}/support/${id}`, this.httpOptions
  ).
    pipe(
      map(resp => {
       let support = new Support().deserialize(resp);
        return support;
      })
    );
  }

  getUserID(id: number): Observable<User> {
    return this.http.get<User>(
      `${AppSettings.url}/user/${id}`, this.httpOptions
    ).
    pipe(
      map(resp => {
        let user = new User().deserialize(resp);
        return user;
      })
    );
  }

  PostSupport(form): Observable<Support> {
    if (form.state === 1){
      form.state = true;
    }else{
      form.state = false;
    }
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      password: form.password,
      role: form.role,
      status: form.state,
    };
    return this.http.post<Support>(
      AppSettings.url + '/support/register', data, this.httpOptions
  ).
    pipe(
      map(data => {
        return data;
      })
    );
  }

  createUser(form, id: number): Observable<UserResponse> {
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone.toString(),
      apartment_id: id,
      role: form.user_type,
      status: form.state == 1 ? true : false,
    }
    return this.http.post<User>(
      `${this.url}/user/register`, data, this.httpOptions
    ).
    pipe(
      map(data => {
        const user = data["user"]
        user.role = data["role"]
        const message = data["message"]
        return new UserResponse(user, message)
      }), 
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  putSupport(form, id: number): Observable<Support> {
    if (form.state === 1){
      form.state = true;
    }else{
      form.state = false;
    }
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      password: form.password,
      role: form.role,
      status: form.state,

    };
    return this.http.put<Support>(
      `${AppSettings.url}/support/${id}`, data, this.httpOptions
  ).
    pipe(
      map(data => {
        return data;
      })
    );
  }

  updateUser(form, id: number, apartment_id: number): Observable<UserResponse> {
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email.replace(/\s/g, "").toLowerCase(),
      phone: form.phone.replace(/[^0-9]/g, "").toLowerCase(),
      role: form.user_type,
      apartment_id: apartment_id,
      status: form.state == 1 ? true : false,
    }
    return this.http.put<UserResponse>(
      `${this.url}/user/update/${id}`, data, this.httpOptions
    ).
    pipe(
      map(data => {
        const user = data["user"]
        user.role = data["role"]
        const message = data["message"]
        return new UserResponse(user, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  activeAccount(iv: string, token: string): Observable<string> {
    const authData = {
      iv: iv,
      token: token
    };
    return this.http.post<string>(
      `${AppSettings.url}/user/active`, authData
    ).pipe(
      map(resp => {
        return resp['token']
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateActivePassword(form, token): Observable<string> {
    console.log(token)
    const data = {
      password: form.password
    }
    this.updateDefaultToken(token)
    return this.http.post<string>(
      `${AppSettings.url}/user/active/password`, data, this.httpOptions
    ).pipe(
      map(resp => {
        return resp['message']
      }), 
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  sendActivation(id: number): Observable<MessageResponse> {
    const data = {
      id: id
    }
    return this.http.post<MessageResponse>(
      `${AppSettings.url}/admin/user/mail/activate`, data, this.httpOptions
    ).pipe(
      map(data => {
        return new MessageResponse(null, data.message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  
}