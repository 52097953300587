import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Building } from 'src/app/core/models/building'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { BuildingDialogData, showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-apartment',
  templateUrl: './create-apartment.component.html'
})
export class CreateApartmentComponent implements OnInit {

  id = -1
  formGroup: UntypedFormGroup
  building: Building

  constructor(private fb: UntypedFormBuilder, private apartmentService: ApartmentService, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<CreateApartmentComponent>) {
    this.building = this.data.building
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(1)]],
      'footage': [null, [Validators.maxLength(10), Validators.pattern("\\-?\\d*\\.?\\d{1,2}")]],
      'coefficient': [null, [Validators.maxLength(15), Validators.pattern("\\-?\\d*\\.?\\d{1,10}")]]
    });
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get footage() {
    return this.formGroup.get('footage') as UntypedFormControl
  }

  get coefficient() {
    return this.formGroup.get('coefficient') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.apartmentService.createApartment(form, this.data.id, this.data.block.id).subscribe(
      data => {
        showMessage('¡Guardado!', data.message, true)
        this.dialogRef.close(data.apartment)
      }, error => {
        showMessage('¡Error!', error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}