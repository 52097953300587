import { Desearizable } from './desearizable'

export class Logbook implements Desearizable {

    public id: number
    public message: string
    public image: string
    public building_id: number
    public doorman_id: number
    public created_at: string

    public doorman: string

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getDate() {
        return this.created_at.split(" ").shift()
    }

    getTime() {
        return this.created_at.split(" ").pop()
    }

}