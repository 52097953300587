<mat-spinner *ngIf="building == null" [diameter]="50"></mat-spinner>
<div class="container admin-container" *ngIf="building">

  <div class="card">
    <div class="card-header">
      <h1>{{building?.name}} {{ building?.type == 2 ? '(Negocio)' : '' }}</h1>
      <h2>{{building?.address}}<br>{{building?.city}}</h2>
    </div>
  </div>

  <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
    <mat-tab label="Apartamentos">
      
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="list-header">
                <h2>Torres</h2><button class="btn" (click)="createBlock()" >Nuevo +</button>
              </div>
              <ul class="block-list">
                <li class="list-group-item" [ngClass]="{'active': block.id == selectedBlock.id}" *ngFor="let block of building.blocks" [value]="block.id" (click)="showApartments(block)">
                  <div class="float-right ml-0">
                    <button class="btn" (click)="editBlock(block); $event.stopPropagation()"><mat-icon aria-hidden="false">create</mat-icon></button>
                  </div>
                  <h3>{{ block.name }}</h3>
                </li>
              </ul>
            </div>
            <div class="col-md-9" *ngIf="selectedBlock">
              <div class="list-header">
                <h2>{{ selectedBlock.name }}</h2>
                <button class="btn" (click)="createApartment()">Nuevo +</button>
              </div>
              <mat-spinner *ngIf="loadingApartments == true" [diameter]="50"></mat-spinner>
              <div class="card-columns apartment">
                <div class="card" *ngFor="let apartment of apartments">
                  <div class="card-body" (click)="showApartment(apartment)">
                    <h5 class="card-title">{{ apartment.name }}</h5>
                    <button class="btn float-right" (click)="editApartment(apartment); $event.stopPropagation()"><mat-icon aria-hidden="false">create</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Porteros"><app-doorman [building]="building"></app-doorman></mat-tab>
    <mat-tab label="Administradores"><app-building-admin [building]="building"></app-building-admin></mat-tab>
    <!--mat-tab label="Areas comunales"><app-common-place [building]="building"></app-common-place></mat-tab-->
    <mat-tab label="Contactos"><app-contact [building]="building"></app-contact></mat-tab>
  </mat-tab-group>

  <!--mat-tab-group mat-align-tabs="center">
      <mat-tab label="Reservaciones"><app-reservation [building]="building"></app-reservation></mat-tab>
      <mat-tab label="Area comunales"><app-common-place [building]="building"></app-common-place></mat-tab>
      <mat-tab label="Subscripciones"><app-building-subscription></app-building-subscription></mat-tab>
  </mat-tab-group-->
  
</div>