import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import Swal from 'sweetalert2'
import { DataType, showMessage } from 'src/app/core/util/common'
import { DoormanService } from 'src/app/core/services/doorman.service'
import { DoormanDialogData } from 'src/app/components/support/building/doorman/doorman.component'


@Component({
  selector: 'app-edit-doorman',
  templateUrl: './edit-doorman.component.html'
})
export class EditDoormanComponent implements OnInit {

  formGroup: UntypedFormGroup

  states: DataType[] = [
    {id: 1, name: 'Activo'},
    {id: 0, name: 'Inactivo'}
  ];

  constructor(private fb: UntypedFormBuilder, private doormanService: DoormanService, @Inject(MAT_DIALOG_DATA) public data: DoormanDialogData, public dialogRef: MatDialogRef<EditDoormanComponent>) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'first_name': [this.data.doorman.first_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'last_name': [this.data.doorman.last_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'cellphone': [this.data.doorman.cellphone.substring(3, this.data.doorman.cellphone.length), [Validators.required, Validators.maxLength(15), Validators.minLength(10)]],
      'document_id': [this.data.doorman.document_id, [Validators.required, Validators.maxLength(15), Validators.minLength(6)]],
      'state': [this.data.doorman.status ? 1 : 0, Validators.required]
    });
  }

  get first_name() {
    return this.formGroup.get('first_name') as UntypedFormControl
  }

  get last_name() {
    return this.formGroup.get('last_name') as UntypedFormControl
  }

  get cellphone() {
    return this.formGroup.get('cellphone') as UntypedFormControl
  }

  get document_id() {
    return this.formGroup.get('document_id') as UntypedFormControl
  }

  get state() {
    return this.formGroup.get('state') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    //if (Object.keys(this.session)[0] === 'support') {
      this.doormanService.updateDoorman(form, this.data.doorman.id).subscribe(data => {
          //this.doorman2 = data;
          Swal.close()
          showMessage('¡Completo!', data.message, true)
          this.dialogRef.close(data.doorman)
        },
        error => {
          showMessage('¡Error!', error, false)
        }
      );
    //}
    /*if (Object.keys(this.session)[0] === 'admin') {
      this.doormanadminservice.putDoorman(form, this.doorman2.id).subscribe(data => {
          this.closeModal.nativeElement.click();
          this.doormanEvent.emit(true);
          this.doorman2 = data;
          Swal.close();
          this.showMessage('exito', 'Se han realizado los cambios con exito.');
        },
        error => {
          this.showMessage('Error', error.error.message);
        }
      );
    }*/
  }

  close(): void {
    this.dialogRef.close(false)
  }

}