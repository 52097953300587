<div class="container modal-ag">
    <h2>Crear Edificio</h2>
    <form [formGroup]="formGroup" (ngSubmit)= "onSubmit(formGroup.value)" class="form">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off">
            <div *ngIf="!name.valid && name.touched">
                <mat-error *ngIf="name.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="name.errors.minlength">El nombre ingresado debe contener al menos 4 caracteres.</mat-error>
                <mat-error *ngIf="name.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput formControlName="email" autocomplete="off">
            <div *ngIf="!email.valid && email.touched">
                <mat-error *ngIf="email.errors.email || email.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
                <mat-error *ngIf="email.errors.maxlength">El correo electrónico ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Ciudad</mat-label>
            <mat-select formControlName="city_id" >
                <mat-option *ngFor="let city of cities" [value]="city.id">
                {{ city.name }}
                </mat-option>
            </mat-select>
            <div *ngIf="!city.valid && city.touched">
                <mat-error *ngIf="city.errors.required">Debe seleccionar una ciudad.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="type" >
                <mat-option *ngFor="let type of types" [value]="type.id">
                {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="address" autocomplete="off">
            <div *ngIf="!address.valid && address.touched">
                <mat-error *ngIf="address.errors.required">Debe ingresar una dirección válida.</mat-error>
                <mat-error *ngIf="address.errors.minlength">La dirección ingresada debe contener al menos 6 caracteres.</mat-error>
                <mat-error *ngIf="address.errors.maxlength">La dirección ingresada supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Barrio</mat-label>
            <input matInput formControlName="neighborhood" autocomplete="off">
            <div *ngIf="!neighborhood.valid && neighborhood.touched">
                <mat-error *ngIf="neighborhood.errors.required">Debe ingresar un barrio válida.</mat-error>
                <mat-error *ngIf="neighborhood.errors.minlength">El barrio ingresado debe contener al menos 4 caracteres.</mat-error>
                <mat-error *ngIf="neighborhood.errors.maxlength">El barrio ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-label>Almacenamiento:  {{ getSpace() }}</mat-label>
        <mat-slider thumbLabel formControlName="space_allowed" [displayWith]="formatLabel" tickInterval="1000" step="1000" min="1000" max="20000" aria-label="units" class="d-block"></mat-slider>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form>
</div>