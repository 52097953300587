import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import Swal from 'sweetalert2'

export interface Payment {
  id: number;
  block: string;
  apartment: string;
  date_pay: Date;
  month_pay: string;
  form_to_pay: string;
  pay_value: string
}

interface Block {
  id: number;
  name: string;
}

interface Apartment {
  id: number;
  name: string
}

const ELEMENT_DATA: Payment[] = [
  { id: 1, block: "1", apartment: "301", date_pay: new Date, month_pay: "Enero", form_to_pay: "Cash", pay_value:"200000"},
  { id: 2, block: "3", apartment: "401", date_pay: new Date, month_pay: "Marzo", form_to_pay: "tarjeta", pay_value:"200000"}
] ;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {

  blocks: Block[] = [
    {id: 1, name: "Torre 1"},
    {id: 2, name: "Torre 2"},
    {id: 3, name: "Torre 3"}
  ]
  
  apartments: Apartment[] = [
    {id: 1, name: "Apartamento 501"},
    {id: 2, name: "Apartamneto 502"},
    {id: 3, name: "Apartamento 503"}
  ]
  
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;

  formGroupFilter: UntypedFormGroup

  displayedColumns: string[] = ['id','block', 'apartment', 'date_pay', 'month_pay', 'pay_value' ,'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor(private fb: UntypedFormBuilder ) {
    this.createForm();
   }

  ngOnInit(): void {
  }

  createForm(){
    this.formGroupFilter = this.fb.group({
      'block': [null, Validators.required],
      'apartment': [null, Validators.required],
      'date_from': [null, Validators.required],
      'date_to': [null, Validators.required]
    })
  }

  get block(){
    return this.formGroupFilter.get('block') as UntypedFormControl
  }
  get apartment(){
    return this.formGroupFilter.get('apartment') as UntypedFormControl
  }
  get date_from(){
    return this.formGroupFilter.get('date_from') as UntypedFormControl
  }
  get date_end(){
    return this.formGroupFilter.get('date_end') as UntypedFormControl
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSearch(form){
    this.showMessage("Buscando...", "Por favor espere")
    Swal.showLoading();
  }

  showMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
    })
  }

}
