import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms'
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { BlockService } from 'src/app/core/services/block.service'
import { Apartment } from 'src/app/core/models/apartment'
import { showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-packages',
  templateUrl: './package.component.html'
})
export class PackageComponent implements OnInit {

  @Input() apartment: Apartment
  formGroupFilter: UntypedFormGroup

  displayedColumns: string[] = ['delivered_company', 'created_at', 'delivery_at', 'delivered', 'doorman', 'doorman_delivered', 'actions'];
  dataSource = new MatTableDataSource()

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private fb: UntypedFormBuilder, private apartmentservice: ApartmentService, private blockservice: BlockService, private datePipe: DatePipe) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroupFilter = this.fb.group({
      'date_from': [null, Validators.required],
      'date_to': [null, Validators.required]
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  onFilter(form) {
    form.date_end = this.datePipe.transform(form.date_to,"dd-MM-yyyy");
    form.date_begin = this.datePipe.transform(form.date_from,"dd-MM-yyyy");
    Swal.showLoading()
    this.apartmentservice.postApartmentDateFilter(form, this.apartment.id, 2).subscribe(
      data => {
        this.dataSource.data = data.visit;
        Swal.close()
      },
      error => {
        Swal.close()
        showMessage('¡Error!', error, false)
      }
    )
  }

  onSubmit(form) {
    Swal.showLoading()
  }

  ngOnChanges(){
    this.dataSource.data = this.apartment.packages
  }

}
