import {Desearizable} from './desearizable'

export class Booking implements Desearizable{

  public id: number
  public name: string
  public date: string
  public time_begin: string
  public time_end: string
  public status: boolean

  public date_checkin: string 
  public date_checkout: string 

  public place: string
  public block: string
  public apartment: string
  public users: string

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

  getDate() {
    return this.date.split(" ").shift()
  }

  getTime() {
      return this.date.split(" ").pop()
  }

}