import { Component, OnInit, Input, ViewChild} from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { CreatePlaceComponent } from 'src/app/components/shared/place/create-place/create-place.component'
import { Building } from 'src/app/core/models/building'
import { Place } from 'src/app/core/models/place'
import { PlaceService } from 'src/app/core/services/place.service'

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html'
})
export class PlaceComponent implements OnInit {

  @Input() building: Building

  displayedColumns: string[] = ['id', 'name', 'price_type', 'price', 'image', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private placeService: PlaceService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createPlace(){
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(CreatePlaceComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        data.push(result)
        this.dataSource.data = data
      }
    })
  }

  editPlace(place: Place){

  }

  ngOnChanges() {
     //console.log(this.building.Places)
    //this.dataSource.data = this.building.commonPlaces
  }

}
