import { DatePipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar'
import { isSameDay, isSameMonth, startOfDay } from 'date-fns'
import { Subject } from 'rxjs'
import { Booking } from 'src/app/core/models/booking'
import { BookingService } from 'src/app/core/services/booking.service'
import * as moment from 'moment'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { BookingDetailComponent } from './booking-detail/booking-detail.component'

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html'
})
export class BookingComponent implements OnInit {

  
  view: CalendarView = CalendarView.Month
  CalendarView = CalendarView
  viewDate: Date = new Date()
  events: CalendarEvent[]

  modalData: {
    action: string;
    event: CalendarEvent;
  }

  refresh: Subject<any> = new Subject()
  activeDayIsOpen: boolean = true

  constructor(private bookingServide: BookingService, public dialog: MatDialog) { 
    this.bookingServide.getBookings(this.viewDate.getUTCMonth() + 1, this.viewDate.getFullYear()).subscribe(data => {
      this.loadBookings(data)
    })
  }

  ngOnInit(): void {
  }

  handleEvent(action: string, event: CalendarEvent): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: event.id}
    dialogConfig.width = '650px'
    const dialogRef = this.dialog.open(BookingDetailComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){

      }
    })
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({event, newStart, newEnd,}: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  closeOpenMonthViewDay() {
    this.events = [];
    this.refresh.next();
    const month = this.viewDate.getUTCMonth() + 1;
    this.bookingServide.getBookings(month, this.viewDate.getUTCFullYear()).subscribe(data => {
      this.loadBookings(data)
    })
    this.activeDayIsOpen = false;
  }

  loadBookings(bookings: Booking[]) {
    let newEvents = []
    let datepipe = new DatePipe('en-US')
    bookings.forEach(function (booking) {
      var mDate = moment(`${booking.date} 01:00`, "MM-DD-YYYY HH:mm")
      let event = {
        id: booking.id,
        title: booking.place + "<span>" + `Bloque: ${booking.block} <br> Apartmento: ${booking.apartment}` + `<p>${booking.time_begin}</p>` + "</span>",
        start: startOfDay(mDate.toDate())
      };
      newEvents.push(event)
    })
    console.log(newEvents.length)
    this.events = newEvents
    this.refresh.next()
  }
  

}
