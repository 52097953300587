<div class="container admin-container">
    <div class="form-group row">
        <label for="block" class="col-sm-3 col-form-label">Bloque:</label>
        <p class="col-sm-9"> {{payment.block}} </p>
    </div>

    <div class="form-group row">
        <label for="apartment" class="col-sm-3 col-form-label">Apartamento:</label>
        <p class="col-sm-9"> {{payment.apartment}} </p>
    </div>

    <div class="form-group row">
        <label for="date_pay" class="col-sm-3 col-form-label">Dia de pago:</label>
        <p class="col-sm-9"> {{payment.date_pay}} </p>
    </div>

    <div class="form-group row">
        <label for="month_pay" class="col-sm-3 col-form-label">Mes pago:</label>
        <p class="col-sm-9"> {{payment.month_pay}} </p>
    </div>

    <div class="form-group row">
        <label for="form_to_pay" class="col-sm-3 col-form-label">Forma de pago:</label>
        <p class="col-sm-9"> {{payment.form_to_pay}} </p>
    </div>

    <div class="form-group row">
        <label for="pay_value" class="col-sm-3 col-form-label">Valor Pagado:</label>
        <p class="col-sm-9"> {{payment.pay_value}} </p>
    </div>

</div>