import { Injectable, EventEmitter } from '@angular/core'
import { Building } from '../models/building'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { City } from '../models/city'
import { Block } from '../models/block'
import { Logbook } from '../models/logbook'
import { Doorman } from '../models/doorman'
import { Admin } from '../models/admin'
import { Place } from '../models/place'
import { PlaceService } from './place.service'
import { GlobalErrorHandler } from '../util/common'
import { BuildingResponse, InfoResponse } from '../reponse/data.response'
import { LogbookComponent } from 'src/app/components/admin/logbook/logbook.component'
import { ServiceRequest } from '../models/service.request'
import { Booking } from '../models/booking'

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  buildingSelected = new EventEmitter<Building>();

  httpOptions: {}
  errorHandler = new GlobalErrorHandler()
  url = ""
  
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      if(session){
        this.updateToken(session ? session.token : '', session.session)
      } 
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    //this.url = AppSettings.url + (sessionType ? `/${String(sessionType).split('"').join('')}` : "")
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getInfo(): Observable<InfoResponse> {
    return this.http.get<Logbook[]>(
      `${this.url}home/info`, this.httpOptions
    ).pipe(
      map(data => {
        let totalApartment = data['total_apartments']
        let totalBlocks = data['total_blocks']
        let totalUsers = data['total_users']

        var resp = new InfoResponse(totalApartment, totalBlocks, totalUsers)
        resp.total_doormans = data['total_doormans']
        resp.requests = data['requests'].map(r => new ServiceRequest().deserialize(r))
        resp.bookings = data['bookings'].map(b => new Booking().deserialize(b))
        resp.logbooks = data['logbooks'].map(l => new Logbook().deserialize(l))

        return resp
      }, catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    );
  }

  getLogbook(): Observable<Logbook[]> {
    return this.http.get<Logbook[]>(
      `${this.url}building/all/logbook`, this.httpOptions
    ).pipe(
      map(data => {
        return (data["logbooks"]).map(logook => new Logbook().deserialize(logook))
      }, catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    );
  }

  getBuilding(): Observable<Building> {
    return this.http.get<Building>(
      `${this.url}/building/t/info`, this.httpOptions
    ).pipe(
      map(data => {
        let building = new Building().deserialize(data['building'])
        building.blocks = data['blocks'].map(block => new Block().deserialize(block))
        return building
      }, catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    );
  }

  getBuildings(): Observable<Building[]> {
    return this.http.get<Building[]>(
      `${this.url}building/city/-1/-1/-1`, this.httpOptions
    ).pipe(
      map(data => {
        return data.map(data => new Building().deserialize(data))
      }, 
      catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    );
  }

  /**
   * Este metodo retorna el edificio
   * @param id el identificador del edificio
   */
  getBuildingID(id: number): Observable<BuildingResponse> {
    console.log(`${this.url}building/${id}`)
    return this.http.get<Building>(
      `${this.url}building/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        let building = new Building().deserialize(resp["building"])
        building.blocks = resp["blocks"].map((block: Block) => new Block().deserialize(block))
        building.doormans = resp["doormans"].map((doorman: Doorman) => new Doorman().deserialize(doorman))
        building.admins = resp["admins"].map((admin: Admin) => new Admin().deserialize(admin))
        building.places = resp["places"].map((place: Place) => new Place().deserialize(place))
        let message = resp['message']
        return new BuildingResponse(building, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  /**
   * getBuildingCity
   * @param cityID hace referencia  a la ciudad de la cual se quiere obtener la información.
   * @param status hace referencia al estado del edificio.
   */
  getBuildingsCity(cityID: number, status: number, type: number): Observable<Building[]> {
    return this.http.get<Building[]>(
      `${this.url}building/city/${cityID}/${status}/${type}`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp.map(building => new Building().deserialize(building))
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  /**
   *  getCities.
   */
  getCities(): Observable<City[]> {
    return this.http.get<City[]>(
      `${AppSettings.url}/building/city/`
    ).pipe(
      map(resp => {
        return resp
      })
    )
  }

  /**
   *  createBuilding
   * @param form hace referencia al formulario con la información del edificio
   */
  createBuilding(form): Observable<BuildingResponse> {
    const data = {
      name: form.name,
      city_id: form.city_id,
      address: form.address,
      email: form.email,
      type: form.type,
      neighborhood: form.neighborhood,
      space_allowed: form.space_allowed
    }
    return this.http.post<BuildingResponse>(
      `${this.url}building`, data, this.httpOptions
    ).pipe(
      map(data => {
        const building = new Building().deserialize(data["building"])
        const message = data["message"]
        return new BuildingResponse(building, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  /**
   * putBuilding
   * @param form hace referencia al formulario con la información del edificio.
   */
  updateBuilding(form, id: number): Observable<BuildingResponse> {
    const data = {
      name: form.name,
      address: form.address,
      email: form.email,
      neighborhood: form.neighborhood,
      city_id: form.city_id,
      type: form.type,
      status: form.state == 1 ? true : false,
      space_allowed: form.space_allowed
    }
    return this.http.put<BuildingResponse>(
      `${this.url}building/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const building = new Building().deserialize(data["building"])
        const message = data["message"]
        return new BuildingResponse(building, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
