import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import Swal from 'sweetalert2'
import { BlockService } from 'src/app/core/services/block.service'
import { ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { showMessage } from 'src/app/core/util/common'
import { Apartment } from 'src/app/core/models/apartment'

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html'
})
export class DeliveryComponent implements OnInit {
  
  @Input() apartment: Apartment
  id: number
  
  newForm = false;
  loading = false;

  displayedColumns: string[] = ['name', 'note', 'delivery_company', 'created_at'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private fb: UntypedFormBuilder, private apartmentservice: ApartmentService, private route: ActivatedRoute, private blockservice: BlockService, private datePipe: DatePipe ) {
    //this.route.paramMap.pipe().subscribe(params => {
    //  this.id = Number(params.get('id'));
    //});
    this.loading = false;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  newAddresses() {
    this.newForm = true;
  }

  onFilter(form) {
    form.date_end = this.datePipe.transform(form.date_to,"dd-MM-yyyy");
    form.date_begin = this.datePipe.transform(form.date_from,"dd-MM-yyyy");
    showMessage('Filtrando', 'Por favor espere', true);
    Swal.showLoading();
    this.apartmentservice.postApartmentDateFilter(form, this.id, 3).subscribe(
      data => {
        this.dataSource.data = data.visit;
        Swal.close();
      },
      error => {
        Swal.close();
        showMessage('error', error.error.message, false);
      }
    );
  }

  ngOnChanges(){
    this.dataSource.data = this.apartment.deliveries
  }

}
