import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Building } from 'src/app/core/models/building'
import { Doorman } from 'src/app/core/models/doorman'
import { BuildingService } from 'src/app/core/services/building.service'
import { DoormanService } from 'src/app/core/services/doorman.service'
import { showMessage } from 'src/app/core/util/common'
import { CreateDoormanComponent } from '../../shared/doorman/create-doorman/create-doorman.component'
import { EditDoormanComponent } from '../../shared/doorman/edit-doorman/edit-doorman.component'

@Component({
  selector: 'app-admin-doorman',
  templateUrl: './admin-doorman.component.html'
})
export class AdminDoormanComponent implements OnInit {

  //doormans: Doorman[]
  displayedColumns: string[] = ['name', 'document_id', 'cellphone', 'state', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  building: Building

  constructor(private doormanService: DoormanService, private buildingService: BuildingService, public dialog: MatDialog) {
    this.doormanService.getAllDoormans().subscribe(resp => {
      this.dataSource.data = resp
    }, error => {
      showMessage("¡Error!", error, false)
    })
    this.buildingService.getBuilding().subscribe(data => {
      this.building = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createDoorman() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(CreateDoormanComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        data.push(result)
        this.dataSource.data = data
      }
    })
  }

  editDoorman(doorman: Doorman) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id, doorman: doorman}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(EditDoormanComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        let index = data.findIndex((item: Doorman) => item.id == result.id)
        data[index] = result
        this.dataSource.data = data
      }
    })
  }

}
