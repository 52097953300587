import { DatePipe } from '@angular/common'
import { Desearizable } from './desearizable'

export class ServiceRequest implements Desearizable {

    public id: number
    public user_id: number
    public admin_id: number
    public building_id: number
    public subject: string
    public status: number
    public type: number
    public sender: number
    public created_at: string

    public users: string
    public block: string
    public apartment: string

    constructor() {
        this.id = -1
    }

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getDate() {
        return this.created_at.split(" ").shift()
    }

    getTime() {
        return this.created_at.split(" ").pop()
    }

    getStatus(): string {
        return this.status == 1 ? "Abierta" : "Cerrado"
    }

    getMDate(): string {
        return this.created_at.replace(" ", "<br>")
    }

    

}