import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import Swal from 'sweetalert2'
import { showMessage } from 'src/app/core/util/common'
import { UserService } from 'src/app/core/services/user.service'
import { BuildingType } from 'src/app/core/models/building'

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit {

  userTypes = [
    {id: 1, name: 'Arrendador'},
    {id: 2, name: 'Arrendatario'},
    {id: 3, name: 'Ambos'}
  ]

  states = [
    {id: 1, name: 'Activo'},
    {id: 0, name: 'Inactivo'}
  ]

  type: BuildingType
  formGroup: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder, private userService: UserService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<EditUserComponent>) {
    this.type = this.data.type
    if (this.type == BuildingType.Business) {
      this.userTypes = [{id: 4, name: 'Oficina'}]
    }
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'first_name': [this.data.user.first_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'last_name': [this.data.user.last_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'email': [this.data.user.email, [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'phone': [this.data.user.phone.substring(3, this.data.user.phone.length), [Validators.required, Validators.maxLength(15), Validators.minLength(6)]],
      'state': [this.data.user.status ? 1 : 0, Validators.required],
      'user_type': [this.data.user.role, Validators.required]
    })
  }

  get firstName() {
    return this.formGroup.get('first_name') as UntypedFormControl
  }

  get lastName() {
    return this.formGroup.get('last_name') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get phone() {
    return this.formGroup.get('phone') as UntypedFormControl
  }

  get userType() {
    return this.formGroup.get('user_type') as UntypedFormControl
  }

  get state() {
    return this.formGroup.get('state') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.userService.updateUser(form, this.data.user.id, this.data.id).subscribe(data => {
        showMessage('¡Guardado!', data.message, true)
        this.dialogRef.close(data.user)
      }, error => {
        showMessage('Error!', error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
