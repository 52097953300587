import {Desearizable} from './desearizable'

export class Bill implements Desearizable {

  public id: number
  public bill_type: string
  public name: string
  public note: string

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }
  
}
