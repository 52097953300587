import {Desearizable} from './desearizable'

export class Admin {

  public id: number
  public email: string
  public title: number
  public password: string
  public building_id: number
  public first_name: string
  public last_name: string
  public status: boolean
  public accepted: boolean

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

  getStatus(){
    return this.status ? "Activo" : "Inactivo"
  }

  getName(){
      return `${this.first_name} ${this.last_name}`
  }

}
