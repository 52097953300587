<div class="container modal-ag">
    <h2>Editar Usuario</h2>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="first_name">
            <div *ngIf="!firstName.valid && firstName.touched">
                <mat-error *ngIf="firstName.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="firstName.errors.maxlength">El apellido supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="last_name">
            <div *ngIf="!lastName.valid && lastName.touched">
                <mat-error *ngIf="lastName.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="lastName.errors.maxlength">El apellido supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <!--mat-form-field class="form-group" appearance="outline">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput formControlName="email" autocomplete="off" validateOnBlur [validateFormControl]="formGroup.controls['email']">
            <div *ngIf="email.touched && email.invalid">
                <mat-error *ngIf="email.errors.required || email.errors.email || email.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
            </div>
        </mat-form-field-->

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Celular</mat-label>
            <ngx-mat-intl-tel-input
                [preferredCountries]="['co']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                describedBy="phoneInput"
                formControlName="phone">
            </ngx-mat-intl-tel-input>
        </mat-form-field>

        <mat-form-field class="form-group d-block" appearance="outline">
            <mat-label>Tipo de Usuario</mat-label>
            <mat-select formControlName="user_type" >
                <mat-option *ngFor="let userType of userTypes" [value]="userType.id">
                {{ userType.name }}
                </mat-option>
            </mat-select>
            <div *ngIf="!userType.valid && userType.touched">
                <mat-error *ngIf="userType.errors.required">Debe seleccionar un tipo de usaurio.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state" >
                <mat-option *ngFor="let state of states" [value]="state.id">
                {{ state.name }}
                </mat-option>
            </mat-select>
            <div *ngIf="!state.valid && state.touched">
                <mat-error *ngIf="state.errors.required">Debe seleccionar un estado.</mat-error>
            </div>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark d-block w-100" (click)="close()">Cancelar</button>
        </div>

    </form>

</div>