<div class="container admin-container p-4">
    <div class="card pl-0">
        <div class="card-header mt-0">
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <!--div class="filter">
                    <form [formGroup]="formGroupFilter" (ngSubmit)="onSubmit(formGroupFilter.value)">

                        <mat-date-range-input [formGroup]="formGroupFilter" class="form-group ml-3" [rangePicker]="picker">
                            <input matStartDate formControlName="date_from" placeholder="Start date">
                            <input matEndDate formControlName="date_to" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <button type="submit" class="btn btn-info ml-3" [disabled]="formGroupFilter.invalid">Buscar</button>
                    </form>
                </div-->
            </div>
        </div>

        <div class="card-body">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container-->

                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef> Nota </th>
                <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                </ng-container>

                <ng-container matColumnDef="bill_type">
                    <th mat-header-cell *matHeaderCellDef>Tipo de Recibo</th>
                    <td mat-cell *matCellDef="let element"> {{element.bill_type}} </td>
                    </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
                </ng-container>

                <ng-container matColumnDef="delivery_at">
                    <th mat-header-cell *matHeaderCellDef>Entregado a</th>
                    <td mat-cell *matCellDef="let element"> {{element.delivery_at}} </td>
                </ng-container>

                <ng-container matColumnDef="delivery_date">
                    <th mat-header-cell *matHeaderCellDef>Fecha de entrega</th>
                    <td mat-cell *matCellDef="let element"> {{element.delivery_date}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

