<app-website-header></app-website-header>
    
  
      <div class="container" *ngIf="blog != null">
        
        <img class="img-thumbnail"  src="{{ blog.image}}" onerror="this.style.display='none'" >
        <h1 class="mt-5">{{blog.title | uppercase}}</h1>
        <p class="lead">{{blog.content}}</p>
        <p>{{blog.description}}</p>
      </div>

        
<app-website-footer></app-website-footer>