import {Desearizable} from './desearizable'

export class Block implements Desearizable {
  public id: number = -1
  public name: string
  public building_id: number

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

}
