<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <h1>Blogs</h1>
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <button type="button" class="btn" (click)="createBlog()">Nuevo +</button>
            </div>
        </div>
        <div class="card-body">
            
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>Titulo</th>
                    <td mat-cell *matCellDef="let blog">{{ blog.title }}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Descripcion</th>
                    <td mat-cell *matCellDef="let blog">{{ blog.description }}</td>
                </ng-container>

                <ng-container matColumnDef="tag">
                    <th mat-header-cell *matHeaderCellDef> Tag </th>
                    <td mat-cell *matCellDef="let blog">{{ blog.tag }}</td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef>Imagen</th>
                    <td mat-cell *matCellDef="let blog">
                        <img [src]="blog.image" height="100"/>
                    </td>
                </ng-container>
  
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let blog">
                        <a [routerLink]="['/blog' ,blog.slug]" class="btn"><mat-icon aria-hidden="false">pageview</mat-icon></a>
                    </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>