<app-website-header></app-website-header>

<div class="container" style="min-height: 100vh; padding-top: 150px;">

    <h2>Blog</h2>
    <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
             
            <a [href]="'/blog/' + blog.slug"  *ngFor="let blog of blogs">
                <div class="card h-100">
                    <img [src]="blog.image" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">{{ blog.title }}</h5>
                        <p class="card-text">{{ blog.content.substring(0, 150) }}...</p>
                    </div>
                </div>
            </a>
            
        </div>
    </div>

</div>

<app-website-footer></app-website-footer>