import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ServiceRequest } from 'src/app/core/models/service.request'
import { RequestMessage } from 'src/app/core/models/request.message'
import { ServiceRequestService } from 'src/app/core/services/service.request.service'
import { showMessage } from 'src/app/core/util/common'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { Lightbox } from 'ngx-lightbox'
import { ScrollToBottomDirective } from 'src/app/core/directives/scroll-to-bottom-directive'

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html'
})
export class RequestComponent implements OnInit {

  requests: ServiceRequest[]
  filteredRequests: ServiceRequest[]
  request = new ServiceRequest()
  messages: RequestMessage[]
  formGroup: UntypedFormGroup
  scrolltop: number = 0
  loading = true
  selectedType = 0
  isUserRequests = true

  @ViewChild("scrollMe") scroll: ElementRef
  @ViewChild("fileInput") fileUpload: ElementRef
  files = []
  filesPath = []

  types = [
    {id: -1, name: "Todos"},
    {id: 1, name: "Pendiente"},
    {id: 2, name: "Cerrado"}
  ]

  constructor(private requestService: ServiceRequestService, private fb: UntypedFormBuilder, private lightbox: Lightbox) { 
    this.createForm()
  }

  ngOnInit(): void {
    this.getRequests(-1)
  }

  createForm() {
    this.formGroup = this.fb.group({
      'message': [null, [Validators.required, Validators.maxLength(500), Validators.minLength(1)]],
      'file': [null],
      fileSource: this.fb.array([])
    })
  }

  get message() {
    return this.formGroup.get('message') as UntypedFormControl
  }

  get tfiles(): UntypedFormArray {
    return this.formGroup.get('fileSource') as UntypedFormArray
  }

  createRequest() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase()
    this.filteredRequests = this.requests.filter(
      (data) =>  JSON.stringify(data).toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
    )
  }

  getRequests(type: number) {
    this.selectedType = type
    if (this.isUserRequests == true) {
      this.loadUserRequests()
    } else {
      this.loadDoormanRequests()
    }
  }

  loadUserRequests() {
    this.requests = []
    this.filteredRequests = []
    this.loading = true
    this.isUserRequests = true
    this.request = new ServiceRequest()
    this.requestService.getRequests(this.selectedType).subscribe(data => {
      this.requests = data.requests
      this.filteredRequests = data.requests
      this.loading = false
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  loadDoormanRequests() {
    this.requests = []
    this.filteredRequests = []
    this.loading = true
    this.isUserRequests = false
    this.request = new ServiceRequest()
    this.requestService.getDoormanRequests(this.selectedType).subscribe(data => {
      this.requests = data.requests
      this.filteredRequests = data.requests
      this.loading = false
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  filterRequest(event){
    this.getRequests(parseInt(event.target.value))
  }

  getRequest(request) {
    this.loading = true
    this.request = new ServiceRequest()
    if (this.isUserRequests == true) {
      this.requestService.getRequest(request.id).subscribe(data => {
        this.request = data.request
        this.messages = data.messages
        this.loading = false
        setTimeout(() => {
          this.scroll.nativeElement.style.opacity = "1"
          this.scrolltop = this.scroll.nativeElement.scrollHeight
        }, 500)
      }, error => {
        showMessage("¡Error!", error, false)
      })
    } else {
      this.requestService.getDoormanRequest(request.id).subscribe(data => {
        this.request = data.request
        this.messages = data.messages
        this.loading = false
        setTimeout(() => {
          this.scroll.nativeElement.style.opacity = "1"
          this.scrolltop = this.scroll.nativeElement.scrollHeight
        }, 500)
      }, error => {
        showMessage("¡Error!", error, false)
      })
    }
  }

  closeRequest(request) {
    Swal.showLoading()
    if (this.isUserRequests == true) {
      this.requestService.closeRequest(request.id).subscribe(data => {
        let canceledRequest = data.request
        let index = this.filteredRequests.findIndex((item: ServiceRequest) => item.id == canceledRequest.id)
        this.filteredRequests[index] = canceledRequest
        this.request = canceledRequest
        showMessage("¡Completo!", data.message, true)
      }, error => {
        showMessage("¡Error!", error, false)
      })
    } else {
      this.requestService.closeDoormanRequest(request.id).subscribe(data => {
        let canceledRequest = data.request
        let index = this.filteredRequests.findIndex((item: ServiceRequest) => item.id == canceledRequest.id)
        this.filteredRequests[index] = canceledRequest
        this.request = canceledRequest
        showMessage("¡Completo!", data.message, true)
      }, error => {
        showMessage("¡Error!", error, false)
      })
    }
  }

  onSubmit(form) {
    Swal.showLoading()

    if (this.isUserRequests == true) {

      this.requestService.createMessage(form, this.request.id, this.files).subscribe(data => {
        this.messages.push(data.messages[0])
        this.message.setValue("")
        this.filesPath = []
        this.files = []
        setTimeout(() => {
          this.scrolltop = this.scroll.nativeElement.scrollHeight
        }, 500)
        showMessage("¡Completo!", data.message, true)
      }, error => {
        showMessage("¡Error!", error, false)
      })

    } else {

      this.requestService.createDoormanMessage(form, this.request.id, this.files).subscribe(data => {
        this.messages.push(data.messages[0])
        this.message.setValue("")
        this.filesPath = []
        this.files = []
        setTimeout(() => {
          this.scrolltop = this.scroll.nativeElement.scrollHeight
        }, 500)
        showMessage("¡Completo!", data.message, true)
      }, error => {
        showMessage("¡Error!", error, false)
      })

    }
  
  }

  openImage(file) {
    const image = {
      src: file,
      caption: "Biru",
      thumb: file
    }
    this.lightbox.open([image], 0)
  }

  openPDF(file) {
    var tabWindowId = window.open('about:blank', '_blank')
    tabWindowId.location.href = file
  }

  attachFile() {
    if(this.files.length  >= 5) {
      showMessage("¡Error!", "No se pueden agregar más de 5 archivos", false)
      return
    }
    this.fileUpload.nativeElement.click()
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      const extension = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
      console.log(file.size)
      if(file.size > 5000000) {
        showMessage("¡Error!", "El archivo no puede superar las 5Mb.", false)
        return
      }
      if(extension === "pdf" || extension === "jpg" || extension === "jpeg" || extension === "png") {
        this.files.push(file)
        this.tfiles.controls.push(this.fb.control(false))

        const reader = new FileReader()
        reader.onload = () => {
          this.filesPath.push(reader.result as string)
        }
        reader.readAsDataURL(file)
      } else {
        showMessage("¡Error!", "El formato del archivo no está permitido, tenga en cuenta que solo se pueden subir imagenes en formato png, jpg y archivos pdf.", false)
        return
      }
    }
  }

  isImage(path) {
    let ext = path.split(/[#?]/)[0].split('.').pop().trim()
    if(ext == "jpg" || ext == "jpeg" || ext == "png") {
      return true
    }
    return false
  }

  isPDF(path) {
    let ext = path.split(/[#?]/)[0].split('.').pop().trim()
    if(ext == "pdf") {
      return true
    }
    return false
  }

  removeImage(index) {
    this.filesPath.splice(index, 1)
    this.files.splice(index, 1)
  }

}
