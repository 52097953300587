import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-service-company',
  templateUrl: './create-service-company.component.html'
})
export class CreateServiceCompanyComponent implements OnInit {

  formGroup: UntypedFormGroup
  types: any[]
  countries: any[]

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<CreateServiceCompanyComponent>) { 
    this.types = data.types
    this.countries = data.countries
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(4)]],
      'type': [null, Validators.required],
      'content': [null, [Validators.maxLength(100)]],
      'country': [null, [Validators.required]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get type() {
    return this.formGroup.get('type') as UntypedFormControl
  }

  get content() {
    return this.formGroup.get('content') as UntypedFormControl
  }

  get country() {
    return this.formGroup.get('country') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.contactService.createServiceCompany(form).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
        this.dialogRef.close(data.serviceCompany)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}