import {Component, OnInit, ViewChild} from '@angular/core'
import {MatPaginator} from '@angular/material/paginator'
import {MatTableDataSource} from '@angular/material/table'
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms'
import Swal from 'sweetalert2'
import { DatePipe} from '@angular/common'
import { Visit } from 'src/app/core/models/visit'
import { BlockService } from 'src/app/core/services/block.service'
import { ActivatedRoute } from '@angular/router'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html'
})
export class VisitComponent implements OnInit {

  id: number;
  formGroup: UntypedFormGroup;
  formGroupFilter: UntypedFormGroup;
  titleAlert: string = 'Este espacio es requerido';

  displayedColumns: string[] = ['id', 'name', 'document_id', 'phone', 'doorman', 'created_at'];
  dataSource = new MatTableDataSource();
  loading = false

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private fb: UntypedFormBuilder, private apartmentservice: ApartmentService, private route: ActivatedRoute, private blockservice: BlockService, private datePipe: DatePipe) {
    this.loading = true;
    this.createForm();
    this.route.paramMap.pipe().subscribe(params => {
      this.id = Number(params.get('id'));
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.apartmentservice.getApartmentID(this.id).subscribe(
      data => {
        this.dataSource.data = data['visit'];
      }
    );
    this.dataSource.filterPredicate = (data: Visit, filter: string ) => {
      return data.created_at <= filter;
    };
    this.loading = false;
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [null, Validators.required],
      'date': [null, Validators.required],
      'time': [null, Validators.required]
    });

    this.formGroupFilter = this.fb.group({
      'date_from': [null, Validators.required],
      'date_to': [null, Validators.required]
    });
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl;
  }

  get date() {
    return this.formGroup.get('date') as UntypedFormControl;
  }

  get time() {
    return this.formGroup.get('time') as UntypedFormControl;
  }

  get date_from() {
    return this.formGroupFilter.get('date_from') as UntypedFormControl;
  }

  get date_to() {
    return this.formGroupFilter.get('date_end') as UntypedFormControl;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onFilter(form) {
    form.date_end = this.datePipe.transform(form.date_to,"dd-MM-yyyy");
    form.date_begin = this.datePipe.transform(form.date_from,"dd-MM-yyyy");
    showMessage('Filtrando', 'Por favor espere', true)
    Swal.showLoading();
    this.apartmentservice.postApartmentDateFilter(form, this.id, 4).subscribe(
      data => {
        this.dataSource.data = data.visit;
        Swal.close()
      },
      error => {
        Swal.close();
        showMessage('error', error.error.message, false)
      }
    );
  }



}
