<footer>
    <div class="container mb-5">
        <a class="navbar-brand" href="#"><img src="assets/img/logo.png" alt="logo" width="80px"></a>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <ul class="list-group site-map">
                    <li><a href="#"><mat-icon aria-hidden="false">smartphone</mat-icon> +57 3194056655</a></li>
                    <li><a href="#"><mat-icon aria-hidden="false">place</mat-icon> Calle 135 #19-65, Bogotá Colombia.</a></li>
                    <li><a href="#"><mat-icon aria-hidden="false">email</mat-icon> soporte@biru.com.co</a></li>
                </ul>
            </div>
            <div class="col-md-4 col-sm-6">
                <ul class="list-group site-map">
                    <li><a href="#">Home</a></li>
                    <li><a href="/#/about">Nosotros</a></li>
                    <li><a href="/#/contact">Contáctanos</a></li>
                    <li><a href="/#/blog">Blog</a></li>
                    <li><a href="/#/legal">Términos y Condiciones</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="copyright">
        <p>Copyright  © Biru 2023</p>
    </div>
</footer>