import { Component, OnInit } from '@angular/core';
import { Blog } from 'src/app/core/models/blog';
import { BlogService } from 'src/app/core/services/blog.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html'
})
export class BlogDetailComponent implements OnInit {
  blog: Blog
  constructor(private blogService: BlogService, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.loadBlog(params.get("slug"))
    })
  }
  ngOnInit(): void {
  }

  loadBlog(slug: string) {
    this.blogService.getBlogBySlug(slug).subscribe(data => {
      this.blog = data
    })
  }
}
