import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppSettings} from '../appsettings';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Subscription} from '../models/subscription';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  userSelected = new EventEmitter<Subscription>();


  httpOptions: {};

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '');
    });
    this.updateToken(localStorage.getItem('token'));
  }

  updateToken(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    };
  }

  getSubscriptionID(id: number): Observable<Subscription> {
    return this.http.get<any>(
      `${AppSettings.url}/plan/subscription/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        // let support = new Support().deserialize(resp);
        return resp;
      })
    );
  }
  getSubscription(): Observable<Subscription[]> {
    return this.http.get<any>(
      `${AppSettings.url}/plan/subscription/`, this.httpOptions
    ).pipe(
      map(resp => {
        // let support = new Support().deserialize(resp);
        return resp;
      })
    );
  }

}
