import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { UserService } from 'src/app/core/services/user.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.sass']
})
export class ActiveComponent implements OnInit {

  showForm = false
  accountCreated = false
  passwordFormGroup: UntypedFormGroup
  mtoken = ""

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService,  private fb: UntypedFormBuilder) {
    Swal.showLoading()
    this.route.paramMap.subscribe(params => {
      let iv = params.get('iv')
      let token = params.get('token')
      if(token != null){
        this.activeAccount(iv, token)
      }
    })
  }

  ngOnInit(): void {
    this.passwordFormGroup = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.max(15)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.max(15)]]
    }, { validator: [this.confirmedValidator, this.checkPassword ]})
  }

  get password() {
    return this.passwordFormGroup.get('password') as UntypedFormControl
  }

  get confirmPassword() {
    return this.passwordFormGroup.get('confirmpassword') as UntypedFormControl
  }

  checkPassword(form: UntypedFormGroup) {
    const password = form.controls.password.value
    return password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,})$/) ? null : form.controls.password.setErrors({ passwordFormat: true })
  }

  confirmedValidator(form: UntypedFormGroup) {
    const pass = form.controls.password.value
    const confirmPass = form.controls.confirmpassword.value
    return pass === confirmPass ? null : form.controls.confirmpassword.setErrors({ confirmedValidator: true })
  }

  activeAccount(iv, token) {
    Swal.showLoading()
    this.userService.activeAccount(iv, token).subscribe (
      data => {
        console.log(data)
        Swal.close()
        showMessage('¡Completo!', "La cuenta ha sido activada exitosamente.", true)
        this.mtoken = data
        this.showForm = true
      },
      error => {
        Swal.close()
        showMessage('¡Error!', error, false)
      }
    )
  }

  onSubmit(form) {
    console.log("**** change password")
    Swal.showLoading()
    this.userService.updateActivePassword(form, this.mtoken).subscribe (
      data => {
        Swal.close()
        showMessage('Completo', data, true)
        this.accountCreated = true
        this.showForm = false
      },
      error => {
        Swal.close()
        showMessage('Error', error, false)
      }
    )
  }

}
