<mat-spinner *ngIf="building == null" [diameter]="50"></mat-spinner>
<div class="container admin-container">
    
    <div class="card" *ngIf="building">
        <div class="card-header">
            <h1>Apartamentos</h1>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="list-header">
                        <h2>Torres</h2><button class="btn" (click)="createBlock()">Nuevo +</button>
                    </div>
                    <ul class="block-list">
                        <li class="list-group-item" *ngFor="let block of building.blocks" [value]="block.id" (click)="showApartments(block)">
                            <button class="btn float-right ml-0" (click)="editBlock(block); $event.stopPropagation()"><mat-icon aria-hidden="false">create</mat-icon></button>
                            <h3>{{ block.name }}</h3>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9" *ngIf="selectedBlock">
                    <div class="list-header">
                        <h2>{{ selectedBlock.name }}</h2>
                        <button class="btn" (click)="createApartment()">Nuevo +</button>
                    </div>
                    <mat-spinner *ngIf="loadingApartments == true" [diameter]="50"></mat-spinner>
                    <div class="card-columns apartment">

                        <div class="card" *ngFor="let apartment of apartments" >
                            <div class="card-body" [routerLink]="['/admin','apartment', apartment.id]">
                                <button class="btn float-right" (click)="editApartment(apartment); $event.stopPropagation()"><mat-icon aria-hidden="false">create</mat-icon></button>
                                <h5 class="card-title">{{ apartment.name }}</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>