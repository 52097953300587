import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AdminService } from '../../../../../core/services/admin.service'
import { AdminDialogData } from '../../building-admin/building-admin.component'
import Swal from 'sweetalert2'
import { DataType, showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-edit-building-admin',
  templateUrl: './edit-building-admin.component.html'
})
export class EditBuildingAdminComponent implements OnInit {

  formGroup: UntypedFormGroup

  states: DataType[] = [
    {id: 1, name: 'Activo'},
    {id: 0, name: 'Inactivo'}
  ]

  titles: DataType[] = [
    {id: 1, name: 'Señor'},
    {id: 2, name: 'Señora'}
  ]

  constructor(private fb: UntypedFormBuilder, private adminService: AdminService, @Inject(MAT_DIALOG_DATA) public data: AdminDialogData, public dialogRef: MatDialogRef<EditBuildingAdminComponent>) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'title': [this.data.admin.title, [Validators.required]],
      'first_name': [this.data.admin.first_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'last_name': [this.data.admin.last_name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'email': [this.data.admin.email, [Validators.required, Validators.email]],
      'state': [this.data.admin.status ? 1 : 0, Validators.required]
    });
  }

  get first_name() {
    return this.formGroup.get('first_name') as UntypedFormControl
  }

  get last_name() {
    return this.formGroup.get('last_name') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get title() {
    return this.formGroup.get('title') as UntypedFormControl
  }

  get state() {
    return this.formGroup.get('state') as UntypedFormControl
  }

  onSave(form) {
    Swal.showLoading()
    this.adminService.updateAdmin(form, this.data.admin.id).subscribe(data => {
        Swal.close()
        showMessage('¡Completo!', 'Se han realizado los cambios con exito.', true)
        this.dialogRef.close(data)
      }, error => {
        showMessage("¡Error!", error, false)
      })
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
