<div class="container modal-ag">
    <h2>Crear Mensaje</h2>
    <form [formGroup]="formGroup" (ngSubmit)= "onSubmit(formGroup.value)" class="message-form">

        <div class="row">
            <div class="col-md-4 line-right">

                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Destinatario</mat-label>
                    <mat-select formControlName="type" #type>
                        <mat-option *ngFor="let type of types" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="form-group" appearance="outline" *ngIf="type.value == 2">
                    <hr>
                    <mat-label class="section-title">Torres</mat-label>
                    <div formArrayName="blocks" *ngFor="let block of buildingBlocks; let i = index" class="form-check-inline">
                      <mat-checkbox [id]="block.id" (change)="changeBlock($event.checked, i, block.id)">{{block.name}}</mat-checkbox>
                    </div>
                    <hr>
                </div>

                <mat-form-field class="form-group" appearance="outline" *ngIf="type.value == 3">
                    <mat-label>Torre</mat-label>
                    <mat-select formControlName="block_id" #type (selectionChange)="loadApartments(type.value)">
                        <mat-option *ngFor="let block of buildingBlocks" [value]="block.id">
                            {{ block.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="form-group" appearance="outline" *ngIf="type.value == 3">
                    <hr>
                    <mat-label class="section-title">Apartamentos</mat-label>
                    <div formArrayName="apartments" *ngFor="let apartment of blockApartments; let i = index" class="form-check-inline">
                      <mat-checkbox [id]="apartment.id" (change)="changeApartment($event.checked, i, apartment.id)">{{apartment.name}}</mat-checkbox>
                    </div>
                    <hr>
                </div>

                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Tipo de Usuario</mat-label>
                    <mat-select formControlName="role" #type>
                        <mat-option *ngFor="let type of userTypes" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
            </div>
            <div class="col-md-8">
                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Asunto</mat-label>
                    <input matInput formControlName="subject">
                    <mat-error *ngIf="!subject.valid && subject.touched">
                        Debe ingresar un asunto válido.
                    </mat-error>
                </mat-form-field>
        
                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Mensaje</mat-label>
                    <textarea id="note" cols="30" rows="8" placeholder="Nota" matInput formControlName="message"></textarea>
                </mat-form-field>

                <ul class="list-group mb-4">
                    <li class="list-group-item" *ngFor="let file of files">
                        {{ file.name }}
                    </li>
                </ul>

                <mat-form-field class="form-group d-block" appearance="outline">
                    <mat-label>Archivo</mat-label>
                    <ngx-mat-file-input formControlName="file" placeholder="Imagen" (change)="onFileChange($event)" class="form-control">
                    </ngx-mat-file-input>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>

                <div class="form-group text-right">
                    <button mat-raised-button type="submit" class="btn btn-ok inline-block mr-2 mb-0" [disabled]="formGroup.invalid">Enviar</button>
                    <button mat-raised-button type="button" class="btn btn-outline-dark inline-block" (click)="close()">Cancelar</button>
                </div>
            </div>
        </div>

    </form>
</div>