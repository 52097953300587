import {Desearizable} from './desearizable'
import {Block} from './block'
import {Doorman} from './doorman'
import {Admin} from './admin'
import { Place } from './place'

export enum BuildingType {
  Residential = 1,
  Business = 2
}

export class Building implements Desearizable {

  public id: number
  public name: string
  public email: string
  public city_id: number
  public city: string
  public country_code: string
  public address: string
  public neighborhood: string
  public status: boolean
  public space_allowed: number
  public type: BuildingType

  public blocks: Block[]
  public doormans: Doorman[]
  public admins: Admin[]
  public places: Place[]

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getStatus(status: number): string {
    return this.status ? 'activo' : 'inactivo'
  }

  getType() {
    if (this.type == BuildingType.Business) {
      return "Negocio"
    }
    return "Residencial"
  }

}
