import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CreateBuildingComponent } from './building/create-building/create-building.component'
import { City } from 'src/app/core/models/city'
import { EditBuildingComponent } from './building/edit-building/edit-building.component'
import { showMessage } from './../../../core/util/common'
import { BuildingService } from 'src/app/core/services/building.service'
import { CityService } from 'src/app/core/services/city.service'
import { Building, BuildingType } from 'src/app/core/models/building'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html'
})
export class BuildingComponent implements OnInit {

  formGroup: UntypedFormGroup

  cities: City[]
  states = [
    {id: -1, name: 'Todos los Estados'},
    {id: 1, name: 'Activo'},
    {id: 0, name: 'Inactivo'}
  ]

  types = [
    {id: -1, name: 'Todos los Tipos'},
    {id: 1, name: 'Residencial'},
    {id: 2, name: 'Negocio'}
  ]

  cityId = -1
  stateType = -1
  typeId = -1
  loading = true

  displayedColumns: string[] = ['id', 'building', 'city', 'type', 'address', 'state', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private fb: UntypedFormBuilder, private buildingService: BuildingService, private cityService: CityService, public dialog: MatDialog) {
    this.createForm()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  ngOnInit(): void {
    this.cityService.getCities().subscribe(data => {
      let city = new City().deserialize({id: -1, name: "Todos"})
      this.cities = [city].concat(data)
      this.loadData()
    })
  }

  loadData() {
    this.buildingService.getBuildings().subscribe(data => {
      this.loading = false
      this.dataSource.data = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  createForm() {
    this.formGroup = this.fb.group({
      'state': [null, Validators.required],
      'city': [null, Validators.required]
    });
  }

  get state() {
    return this.formGroup.get('state') as UntypedFormControl;
  }

  get city() {
    return this.formGroup.get('city') as UntypedFormControl;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createBuilding() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {cities: this.cities}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(CreateBuildingComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(building => {
      if(building){
        const data = this.dataSource.data
        data.push(building)
        this.dataSource.data = data
      }
    })
  }

  editBuilding(building: Building): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {cities: this.cities, building: building}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(EditBuildingComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(building => {
      if(building){
        const data = this.dataSource.data
        let index = data.findIndex((item: Building) => item.id == building.id)
        data[index] = building
        this.dataSource.data = data
      }
    })
  }

  filterRequestCity(event) {
    this.cityId = parseInt(event.target.value)
    this.getBuildings()
  }

  filterRequestState(event) {
    this.stateType = parseInt(event.target.value)
    this.getBuildings()
  }

  filterRequestType(event) {
    this.typeId = parseInt(event.target.value)
    this.getBuildings()
  }

  getBuildings() {
    Swal.showLoading()
    this.dataSource.data = []
    this.buildingService.getBuildingsCity(this.cityId, this.stateType, this.typeId).subscribe(data => {
      Swal.close()
      this.dataSource.data = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  getCity(id: number): String {
    const index = this.cities.findIndex(city => city.id === id) ?? -1
    return index >= 0 ? this.cities[index].name : "-"
  }

}