import { Component, OnInit, Inject } from '@angular/core'
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import Swal from 'sweetalert2'
import { City } from 'src/app/core/models/city'
import { BuildingService } from 'src/app/core/services/building.service'
import { BuildingDialogData, showMessage } from 'src/app/core/util/common'
import { Building } from 'src/app/core/models/building'

@Component({
  selector: 'app-create-building',
  templateUrl: './create-building.component.html'
})
export class CreateBuildingComponent implements OnInit {

  formGroup: UntypedFormGroup
  cities: City[]

  types = [
    {id: 1, name: 'Residencial'},
    {id: 2, name: 'Negocio'}
  ]

  constructor(private fb: UntypedFormBuilder, private buildingService: BuildingService, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<CreateBuildingComponent>) { 
    this.cities = Object.assign([], this.data.cities)
    this.cities.splice(0,1)
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(4)] ],
      'city_id': [null, Validators.required],
      'email': [null, [Validators.email, Validators.maxLength(100)]],
      'type': [null, [Validators.required]],
      'address': [null, [Validators.required, Validators.maxLength(80), Validators.minLength(6)] ],
      'neighborhood':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(4)] ],
      'space_allowed': [1000, [Validators.required]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get city() {
    return this.formGroup.get('city_id') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get address() {
    return this.formGroup.get('address') as UntypedFormControl
  }

  get neighborhood() {
    return this.formGroup.get('neighborhood') as UntypedFormControl
  }

  get space() {
    return this.formGroup.get('space_allowed') as UntypedFormControl
  }

  get type() {
    return this.formGroup.get('type') as UntypedFormControl
  }

  getSpace() {
    if (this.space.value >= 1000) {
      return Math.round(this.space.value / 1000) + 'Gb'
    }
    return this.space.value
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'Gb';
    }
    return value
  }

  onSubmit(form) {
    Swal.showLoading()
    this.buildingService.createBuilding(form).subscribe(
      data => {
        Swal.close()
        showMessage("!Completo¡", data.message, true)
        this.dialogRef.close(data.building)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
