import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-website-header',
  templateUrl: './website-header.component.html',
  styleUrls: ['./website-header.component.sass']
})
export class WebsiteHeaderComponent implements OnInit {

  @Input() isHome: Boolean = true

  ngOnInit(): void {
  }

}
