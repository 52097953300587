import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import { GlobalErrorHandler } from '../util/common'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { map, catchError } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { ServiceCompany } from '../models/service.company'
import { Dependency } from '../models/dependency'
import { Router } from '@angular/router'
import { ContactResponse, DependencyResponse, MessageResponse, ServiceCompanyResponse } from '../reponse/data.response'
import { Contact } from '../models/contact'
import { ContactPhone } from '../models/contact.phone'
import { ContactMail } from '../models/contact.mail'

@Injectable({
  providedIn: 'root'
})
export class ContactService {
   
  httpOptions: {}
  errorHandler = new GlobalErrorHandler()
  url = ""
  
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      if(session){
        this.updateToken(session ? session.token : '', session.session)
      } 
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
      this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'access-token': token
        })
      }
  }

  getServiceCompanies(): Observable<ServiceCompany[]> {
    return this.http.get<ServiceCompany[]>(
      `${this.url}contact/servicecompany`, this.httpOptions
    ).pipe(
      map(data => {
        return data = data.map(x => new ServiceCompany().deserialize(x))
      }, 
      catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    )
  }

  getServiceCompaniesByCountry(cod: string): Observable<ServiceCompany[]> {
    return this.http.get<ServiceCompany[]>(
      `${this.url}contact/servicecompany/country/${cod}`, this.httpOptions
    ).pipe(
      map(data => {
        return data = data.map(x => new ServiceCompany().deserialize(x))
      }, 
      catchError(error => {
        return this.errorHandler.handleError(error)
      }))
    )
  }


  getServiceCompany(id): Observable<ServiceCompanyResponse> {
    return this.http.get<ServiceCompanyResponse>(
      `${this.url}contact/servicecompany/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        const serviceCompany = new ServiceCompany().deserialize(data["service_company"])
        const dependencies = data["dependencies"].map(x => new Dependency().deserialize(x))
        const message = data["message"]
        return new ServiceCompanyResponse(serviceCompany, message, dependencies)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createServiceCompany(form): Observable<ServiceCompanyResponse> {
    const data = {
      name: form.name,
      type: form.type,
      content: form.content,
      country_code: form.country
    }
    return this.http.post<ServiceCompanyResponse>(
      `${this.url}contact/servicecompany`, data, this.httpOptions
    ).pipe(
      map(data => {
        const serviceCompany = new ServiceCompany().deserialize(data["service_company"])
        const message = data["message"]
        return new ServiceCompanyResponse(serviceCompany, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateServiceCompany(form, id): Observable<ServiceCompanyResponse> {
    const data = {
      name: form.name,
      type: form.type,
      content: form.content,
      country_code: form.country
    }
    return this.http.put<ServiceCompanyResponse>(
      `${this.url}contact/servicecompany/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const serviceCompany = new ServiceCompany().deserialize(data["service_company"])
        const message = data["message"]
        return new ServiceCompanyResponse(serviceCompany, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getDependenciesByBuilding(id): Observable<Dependency[]> {
    return this.http.get<Dependency[]>(
      `${this.url}contact/servicecompany/dependency/building/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        return data
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getDependency(id): Observable<DependencyResponse> {
    return this.http.get<DependencyResponse>(
      `${this.url}contact/servicecompany/dependency/all/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        const dependency = new Dependency().deserialize(data["dependency"])
        const contacts = data["contacts"].map(x => new Contact().deserialize(x))
        dependency.contacts = contacts
        const message = data["message"]
        return new DependencyResponse(dependency, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createDependency(form, id): Observable<DependencyResponse> {
    const data = {
      name: form.name
    }
    return this.http.post<DependencyResponse>(
      `${this.url}contact/servicecompany/dependency/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const dependency = new Dependency().deserialize(data["dependency"])
        const message = data["message"]
        return new DependencyResponse(dependency, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateDependency(form, id): Observable<DependencyResponse> {
    const data = {
      name: form.name
    }
    return this.http.put<DependencyResponse>(
      `${this.url}contact/servicecompany/dependency/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const dependency = new Dependency().deserialize(data["dependency"])
        const message = data["message"]
        return new DependencyResponse(dependency, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createContact(form, id): Observable<ContactResponse> {
    const data = {
      name: form.name
    }
    return this.http.post<ContactResponse>(
      `${this.url}contact/servicecompany/contact/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const contact = new Contact().deserialize(data["contact"])
        const message = data["message"]
        return new ContactResponse(contact, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateContact(form, id): Observable<ContactResponse> {
    const data = {
      name: form.name
    }
    return this.http.put<ContactResponse>(
      `${this.url}contact/servicecompany/contact/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const contact = new Contact().deserialize(data["contact"])
        const message = data["message"]
        return new ContactResponse(contact, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getContact(id): Observable<ContactResponse> {
    return this.http.get<ContactResponse>(
      `${this.url}contact/servicecompany/contact/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        const contact = new Contact().deserialize(data["contact"])
        const phones = data["contact_phones"].map(x => new ContactPhone().deserialize(x))
        const mails = data["contact_mails"].map(x => new ContactMail().deserialize(x))
        contact.phones = phones
        contact.mails = mails
        const message = data["message"]
        return new ContactResponse(contact, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateContactInfo(form, phones, mails, id): Observable<ContactResponse> {
    const data = {
      phones: phones,
      mails: mails
    }
    return this.http.put<ContactResponse>(
      `${this.url}contact/servicecompany/contact/pm/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const contact = new Contact().deserialize(data["contact"])
        const message = data["message"]
        return new ContactResponse(contact, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  removeContact(id): Observable<ContactResponse> {
    return this.http.delete<ContactResponse>(
      `${this.url}contact/servicecompany/contact/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        const contact = new Contact().deserialize(data["contact"])
        const message = data["message"]
        return new ContactResponse(contact, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  assignDependency(form, id): Observable<DependencyResponse> {
    const data = {
      dependency_id: form.dependency
    }
    return this.http.post<DependencyResponse>(
      `${this.url}contact/servicecompany/dependency/assign/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const dependency = new Dependency().deserialize(data["dependency"])
        const message = data["message"]
        return new DependencyResponse(dependency, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  removeDependency(id, building): Observable<DependencyResponse> {
    return this.http.delete<DependencyResponse>(
      `${this.url}contact/servicecompany/dependency/remove/${id}/${building}`, this.httpOptions
    ).pipe(
      map(data => {
        const message = data["message"]
        const dependency = data["dependency"]
        return new DependencyResponse(dependency, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}