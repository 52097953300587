import {Desearizable} from './desearizable'
import {Bill} from './bill'
import { Delivery } from './delivery'
import { Package } from './package'
import { Visit } from './visit'
import { User } from './users'
import { Building } from './building'
import { Block } from './block'

export class Apartment implements Desearizable {

  public id: number
  public name: string
  public building_id: number
  public block_id: number
  
  public footage: number
  public coefficient: number

  public bills: Bill[]
  public deliveries: Delivery[]
  public packages: Package[]
  public visits: Visit[]
  public users: User[]

  public building: Building
  public block: Block

  deserialize(input: any): this {
    Object.assign(this, input)
    return this;
  }
}
