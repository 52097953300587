<nav class="navbar navbar-expand-lg navbar-dark justify-content-between nav-web" [ngClass]="isHome ? '' : 'nav-web--fixed'">
    <div class="container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        <a class="navbar-brand" href="/"><img src="assets/img/logo.png" alt="logo" width="80px"></a>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="navbar-nav mr-0 ml-auto mt-2 mt-lg-0">
                <!--li class="nav-item">
                    <a class="nav-link" [routerLink]="['/blog']" [routerLinkActive]="['active']">Blog</a>
                </li-->
                <li class="nav-item">
                    <a class="nav-link" href="/#/contact" >Contáctanos</a>
                </li>
                <li class="nav-item">
                    <a class="btn btn-login" [routerLink]="['/admin']" >Iniciar Sesión</a>
                </li>
            </ul>
        </div>
    </div>
    
    <!--form class="form-inline">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form-->
</nav>



<!--nav class="navbar navbar-expand-lg nav-web">
    <a class="navbar-brand" href="#"> <img src="assets/img/logo.png" alt="logo" width="80px"> </a>
    <div class="downloads">
        <a href="https://apps.apple.com/us/app/biru/id1558359322?itsct=apps_box_badge&amp;itscg=30200"><span class="appstore"></span></a>
        <a href="https://play.google.com/store/apps/details?id=com.solutechholding.biru&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><span class="googleplay"></span></a>
        <h2>Descarga La App</h2>
    </div>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        
    </div>
</nav-->