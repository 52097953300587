<div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-6 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">

            <form [formGroup]="formGroup" (ngSubmit)="loginSupport(formGroup.value)" class="form-signin" *ngIf="showPreview == false">
                <h2 class="card-title ">Iniciar Sesión</h2>

                <mat-form-field class="form-group" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" autocomplete="off">
                  <div *ngIf="!email.valid && email.touched">
                    <mat-error *ngIf="email.errors.required || email.errors.email || email.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
                  </div>
                </mat-form-field>

                <mat-form-field class="form-group" appearance="outline">
                  <mat-label>Contraseña</mat-label>
                  <input matInput formControlName="password" type="password" autocomplete="off">
                  <div *ngIf="!password.valid && password.touched">
                    <mat-error *ngIf="password.errors.required">Debe ingresar una contraseña.</mat-error>
                  </div>
                </mat-form-field>

                <button class="btn btn-main btn-lg btn-block" type="submit">Iniciar</button>
                <button class="btn btn-default float-right" type="button" (click)="showPreviewReset()">Recuperar contraseña</button>
            </form>

            <!--form [formGroup]="resetFormGroup" (ngSubmit)="resetPassword(resetForm)" #resetForm="ngForm" class="form-reset" *ngIf="showPreview == true">
              <h2>Recuperar contraseña</h2>

              <mat-form-field class="form-group" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" autocomplete="off">
                <div *ngIf="!resetMail.valid && resetMail.touched">
                  <mat-error *ngIf="resetMail.errors.required || resetMail.errors.email || resetMail.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
                </div>
              </mat-form-field>

              <button class="btn btn-main btn-lg btn-block" type="submit">Enviar</button>
              <button class="btn btn-default float-right" type="button" (click)="canceled()">Cancelar</button>

            </form-->
          </div>
        </div>
      </div>
    </div>
</div>

