import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PlaceService } from 'src/app/core/services/place.service'
import { PlaceDialogData, showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-place',
  templateUrl: './edit-place.component.html'
})
export class EditPlaceComponent implements OnInit {

  formGroup: UntypedFormGroup
  @ViewChild("fileInput") fileUpload: ElementRef
  imagePath = ""

  priceTypes = [
    {id: 1, name: 'Hora'},
    {id: 2, name: 'Día'},
    {id: 3, name: 'Evento'}
  ]

  cancelType = [
    {id: 1, name: 'No aplica'},
    {id: 2, name: 'Horas'},
    {id: 3, name: 'Dias'}
  ]
  constructor(private fb: UntypedFormBuilder, private placeService: PlaceService, @Inject(MAT_DIALOG_DATA) public data: PlaceDialogData, public dialogRef: MatDialogRef<EditPlaceComponent>) { 
    this.createForm()
    this.imagePath = data.place.image
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [this.data.place.name, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]],
      'price_type': [this.data.place.price_type, [Validators.required, Validators.maxLength(60), Validators.minLength(3)]],
      'price': [this.data.place.price, [Validators.maxLength(7), Validators.pattern("^[-]?[0-9]*[.]?[0-9]{0,2}$")]],
      'description': [this.data.place.description, [Validators.maxLength(500)]],
      'cancellation_type': [this.data.place.cancellation_type, [Validators.required]],
      'cancellation_minimum': [this.data.place.cancellation_minimum, [Validators.pattern("^[0-9]{0,3}$"), Validators.maxLength(3)]],
      'fileSource': [null]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get description() {
    return this.formGroup.get('description') as UntypedFormControl
  }

  get price_type() {
    return this.formGroup.get('price_type') as UntypedFormControl
  }

  get image() {
    return this.formGroup.get('image') as UntypedFormControl
  }

  get fileSource() {
    return this.formGroup.get('fileSource') as UntypedFormControl
  }

  get price() {
    return this.formGroup.get('price') as UntypedFormControl
  }

  get cancellationType() {
    return this.formGroup.get('cancellation_type') as UntypedFormControl
  }

  get cancellationMinimum() {
    return this.formGroup.get('cancellation_minimum') as UntypedFormControl
  }

  addFile() {
    this.fileUpload.nativeElement.click()
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const image = event.target.files[0]
      const extension = image.name.split('.')[image.name.split('.').length - 1].toLowerCase()
      if(image.size > 5000000) {
        showMessage("¡Error!", "El archivo no puede superar las 5Mb.", false)
        return
      }
      if(extension === "pdf" || extension === "jpg" || extension === "jpeg" || extension === "png") {
        this.formGroup.patchValue({
          fileSource: image,
        })

        const reader = new FileReader()
        reader.onload = () => {
          this.imagePath = reader.result as string
        }
        reader.readAsDataURL(image)
      } else {
        showMessage("¡Error!", "El formato del archivo no está permitido, tenga en cuenta que solo se pueden subir imagenes en formato png, jpg y archivos pdf.", false)
        return
      }
    }
  }

  onSubmit(form) {
    Swal.showLoading()
    this.placeService.updatePlace(form, this.data.place.id).subscribe(
      data => {
        Swal.close()
        showMessage('¡Completo!', data.message, true)
        this.dialogRef.close(data.place)
      }, error => {
        showMessage("Error!", error, false)
      })
  }

  close(): void {
    this.dialogRef.close(false)
  }
}
