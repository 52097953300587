import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/core/services/auth.service'
import { NavigationEnd, Router } from '@angular/router'
import { SessionType, Session } from 'src/app/core/models/session.modules'
import { Admin } from 'src/app/core/models/admin'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  admin: Admin = null;
  type: SessionType = SessionType.None;
  sessionType = SessionType;
  session: Session;

  showPreview = false;
  isWebsite = false;

  constructor(private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      if (session) {
        this.type = session.type;
      } else {
        this.type = this.sessionType.None
      }
    });

    var session = this.auth.readToken();
    if (session) {
      this.type = session.type;
      this.admin = session.admin;
    }

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      var url = event['url'] as String
      this.isWebsite = !(url.startsWith('/admin') || url.startsWith('/support'))
      console.log(this.isWebsite)
    })
  }

  ngOnInit(): void {
  }

  leave() {
    this.session = this.auth.readToken();
    if (this.session) {
      if (this.session.type == SessionType.Admin) {
        this.auth.logout();
        this.router.navigateByUrl('/admin');
      }
    }
    if (this.session.type == SessionType.Support) {
      this.auth.logout();
      this.router.navigateByUrl('/support');
    }
    this.type = this.sessionType.None;

  }

  showSidebar() {
    this.showPreview = true
  }

}
