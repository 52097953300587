import {Injectable, EventEmitter} from '@angular/core';
import {Booking} from '../models/booking';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppSettings} from '../appsettings';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Place } from '../models/place';
import { BookingResponse } from '../reponse/data.response';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  userSelected = new EventEmitter<Booking>();


  httpOptions: {};
  /**
   * constructor
   * @param http cliente de manejo de peticiones de http.
   * @param auth referencia a la clase AuthService.
   * @param router librería de angular para manejar rutas.
   */
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '');
    });
    this.updateToken(localStorage.getItem('token'));
  }
  /**
   * updateToken
   * @param token llave que hace referencia al tipo de sesión activa.
   */
  updateToken (token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    };
  }

  getBookings(idMonth: number, idYear: number): Observable<Booking[]> {
    const data = {
      month: idMonth,
      year: idYear
    }
    return this.http.post<Booking[]>(
      `${AppSettings.url}/admin/booking/all`, data, this.httpOptions
    ).pipe(
      map(resp => {
        return resp['bookings'].map(booking => new Booking().deserialize(booking))
      })
    )
  }

  getBookingId(id: number): Observable<BookingResponse> {
    return this.http.get<any>(
      `${AppSettings.url}/admin/booking/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        let place = new Place().deserialize(resp['place'])
        let booking = new Booking().deserialize(resp['booking'])
        return new BookingResponse(booking, place, "")
      })
    );
  }

}
