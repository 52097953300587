import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BlogService } from 'src/app/core/services/blog.service'
import { PlaceDialogData, showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'


@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html'
})
export class CreateBlogComponent implements OnInit {

  formGroup: UntypedFormGroup

  statusArr = [
    { id: true, name: 'Activo' },
    { id: false, name: 'Inactivo' }
  ]

  constructor(private fb: UntypedFormBuilder, private blogService: BlogService, public dialogRef: MatDialogRef<CreateBlogComponent>) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'title': [null, [Validators.required]],
      'content': [null, [Validators.required]],
      'description': [null, [Validators.required]],
      'tag': [null, [Validators.required]],
      'status': [null, [Validators.required]],
      'fileSource': [null],
      'image': [null],
    })
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const photo = event.target.files[0];
      this.formGroup.patchValue({
        fileSource: photo
      })
    }
  }

  get title() {
    return this.formGroup.get('title') as UntypedFormControl
  }

  get content() {
    return this.formGroup.get('content') as UntypedFormControl
  }

  get description() {
    return this.formGroup.get('description') as UntypedFormControl
  }

  get tag() {
    return this.formGroup.get('tag') as UntypedFormControl
  }

  get image() {
    return this.formGroup.get('image') as UntypedFormControl
  }

  get fileSource() {
    return this.formGroup.get('fileSource') as UntypedFormControl
  }

  get status() {
    return this.formGroup.get('status') as UntypedFormControl
  }



  onSubmit(form) {
    Swal.showLoading()


    this.blogService.createBlog(form).subscribe(
      data => {
        Swal.close()
        showMessage('¡Guardado!', data.message, true)
        this.dialogRef.close(data.blog)
      }, error => {
        showMessage("Error!", error, false)
      })
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
