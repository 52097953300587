<div class="container modal-ag">
    <h2>Editar {{ building.type == 1 ? 'Apartamento' : 'Oficina' }}</h2>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label> Nombre</mat-label>
            <input matInput formControlName="name">
            <div *ngIf="!name.valid && name.touched">
                <mat-error *ngIf="name.errors.required">Debe ingresar un nombre válido.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline" *ngIf="building.type == 1">
            <mat-label>Metraje</mat-label>
            <input matInput inputmode="numeric" formControlName="footage" autocomplete="off" validateOnBlur [validateFormControl]="formGroup.controls['footage']">
            <div *ngIf="!footage.valid && footage.touched">
                <mat-error *ngIf="footage.errors.pattern">Debe ingresar un metraje válido.</mat-error>
                <mat-error *ngIf="footage.errors.maxlength">El metraje supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline" *ngIf="building.type == 1">
            <mat-label>Coeficinete</mat-label>
            <input matInput inputmode="numeric" formControlName="coefficient" autocomplete="off" validateOnBlur [validateFormControl]="formGroup.controls['coefficient']">
            <div *ngIf="!coefficient.valid && coefficient.touched">
                <mat-error *ngIf="coefficient.errors.pattern">Debe ingresar un coeficinete válido.</mat-error>
                <mat-error *ngIf="coefficient.errors.maxlength">El coeficiente supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field> 

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form>
</div>