import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../appsettings';
import { catchError, map } from 'rxjs/operators';
import { GlobalErrorHandler } from '../util/common';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  errorHandler = new GlobalErrorHandler()
  url = ""

  constructor(private http: HttpClient) {
   this.url = AppSettings.url 
  }

  sendEmail(form): Observable<string> {
    const data = {
      name: form.name,
      building: form.building,
      email: form.email,
      message: form.message,
    }
    return this.http.post<string>(
      `${this.url}/post/mail`, data, this.httpOptions
    ).
    pipe(
      map(data => {
        return data["message"]
      }), 
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
