import {Desearizable} from './desearizable';

export class City implements Desearizable {

  public id: number
  public name: string
  public country_code: string

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
  
}
