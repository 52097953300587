import { Admin } from './admin'
import { Support } from '../models/support.modules'
import { Building } from './building'
import { Desearizable } from './desearizable'

export enum SessionType {
    None = 0,
    SuperAdmin = 1,
    Admin = 2,
    Support = 3,
}

export class Session implements Desearizable {
    public token: string
    public admin: Admin
    public building: Building
    public support: Support
    public type: SessionType
    public session: string

    deserialize(input: any): this {
        Object.assign(this, input)
        return this;
    }

}
