import { Desearizable } from './desearizable'

export class Doorman implements Desearizable {

    public id: number
    public name: string
    public first_name: string
    public last_name: string
    public cellphone: string
    public document_id: string
    public status: boolean

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getStatus(){
        return this.status ? "Activo" : "Inactivo"
    }
    
    getName(){
        return `${this.first_name} ${this.last_name}`
    }

}
