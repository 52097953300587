<mat-spinner *ngIf="apartment == null" [diameter]="60"></mat-spinner>
<div class="container admin-container">

    <div class="card" *ngIf="apartment">
      
        <div class="card-header">
        <h1>{{ apartment.building?.name }} {{ apartment.building?.type == 2 ? '(Negocio)' : '' }}</h1>
        <h2>{{ apartment.block?.name }} - {{ apartment?.name }}</h2>
      </div>

      <div class="card-body">

        <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
            <mat-tab label="Usuarios">
    
                <div class="card">
                    <div class="card-header mt-0">
                        <div class="options">
                            <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                            <button type="button" class="btn" (click)="createUser()">Nuevo +</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                                <td mat-cell *matCellDef="let user">{{ user.getName() }}</td>
                            </ng-container>
            
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef>Email</th>
                                <td mat-cell *matCellDef="let user">{{ user.email }}</td>
                            </ng-container>
            
                            <ng-container matColumnDef="phone">
                                <th mat-header-cell *matHeaderCellDef>Teléfono</th>
                                <td mat-cell *matCellDef="let user">{{ user.phone }}</td>
                            </ng-container>
            
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef>Tipo de Usuario</th>
                                <td mat-cell *matCellDef="let user">{{ user.getType() }}</td>
                            </ng-container>
            
                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef>Estado</th>
                                <td mat-cell *matCellDef="let user">{{ user.getStatus() }}</td>
                            </ng-container>
    
                            <ng-container matColumnDef="activation">
                                <th mat-header-cell *matHeaderCellDef>Activación</th>
                                <td mat-cell *matCellDef="let user">{{ user.actived_at }}</td>
                            </ng-container>
            
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                                <td mat-cell *matCellDef="let user">
                                    <button class="btn" type="button" (click)="sendActivation(user)"><mat-icon aria-hidden="false">send</mat-icon></button>
                                    <button class="btn btn-link" (click)="editUser(user)"><mat-icon aria-hidden="false">create</mat-icon></button>
                                </td>
                            </ng-container>
            
            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
    
            </mat-tab>
            <mat-tab label="Paquetes"><app-packages [apartment]="apartment" ></app-packages></mat-tab>
            <mat-tab label="Recibos"><app-bill [apartment]="apartment"></app-bill></mat-tab>
            <mat-tab label="Domicilios"><app-delivery [apartment]="apartment"></app-delivery></mat-tab>
            <!--mat-tab label="Visitas"><app-visits></app-visits> </mat-tab-->
            <!--mat-tab label="Administracion"><app-administration></app-administration></mat-tab-->
        </mat-tab-group>

      </div>
    </div>
</div>