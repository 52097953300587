import { Desearizable } from './desearizable'
import { ContactPhone } from './contact.phone'
import { ContactMail } from './contact.mail'

export class Contact implements Desearizable {

  public id: number = -1
  public name: string
  public dependency_id: number

  public phones: ContactPhone[]
  public mails: ContactMail[]

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

}