import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WebsiteService } from 'src/app/core/services/website.service';
import { showMessage } from 'src/app/core/util/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-website-contact',
  templateUrl: './website-contact.component.html',
  styleUrls: ['./website-contact.component.sass']
})
export class WebsiteContactComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private websiteService: WebsiteService) { 
    this.createForm()
  }

  ngOnInit(): void {
  }

  formGroup: UntypedFormGroup

  createForm() {
    this.formGroup = this.fb.group({
      'name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(1)]],
      'building': [null, [Validators.maxLength(60), Validators.minLength(1)]],
      'email': [null, [Validators.required, Validators.email, Validators.maxLength(60), Validators.minLength(1)]],
      'message': [null, [Validators.required, Validators.maxLength(100), Validators.minLength(1)]],
    });
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get building() {
    return this.formGroup.get('building') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get message() {
    return this.formGroup.get('message') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.websiteService.sendEmail(form).subscribe(
      data => {
        this.formGroup.reset()
        Swal.close()
        showMessage('¡Enviado!', data, true)
      },
      error => {
        showMessage('¡Error!', error, false)
      }
    )
  }

}
