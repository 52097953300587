import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { ErrorHandler, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule } from '@angular/material/tabs'
import { MatSidenavModule } from '@angular/material/sidenav'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MaterialFileInputModule } from 'ngx-material-file-input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatDialogModule } from '@angular/material/dialog'

import { NgxCurrencyModule } from "ngx-currency"
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatStepperModule } from '@angular/material/stepper'
import { MatSliderModule } from '@angular/material/slider'
import { MatButtonToggleModule } from '@angular/material/button-toggle'

import { AuthService } from './core/services/auth.service';
import { AuthSupportGuard } from './core/guards/auth-support.guard';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './components/shared/header/header.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { FooterComponent } from './components/shared/footer/footer.component';

import { AdminComponent } from './components/admin/admin.component';
import { SupportComponent } from './components/support/support.component';

import { LoginAdminComponent } from './components/login/login-admin/login-admin.component';
import { LoginSupportComponent } from './components/login/login-support/login-support.component';
import { CreateUserComponent } from './components/shared/apartment/apartment-detail/user/create-user/create-user.component';
import { EditUserComponent } from './components/shared/apartment/apartment-detail/user/edit-user/edit-user.component';
import { BuildingComponent } from './components/support/building/building.component';

import { BuildingAdminComponent } from './components/support/building/building-admin/building-admin.component';
import { DoormanComponent } from './components/support/building/doorman/doorman.component';
import { ApartmentDetailComponent } from './components/shared/apartment/apartment-detail/apartment-detail.component';

import { DeliveryComponent } from './components/shared/apartment/apartment-detail/delivery/delivery.component';
import { VisitComponent } from './components/shared/apartment/apartment-detail/visit/visit.component';
import { PackageComponent } from './components/shared/apartment/apartment-detail/package/package.component';
import { BillComponent } from './components/shared/apartment/apartment-detail/bill/bill.component';
//import { AdministrationComponent } from './components/support/building/apartment/apartment-detail/administration/administration.component';

import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { AdminApartmentComponent } from './components/admin/admin-apartment/admin-aparment.component';
import { PaymentComponent } from './components/admin/payment/payment.component';
import { AdminDetailComponent } from './components/admin/admin-apartment/admin-detail/admin-detail.component';
import { MessageComponent } from './components/admin/admin-apartment/admin-detail/message/message.component';
import { PaymentDetailComponent } from './components/admin/payment/payment-detail/payment-detail.component';
import { PaymentCreateComponent } from './components/admin/payment/payment-create/payment-create.component';
import { ReservationComponent } from './components/support/building/reservation/reservation.component';

import { DatePipe } from '@angular/common';

import { SubscriptionComponent } from './components/support/subscription/subscription.component';
import { BuildingSubscriptionComponent } from './components/support/building/building-subscription/building-subscription.component';
import { SupportDashboardComponent } from './components/support/support-dashboard/support-dashboard.component';
import { BookingComponent } from './components/admin/place/booking/booking.component';

import { CreateBlockComponent } from './components/shared/block/create-block/create-block.component';
import { EditBlockComponent } from './components/shared/block/edit-block/edit-block.component';
import { CreateApartmentComponent } from './components/shared/apartment/create-apartment/create-apartment.component';
import { EditApartmentComponent } from './components/shared/apartment/edit-apartment/edit-apartment.component';

import { CreateDoormanComponent } from './components/shared/doorman/create-doorman/create-doorman.component';
import { EditDoormanComponent } from './components/shared/doorman/edit-doorman/edit-doorman.component';
import { CreateBuildingComponent } from './components/support/building/building/create-building/create-building.component'
import { EditBuildingComponent } from './components/support/building/building/edit-building/edit-building.component'

import { CreateBuildingAdminComponent } from './components/support/building/building-admin/create-building-admin/create-building-admin.component'
import { EditBuildingAdminComponent } from './components/support/building/building-admin/edit-building-admin/edit-building-admin.component'
import { BuildingDetailComponent } from './components/support/building/building/building-detail/building-detail.component'

import { AdminPlaceComponent } from './components/admin/place/admin-place.component'
import { PlaceComponent } from './components/support/building/place/place.component'
import { CreatePlaceComponent } from './components/shared/place/create-place/create-place.component'
import { EditPlaceComponent } from './components/shared/place/edit-place/edit-place.component'

import { MessageAdminComponent } from './components/admin/message-admin/message-admin.component'
import { CreateMessageComponent } from './components/admin/message-admin/create-message/create-message.component'
import { GlobalErrorHandler } from './core/util/common'

import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RequestComponent } from './components/admin/request/request.component';
import { CreateRequestComponent } from './components/admin/request/create-request/create-request.component';
import { WalkthroughComponent } from './components/admin/walkthrough/walkthrough.component'

import { FocusDirective } from './core/directives/FocusDirective'
import { ScrollToBottomDirective } from './core/directives/scroll-to-bottom-directive'
import { ActiveComponent } from './components/user/active/active.component'
import { AdminDoormanComponent } from './components/admin/admin-doorman/admin-doorman.component'
import { LightboxModule } from 'ngx-lightbox'
import { LogbookComponent } from './components/admin/logbook/logbook.component'
import { BookingDetailComponent } from './components/admin/place/booking/booking-detail/booking-detail.component'

import { HomeComponent } from './components/website/home/home.component';
import { AboutComponent } from './components/website/about/about.component';
import { LegalComponent } from './components/website/legal/legal.component'
import { DownloadComponent } from './components/website/download/download.component'
import { LicenseComponent } from './components/website/license/license.component'
import { WebsiteHeaderComponent } from './components/website/website-header/website-header.component'
import { WebsiteFooterComponent } from './components/website/website-footer/website-footer.component'
import { WebsiteBlogComponent } from './components/website/website-blog/website-blog.component'
import { WebsiteContactComponent } from './components/website/website-contact/website-contact.component';


import { ContactComponent } from './components/shared/contact/contact.component'

import { ServiceCompanyComponent } from './components/support/service-company/service-company.component'
import { CreateServiceCompanyComponent } from './components/support/service-company/create-service-company/create-service-company.component'
import { EditServiceCompanyComponent } from './components/support/service-company/edit-service-company/edit-service-company.component'
import { DetailServiceCompanyComponent } from './components/support/service-company/detail-service-company/detail-service-company.component'
import { CreateDependencyComponent } from './components/support/service-company/detail-service-company/create-dependency/create-dependency.component'
import { EditDependencyComponent } from './components/support/service-company/detail-service-company/edit-dependency/edit-dependency.component'
import { CreateContactComponent } from './components/support/service-company/detail-service-company/create-contact/create-contact.component'
import { EditContactComponent } from './components/support/service-company/detail-service-company/edit-contact/edit-contact.component';
import { AssignServiceCompanyComponent } from './components/support/service-company/assign-service-company/assign-service-company.component';
import { BlogComponent } from './components/website/blog/blog.component'
import { CreateBlogComponent } from './components/website/blog/create-blog/create-blog.component'
import { BlogDetailComponent } from './components/website/blog/blog-detail/blog-detail.component'

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        AdminComponent,
        SupportComponent,
        LoginAdminComponent,
        LoginSupportComponent,
        CreateUserComponent,
        EditUserComponent,
        BuildingComponent,
        BuildingAdminComponent,
        DoormanComponent,
        ApartmentDetailComponent,
        DeliveryComponent,
        VisitComponent,
        PackageComponent,
        BillComponent,
        DashboardComponent,
        AdminApartmentComponent,
        PaymentComponent,
        AdminDetailComponent,
        MessageComponent,
        PaymentDetailComponent,
        PaymentCreateComponent,
        ReservationComponent,
        HomeComponent,
        SubscriptionComponent,
        BuildingSubscriptionComponent,
        SupportDashboardComponent,
        BookingComponent,
        CreateBlockComponent,
        EditBlockComponent,
        CreateApartmentComponent,
        EditApartmentComponent,
        CreateDoormanComponent,
        EditDoormanComponent,
        CreateBuildingComponent,
        EditBuildingComponent,
        CreateBuildingAdminComponent,
        EditBuildingAdminComponent,
        BuildingDetailComponent,
        PlaceComponent,
        CreatePlaceComponent,
        EditPlaceComponent,
        MessageAdminComponent,
        AdminPlaceComponent,
        CreateMessageComponent,
        RequestComponent,
        CreateRequestComponent,
        WalkthroughComponent,
        ActiveComponent,
        AdminDoormanComponent,
        FocusDirective,
        ScrollToBottomDirective,
        LogbookComponent,
        BookingDetailComponent,
        LegalComponent,
        DownloadComponent,
        LicenseComponent,
        ContactComponent,
        ServiceCompanyComponent,
        CreateServiceCompanyComponent,
        EditServiceCompanyComponent,
        DetailServiceCompanyComponent,
        CreateDependencyComponent,
        EditDependencyComponent,
        CreateContactComponent,
        EditContactComponent,
        AssignServiceCompanyComponent,
        BlogComponent,
        CreateBlogComponent,
        BlogDetailComponent,
        WebsiteHeaderComponent,
        WebsiteFooterComponent,
        WebsiteBlogComponent,
        AboutComponent,
        WebsiteContactComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LightboxModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NgxMatIntlTelInputComponent,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatSidenavModule,
        [NgxMaterialTimepickerModule],
        MatDatepickerModule,
        MatNativeDateModule,
        MatPasswordStrengthModule,
        MatProgressSpinnerModule,
        CommonModule,
        MaterialFileInputModule,
        MatTooltipModule,
        MatDialogModule,
        NgxCurrencyModule,
        MatCheckboxModule,
        MatStepperModule,
        MatSliderModule,
        MatButtonToggleModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        AuthService,
        AuthSupportGuard,
        AuthAdminGuard,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
