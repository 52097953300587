import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Admin } from 'src/app/core/models/admin'
import { AuthService } from 'src/app/core/services/auth.service'
import { BuildingDialogData, showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html'
})
export class WalkthroughComponent implements OnInit {

  admin: Admin  = null
  passwordFormGroup: UntypedFormGroup

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<WalkthroughComponent>) {
    this.admin =this.data.admin
  }

  ngOnInit(): void {
    this.passwordFormGroup = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.max(15)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.max(15)]]
    }, { validator: [this.confirmedValidator, this.checkPassword ]})
  }

  get password() {
    return this.passwordFormGroup.get('password') as UntypedFormControl
  }

  get confirmPassword() {
    return this.passwordFormGroup.get('confirmpassword') as UntypedFormControl
  }

  checkPassword(form: UntypedFormGroup) {
    const password = form.controls.password.value
    return password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,})$/) ? null : form.controls.password.setErrors({ passwordFormat: true })
  }

  confirmedValidator(form: UntypedFormGroup) {
    const pass = form.controls.password.value
    const confirmPass = form.controls.confirmpassword.value
    return pass === confirmPass ? null : form.controls.confirmpassword.setErrors({ confirmedValidator: true })
  }

  finish() {
    this.dialogRef.close()
  }

  onSubmit(form, stepper: MatStepper) {
    Swal.showLoading()
    this.authService.updatePassword(form).subscribe (
      data => {
        Swal.close()
        showMessage('Completo', data, true)
        this.enableAdmin()
        stepper.next()
      },
      error => {
        Swal.close()
        showMessage('Error', error, false)
      }
    )
  }

  enableAdmin() {
    this.authService.enableAdmin()
  }

}
