import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Dependency } from 'src/app/core/models/dependency'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-dependency',
  templateUrl: './edit-dependency.component.html'
})
export class EditDependencyComponent implements OnInit {

  formGroup: UntypedFormGroup
  dependency: Dependency

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<EditDependencyComponent>) { 
    this.dependency = data.dependency
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [this.dependency.name, [Validators.required, Validators.maxLength(60), Validators.minLength(4)]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.contactService.updateDependency(form, this.dependency.id).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
        this.dialogRef.close(data.dependency)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}