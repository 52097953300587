import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Building } from 'src/app/core/models/building'
import { Dependency } from 'src/app/core/models/dependency'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'
import { AssignServiceCompanyComponent } from '../../support/service-company/assign-service-company/assign-service-company.component'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  @Input() building: Building

  displayedColumns: string[] = ['id', 'company', 'dependency', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private contactService: ContactService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadDependencies()
  }

  loadDependencies() {
    this.contactService.getDependenciesByBuilding(this.building.id).subscribe(
      data => {
        this.dataSource.data = data
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  createContact() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {building: this.building}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(AssignServiceCompanyComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(dependency => {
      if(dependency){
        console.log(dependency.O)
        const data = this.dataSource.data
        data.push(dependency)
        this.dataSource.data = data
      }
    })
  }

  removeDependency(dependency) {
    Swal.showLoading()
    this.contactService.removeDependency(dependency.id, this.building.id).subscribe(
      result => {
        Swal.close()
        showMessage("¡Completo!", result.message, true)
        const data = this.dataSource.data
        const index = data.findIndex((dependency: Dependency) => dependency.id == result.dependency.id)
        data.splice(index, 1)
        this.dataSource.data = data
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

}