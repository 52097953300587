import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { Dependency } from 'src/app/core/models/dependency'
import { Contact } from 'src/app/core/models/contact'
import { ServiceCompany } from 'src/app/core/models/service.company'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import { CreateContactComponent } from './create-contact/create-contact.component'
import { CreateDependencyComponent } from './create-dependency/create-dependency.component'
import { EditDependencyComponent } from './edit-dependency/edit-dependency.component'
import { EditContactComponent } from './edit-contact/edit-contact.component'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { getLocaleDateFormat } from '@angular/common'
import { ContactPhone } from 'src/app/core/models/contact.phone'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-detail-service-company',
  templateUrl: './detail-service-company.component.html'
})
export class DetailServiceCompanyComponent implements OnInit {

  id = -1
  loading = true
  serviceCompany: ServiceCompany
  dependencies: Dependency[] = []
  selectedDependency = new Dependency()
  contacts: Contact[] = []
  selectedContact = new Contact()

  formGroup: UntypedFormGroup
  phoneFields = []
  mailFields = []

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private contactService: ContactService, public dialog: MatDialog) { 
    this.route.paramMap.subscribe(params => {
      let id = Number(params.get('id'))
      this.loadData(id)
    })
  }

  ngOnInit(): void {
    this.loadForm()
  }

  loadData(id) {
    this.contactService.getServiceCompany(id).subscribe(data => {
      this.loading = false
      this.serviceCompany = data.serviceCompany
      this.dependencies = data.dependencies
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  createDependency() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { company: this.serviceCompany }
    const dialogRef = this.dialog.open(CreateDependencyComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(dependency => {
      if(dependency) {
        this.dependencies.push(dependency)
      }
    })
  }

  editDependency(dependency: Dependency) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { dependency: dependency }
    const dialogRef = this.dialog.open(EditDependencyComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(dependency => {
      if(dependency) {
        let index = this.dependencies.findIndex((item: Dependency) => item.id == dependency.id)
        this.dependencies[index] = dependency
      }
    })
  }

  showDependency(dependency: Dependency) {
    this.contactService.getDependency(dependency.id).subscribe(data => {
      this.selectedDependency = data.dependency
      this.contacts = data.dependency.contacts
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  showContact(contact: Contact) {
    this.phones.controls = []
    this.mails.controls = []
    this.selectedContact = new Contact()

    this.contactService.getContact(contact.id).subscribe(data => {
      this.loading = false
      this.selectedContact = data.contact
      this.loadContact()
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  createContact() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { dependency: this.selectedDependency }
    const dialogRef = this.dialog.open(CreateContactComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(contact => {
      if(contact) {
        this.contacts.push(contact)
      }
    })
  }

  editContact(contact: Contact) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { contact: contact }
    const dialogRef = this.dialog.open(EditContactComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(contact => {
      if(contact) {
        let index = this.contacts.findIndex((item: Contact) => item.id == contact.id)
        this.contacts[index] = contact
      }
    })
  }

  loadForm() {

    this.formGroup = this.fb.group({
      phones: this.fb.array([]),
      mails: this.fb.array([])
    })

    /*for(let pf of this.phoneFields) {
      this.formGroup.removeControl(pf)
    }
    for(let mf of this.mailFields) {
      this.formGroup.removeControl(mf)
    }
    
    this.phoneFields = [] 
    this.mailFields = []
    
    this.selectedContact.phones.push(new ContactPhone())
    for(let pf of this.selectedContact.phones) {
      const key = pf.id.toString()
      const value = pf.phone
      console.log(key + " - " + value )
      this.formGroup.addControl(key, new FormControl(value))
      this.phoneFields.push(key)
    }*/

  }

  loadContact() {
    this.phones.controls = []
    this.mails.controls = []

    this.selectedContact.phones.forEach(phone => {
      this.addPhone(phone.phone)
    })
    this.selectedContact.mails.forEach(mail => {
      this.addMail(mail.email)
    })
  }

  get phones() {
    return this.formGroup.controls["phones"] as UntypedFormArray
  }

  get mails() {
    return this.formGroup.controls["mails"] as UntypedFormArray
  }

  addPhone(value = '') {
    this.phones.controls.push(new UntypedFormControl(value))
  }

  removePhone(i) {
    this.phones.removeAt(i)
  }

  addMail(value = '') {
    this.mails.controls.push(new UntypedFormControl(value))
  }

  removeMail(i) {
    this.mails.removeAt(i)
  }

  removeContact(contact) {
    Swal.showLoading()
    this.contactService.removeContact(contact.id).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
        let index = this.contacts.findIndex(x => x.id == contact.id)
        this.contacts.splice(index, 1)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  onSubmit(form) {
    var pA = Array()
    this.phones.controls.forEach((element, index) => {
      pA.push(element.value)
    }); 

    var eA = Array()
    this.mails.controls.forEach((element, index) => {
      eA.push(element.value)
    }); 

    Swal.showLoading()
    this.contactService.updateContactInfo(form, pA, eA, this.selectedContact.id).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

}