import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'

import { gsap, Power3 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import ScrollTrigger  from 'gsap/ScrollTrigger'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { WebsiteService } from 'src/app/core/services/website.service'
import { showMessage } from 'src/app/core/util/common'

export {}; declare global { interface Window { Calendly: any; } } 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  
})

export class HomeComponent implements OnInit {

  @ViewChild('motionSVG') motionSVG: ElementRef
  @ViewChild('tractor') tractor: ElementRef
  @ViewChild('motionPath') motionPath: ElementRef

  animation?: any
  animation2?: any


  constructor(private fb: UntypedFormBuilder, private websiteService: WebsiteService) { 
    this.createForm()

    
    //gsap.set(this.motionSVG, { scale: 0.7, autoAlpha: 1 });
    //gsap.set(this.tractor, {transformOrigin: "50% 50%"});

    

    /*this.animation = gsap.to(".phone", {
      scrollTrigger: {
        trigger: ".phone",
        start: "top 20%",
        end: "bottom 20%",
        scrub: 1,
        //markers: true,
        onUpdate: self => {
          gsap.to(this.tractor, {rotation: () => self.direction === 1 ? 0 : -180, overwrite: 'auto'});
        }
      },
      duration: 10,
      ease: "none",
      immediateRender: true,
      motionPath: {
        path: ".phone",
        align: ".phone",
        alignOrigin: [0.5, 0.5],
        autoRotate: 90,
      }
    })*/

  }

    ngOnInit(): void {

      gsap.registerPlugin(ScrollToPlugin, MotionPathPlugin, ScrollTrigger)

      var navbarAnimation = ScrollTrigger.create({
        start: () =>  window.innerHeight/5,
        end: 99999,
        toggleClass: {className: 'nav-web--scrolled', targets: '.nav-web'}
      })

        /*this.animation = gsap.to(".phone", {
                scrollTrigger: {
                    trigger: "#motionPath",
                    start: "top 20%",
                    end: "bottom 0",
                    scrub: 1,
                    //markers: true,
                    onUpdate: self => {
                    //gsap.to(".phone", {rotation: () => 0, overwrite: 'auto'});
                    }
                },
                duration: 1,
                ease: "linear",
                immediateRender: true,
                motionPath: {
                    path: "#motionPath",
                    align: "#motionPath",
                    alignOrigin: [0.5, 0]
                }
            })
*/
        /*this.animation2 = ScrollTrigger.create({
            trigger: ".website",
            start: "top top",
            end: "bottom bottom",
            onUpdate: this.getCurrentSection,
            pin: ".left-content"
        })*/

        window.Calendly.initBadgeWidget({ 
          url: 'https://calendly.com/oscargonzalez-mri/biru',
          text: 'Agenda una cita', 
          color: '#ffffff', 
          textColor: '#262262'
        });

    }

    getCurrentSection () {

    }

    formGroup: UntypedFormGroup

    createForm() {
      this.formGroup = this.fb.group({
        'name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(1)]],
        'building': [null, [Validators.maxLength(60), Validators.minLength(1)]],
        'email': [null, [Validators.required, Validators.email, Validators.maxLength(60), Validators.minLength(1)]],
        'message': [null, [Validators.required, Validators.maxLength(100), Validators.minLength(1)]],
      });
    }

    get name() {
      return this.formGroup.get('name') as UntypedFormControl
    }

    get building() {
      return this.formGroup.get('building') as UntypedFormControl
    }

    get email() {
      return this.formGroup.get('email') as UntypedFormControl
    }

    get message() {
      return this.formGroup.get('message') as UntypedFormControl
    }

    onSubmit(form) {
      Swal.showLoading()
      this.websiteService.sendEmail(form).subscribe(
        data => {
          this.formGroup.reset()
          Swal.close()
          showMessage('¡Enviado!', data, true)
        },
        error => {
          showMessage('¡Error!', error, false)
        }
      )
    }
  

}
