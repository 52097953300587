import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Booking } from 'src/app/core/models/booking'
import { Place } from 'src/app/core/models/place'
import { BookingService } from 'src/app/core/services/booking.service'

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html'
})
export class BookingDetailComponent implements OnInit {

  place = new Place()
  booking = new Booking()

  constructor(private bookingService: BookingService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<BookingDetailComponent>) {
    bookingService.getBookingId(this.data.id).subscribe(resp => {
      this.place = resp.place
      this.booking = resp.booking
    })
  }

  ngOnInit(): void {
  }

}
