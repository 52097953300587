import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Blog } from 'src/app/core/models/blog'
import { BlogService } from 'src/app/core/services/blog.service'
import { showMessage } from 'src/app/core/util/common'
import { CreateBlogComponent } from '../../website/blog/create-blog/create-blog.component'


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html'
})
export class BlogComponent implements OnInit {

  displayedColumns: string[] = ['title', 'description', 'tag', 'image', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(private blogService: BlogService, public dialog: MatDialog) {
    this.blogService.getBlogs().subscribe(data => {
      this.dataSource.data = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  createBlog() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '650px'
    const dialogRef = this.dialog.open(CreateBlogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = this.dataSource.data
        data.push(result)
        this.dataSource.data = data
      }
    })
  }

}
