import { Component, OnInit, Input } from '@angular/core'
import { SessionType, Session } from 'src/app/core/models/session.modules'
import { AuthService } from 'src/app/core/services/auth.service'
import { Router } from '@angular/router'
import { BuildingService } from 'src/app/core/services/building.service'
import { Building } from 'src/app/core/models/building'
import Swal from 'sweetalert2'
import { Admin } from 'src/app/core/models/admin'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {

  admin: Admin = null
  type: SessionType = SessionType.None
  sessionType = SessionType
  session: Session
  building: Building

  constructor(private auth: AuthService, private buildingService: BuildingService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      if (session) {
        this.type = session.type
        if(this.type == SessionType.Admin){

        }
      } else {
        //this.type = this.sessionType.None
      }

    })

    var session = this.auth.readToken()
    if (session) {
      this.type = session.type
      this.admin = new Admin().deserialize(session.admin)
      this.building = new Building().deserialize(session.building)
    }
  }

  ngOnInit(): void {
  }

  loadBuilding(){
    this.buildingService.getBuilding().subscribe(data => {
      this.building = data
    }, error => {
      this.showMessage("Error", error)
    })
  }

  showMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
    })
  }

}
