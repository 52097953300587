<div class="container modal-ag">
  <h2>Crear Administrador</h2>
  <form [formGroup]="formGroup" (ngSubmit)="onSave(formGroup.value)">

    <mat-form-field class="form-group" appearance="outline">
      <mat-label></mat-label>
      <mat-select formControlName="title" >
          <mat-option *ngFor="let title of titles" [value]="title.id">
          {{ title.name }}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-group" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="first_name">
      <mat-error *ngIf="!first_name.valid && first_name.touched">
        Debes escribir un nombre válido.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-group" appearance="outline">
      <mat-label>Apellido</mat-label>
      <input matInput formControlName="last_name">
      <mat-error *ngIf="!last_name.valid && last_name.touched">
        Debes escribir un apellido válido.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-group" appearance="outline">
      <mat-label>Estado</mat-label>
      <mat-select formControlName="state" >
          <mat-option *ngFor="let state of states" [value]="state.id">
          {{ state.name }}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="form-group text-right">
      <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
      <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
    </div>

  </form>
</div>
