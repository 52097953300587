<div class="container admin-container p-4">
    <div class="card pl-0">
        <div class="card-header mt-0">
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <!--div class="filter">
                    <form [formGroup]="formGroupFilter" (ngSubmit)="onSubmit(formGroupFilter.value)">
                        <mat-date-range-input [formGroup]="formGroupFilter" class="form-group ml-3" [rangePicker]="picker">
                            <input matStartDate formControlName="date_from" placeholder="Start date">
                            <input matEndDate formControlName="date_to" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <button type="submit" class="btn btn-info ml-3" [disabled]="formGroupFilter.invalid">Buscar</button>
                    </form>
                </div-->
            </div>
        </div>
        
        <div class="card-body">
          <!--mat-spinner *ngIf="loading"></mat-spinner-->
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let package">{{ package.id }}</td>
                </ng-container-->

                <ng-container matColumnDef="delivered_company">
                    <th mat-header-cell *matHeaderCellDef>Empresa</th>
                    <td mat-cell *matCellDef="let package">{{ package.delivery_company }}</td>
                </ng-container>

                <ng-container matColumnDef="delivered">
                    <th mat-header-cell *matHeaderCellDef>Entregado</th>
                    <td mat-cell *matCellDef="let package">{{ package.getStatus() }}</td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Fecha Recepción</th>
                    <td mat-cell *matCellDef="let package">{{ package.created_at }}</td>
                </ng-container>

                <ng-container matColumnDef="doorman">
                    <th mat-header-cell *matHeaderCellDef>Portero que recibio</th>
                    <td mat-cell *matCellDef="let package">{{ package.doorman }}</td>
                </ng-container>

                <ng-container matColumnDef="delivery_at">
                    <th mat-header-cell *matHeaderCellDef>Fecha Entrega</th>
                    <td mat-cell *matCellDef="let package">{{ package.delivered_at }}</td>
                </ng-container>

                <ng-container matColumnDef="doorman_delivered">
                    <th mat-header-cell *matHeaderCellDef>Portero que entrego</th>
                    <td mat-cell *matCellDef="let package">{{ package.doorman_delivered }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let doorman">
                        <button class="btn btn-link" type="button" ><mat-icon aria-hidden="false">more_horiz</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

