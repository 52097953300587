import { Component, OnInit, Inject } from '@angular/core'
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BlockService } from 'src/app/core/services/block.service'
import { BuildingDialogData, showMessage } from 'src/app/core/util/common'

import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-block',
  templateUrl: './edit-block.component.html'
})
export class EditBlockComponent implements OnInit {

  formGroup: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder, private blockservice: BlockService, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<EditBlockComponent>) { 
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [this.data.block.name, [Validators.required, Validators.maxLength(60), Validators.minLength(1)]],
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    const send = {
      name: form.name,
      building_id: this.data.id
    }
    this.blockservice.updateBlock(send, this.data.block.id).subscribe(
      data => {
        this.formGroup.reset()
        Swal.close()
        showMessage('¡Guardado!', data.message, true)
        this.dialogRef.close(data.block)
      },
      error => {
        Swal.close()
        showMessage('¡Error!', error.message, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
