import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/website/home/home.component';
import { LoginAdminComponent } from './components/login/login-admin/login-admin.component';
import { LoginSupportComponent } from './components/login/login-support/login-support.component';
import { SupportComponent } from './components/support/support.component';
import { BuildingComponent } from './components/support/building/building.component';
import { BuildingDetailComponent } from './components/support/building/building/building-detail/building-detail.component';

import { BuildingAdminComponent } from './components/support/building/building-admin/building-admin.component';
import { DoormanComponent } from './components/support/building/doorman/doorman.component';
import { ApartmentDetailComponent } from './components/shared/apartment/apartment-detail/apartment-detail.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { AdminApartmentComponent } from './components/admin/admin-apartment/admin-aparment.component';
import { AdminDetailComponent } from './components/admin/admin-apartment/admin-detail/admin-detail.component';
import { PaymentComponent } from './components/admin/payment/payment.component';
import { SubscriptionComponent } from './components/support/subscription/subscription.component';
import { SupportDashboardComponent } from './components/support/support-dashboard/support-dashboard.component';

import { AuthSupportGuard } from './core/guards/auth-support.guard'
import { AuthAdminGuard } from './core/guards/auth-admin.guard'

import { AdminPlaceComponent } from './components/admin/place/admin-place.component'
import { MessageAdminComponent } from './components/admin/message-admin/message-admin.component'
import { RequestComponent } from './components/admin/request/request.component'
import { ActiveComponent } from './components/user/active/active.component'
import { AdminDoormanComponent } from './components/admin/admin-doorman/admin-doorman.component'
import { BookingComponent } from './components/admin/place/booking/booking.component'
import { LogbookComponent } from './components/admin/logbook/logbook.component'
import { LegalComponent } from './components/website/legal/legal.component'
import { DownloadComponent } from './components/website/download/download.component'
import { LicenseComponent } from './components/website/license/license.component'
import { ServiceCompanyComponent } from './components/support/service-company/service-company.component';
import { DetailServiceCompanyComponent } from './components/support/service-company/detail-service-company/detail-service-company.component';


import { WebsiteBlogComponent } from './components/website/website-blog/website-blog.component';
import { BlogComponent } from './components/website/blog/blog.component';
import { BlogDetailComponent } from './components/website/blog/blog-detail/blog-detail.component';
import { AboutComponent } from './components/website/about/about.component';
import { WebsiteContactComponent } from './components/website/website-contact/website-contact.component';

const routes: Routes = [

  // Website
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: WebsiteContactComponent },
  { path: 'legal', component: LicenseComponent },
  { path: 'license', component: LicenseComponent },
  { path: 'download', component: DownloadComponent },
  { path: 'blog', component: WebsiteBlogComponent },
  { path: 'user/active/:iv/:token', component: ActiveComponent },

  // Support
  { path: 'support', component: LoginSupportComponent },
  { path: 'support/home', component: SupportDashboardComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/building', component: BuildingComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/building/:id', component: BuildingDetailComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/apartment/:id', component: ApartmentDetailComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/servicecompany', component: ServiceCompanyComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/servicecompany/:id', component: DetailServiceCompanyComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/user', component: SupportComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/adminbuilding', component: BuildingAdminComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/doorman', component: DoormanComponent, canActivate: [AuthSupportGuard] },
  { path: 'support/subscription', component: SubscriptionComponent, canActivate: [AuthSupportGuard] },
  
 // Admin
  { path: 'admin/home', component: DashboardComponent, canActivate: [AuthAdminGuard] },
  { path: 'admin/admin-places', component: AdminPlaceComponent, canActivate: [AuthAdminGuard] },
  { path: 'admin/apartment/:id', component: ApartmentDetailComponent, canActivate: [AuthAdminGuard] },


  { path: 'support/admin-places', component: AdminPlaceComponent, canActivate: [AuthSupportGuard] },

  { path: 'admin', component: LoginAdminComponent, pathMatch: 'full' },
  { path: 'admin/auth/:iv/:token', component: LoginAdminComponent },
  { path: 'admin/apartment', component: AdminApartmentComponent, canActivate: [AuthAdminGuard] },
  
  { path: 'admin/d/doorman', component: AdminDoormanComponent, canActivate: [AuthAdminGuard] },
  { path: 'admin/admin-request', component: RequestComponent, canActivate: [AuthSupportGuard] },
  { path: 'admin/admin-logbook', component: LogbookComponent, canActivate: [AuthSupportGuard] },
  { path: 'admin/admin-news', component: MessageAdminComponent, canActivate: [AuthSupportGuard] },
  { path: 'admin/admin-places/booking/:id', component: BookingComponent, canActivate: [AuthAdminGuard] },


  
  { path: 'payment', component: PaymentComponent, canActivate: [AuthAdminGuard] },
  { path: 'admin/:id', component: AdminDetailComponent, canActivate: [AuthAdminGuard] },
  
  
  
  { path: 'support/blog', component: BlogComponent, canActivate: [AuthSupportGuard] },
  { path: 'blog/:slug', component: BlogDetailComponent },

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
