import { is } from 'date-fns/locale'
import { Apartment } from '../models/apartment'
import { Block } from '../models/block'
import { Booking } from '../models/booking'
import { Building } from '../models/building'
import { Contact } from '../models/contact'
import { Dependency } from '../models/dependency'
import { Doorman } from '../models/doorman'
import { Logbook } from '../models/logbook'
import { Message } from '../models/message'
import { Place } from '../models/place'
import { RequestMessage } from '../models/request.message'
import { ServiceCompany } from '../models/service.company'
import { ServiceRequest } from '../models/service.request'
import { User } from '../models/users'
import { Blog } from '../models/blog'

export class BlockResponse {

    public block: Block
    public message: string

    constructor(block: Block, message: string) {
        this.block = block
        this.message = message
    }

}

export class ApartmentResponse {

    public apartment: Apartment
    public message: string

    constructor(apartment: Apartment, message: string) {
        this.apartment = apartment
        this.message = message
    }

}

export class BuildingResponse {

    public building: Building
    public message: string

    constructor(building: Building, message: string) {
        this.building = building
        this.message = message
    }

}

export class UserResponse {

    public user: User
    public message: string

    constructor(user: User, message: string) {
        this.user = user
        this.message = message
    }

}

export class DoormanResponse {

    public doorman: Doorman
    public message: string

    constructor(doorman: Doorman, message: string) {
        this.doorman = doorman
        this.message = message
    }

}

export class PlaceResponse {

    public place: Place
    public message: string

    constructor(place: Place, message: string) {
        this.place = place
        this.message = message
    }

}

export class MessageResponse {

    public mmessage: Message
    public message: string
    public files: File[]

    constructor(mmessage: Message, message: string, files: File[] = null) {
        this.mmessage = mmessage
        this.message = message
        this.files = files
    }

}

export class ServiceRequestReponse {

    public requests: ServiceRequest[]
    public request: ServiceRequest
    public messages: RequestMessage[]

    public message: string
    public files: File[]

    constructor(message: string, data: any, messages: any[] = null) {
        if (Array.isArray(data)) {
            this.requests = data
        } else {
            this.request = data
        }
        this.messages = messages
        this.message = message
    }

}

export class BookingResponse {

    public booking: Booking
    public place: Place
    public message: string

    constructor(booking: Booking, place: Place, message: string) {
        this.place = place
        this.booking = booking
        this.message = message
    }

}

export class InfoResponse {

    public total_apartments: number
    public total_blocks: number
    public total_users: number
    public total_doormans: number

    public requests: Request[]
    public bookings: Booking[]
    public logbooks: Logbook[]

    constructor(apartments: number, blocks: number, users: number) {
        this.total_apartments = apartments
        this.total_blocks = blocks
        this.total_users = users

        this.total_doormans = 0
        this.requests = []
        this.bookings = []
        this.logbooks = []
    }

}

export class ServiceCompanyResponse {

    public serviceCompany: ServiceCompany
    public dependencies: Dependency[]
    public message: string

    constructor(serviceCompany: ServiceCompany, message: string, dependencies: Dependency[] = []) {
        this.serviceCompany = serviceCompany
        this.message = message
        this.dependencies = dependencies
    }

}

export class DependencyResponse {

    public dependency: Dependency
    public message: string

    constructor(dependency: Dependency, message: string) {
        this.dependency = dependency
        this.message = message
    }

}

export class ContactResponse {

    public contact: Contact
    public message: string

    constructor(contact: Contact, message: string) {
        this.contact = contact
        this.message = message
    }

}

export class BlogResponse {

    public blog: Blog
    public blogs: Blog[]
    public message: string

    constructor(data: any, message: string) {
        if (Array.isArray(data)) {
            this.blogs = data
        } else {
            this.blog = data
        }
        this.message = message
    }

}