import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { User } from './core/models/users';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'minuta-frontend';

  admin = false

  constructor(private router: Router, private activatedRoute: ActivatedRoute){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        const url = event.url
        this.admin = (url.startsWith('/admin') || url.startsWith('/support'))
        /*switch(url){
          case "/": case "/admin": case "/support":  {
            this.admin = false 
            break
          }
          default: {
            this.admin = true
          }
        }*/

      }
    })
  }

  ngOnInit(){

  }

}
