import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatPaginator} from '@angular/material/paginator'
import { MatTableDataSource} from '@angular/material/table'
import { Admin} from '../../../../core/models/admin'
import Swal from 'sweetalert2'
import { AdminService} from '../../../../core/services/admin.service'
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CreateBuildingAdminComponent } from './create-building-admin/create-building-admin.component'
import { Building } from 'src/app/core/models/building';
import { EditBuildingAdminComponent } from './edit-building-admin/edit-building-admin.component';
import { DataType, showMessage } from 'src/app/core/util/common'

export interface AdminDialogData {
  id: number,
  building: Building,
  admin: Admin
}

@Component({
  selector: 'app-building-admin',
  templateUrl: './building-admin.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BuildingAdminComponent implements OnInit {

  @Input() building: Building

  displayedColumns: string[] = ['id', 'name', 'email', 'state', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private adminService: AdminService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createAdmin() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(CreateBuildingAdminComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        data.push(result)
        this.dataSource.data = data
      }
    })
  }

  editAdmin(admin: Admin) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id, admin: admin}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(EditBuildingAdminComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        let index = data.findIndex((item: Admin) => item.id == result.id)
        data[index] = result
        this.dataSource.data = data
      }
    })
  }

  ngOnChanges() {
    this.dataSource.data = this.building.admins
  }

  sendActivation(admin) {
    Swal.showLoading()
    this.adminService.sendActivation(admin, this.building.id).subscribe(
      data => {
        Swal.close()
        showMessage('¡Completo!', data.message, true)
      }, error => {
        showMessage("Error", error, false)
      })
  }

}
