import {Injectable, EventEmitter} from '@angular/core';
import {City} from '../models/city';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AppSettings} from '../appsettings';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  userSelected = new EventEmitter<City>();


  httpOptions: {};
  /**
   * constructor
   * @param http cliente de manejo de peticiones de http.
   * @param auth referencia a la clase AuthService.
   * @param router librería de angular para manejar rutas.
   */
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '');
    });
    this.updateToken(localStorage.getItem('token'));
  }
  
  /**
   * updateToken
   * @param token llave que hace referencia al tipo de sesión activa.
   */
  updateToken (token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    };
  }

  /**
   * getCity
   */
  getCities(): Observable<City[]> {
    return this.http.get<City[]>(
      AppSettings.url + '/building/city', this.httpOptions
    ).pipe(
      map(data => data.map(data => new City().deserialize(data)))
    );
  }

  /**
   * getCityID
   * @param id hace referencia a la ciudad.
   */
  getCityID(id: number): Observable<City> {
    return this.http.get<City>(
      `${AppSettings.url}/building/city/${id}`, this.httpOptions
    ).
    pipe(
      map(resp => {
        // let support = new Support().deserialize(resp);
        return resp;
      })
    );
  }

}
