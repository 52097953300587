import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Block } from '../models/block'
import { GlobalErrorHandler } from '../util/common'
import { BlockResponse } from '../reponse/data.response'

@Injectable({
  providedIn: 'root'
})
export class BlockService {

  userSelected = new EventEmitter<Block>()
  errorHandler = new GlobalErrorHandler()
  url = ""
  httpOptions: {}

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getBlockID(id: number): Observable<any> {
    return this.http.get<any>(
      `${this.url}building/block/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createBlock(form): Observable<BlockResponse> {
    const data = {
      name: form.name,
      building_id: form.building_id,
    }
    return this.http.post<Block>(
      `${this.url}building/block`, data, this.httpOptions
    ).
    pipe(
      map(data => {
        var block = new Block().deserialize(data["block"])
        var message = data["message"]
        return new BlockResponse(block, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateBlock(form, id: number): Observable<BlockResponse> {
    const data = {
      name: form.name,
      building_id: form.building_id,
    };
    return this.http.put<Block>(
      `${this.url}building/block/${id}`, data, this.httpOptions
    ).
    pipe(
      map(data => {
        var block = new Block().deserialize(data["block"])
        var message = data["message"]
        return new BlockResponse(block, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
