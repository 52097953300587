import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { AuthService } from 'src/app/core/services/auth.service'
import { Router } from '@angular/router'
import { Support } from 'src/app/core/models/support.modules'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-login-support',
  templateUrl: './login-support.component.html'
})
export class LoginSupportComponent implements OnInit {

  support: Support = new Support()
  formGroup: UntypedFormGroup
  resetFormGroup: UntypedFormGroup
  remember = false
  showPreview = false

  constructor(private fb: UntypedFormBuilder, private auth: AuthService, private router: Router) {
    var session = this.auth.readToken()
    if (session) {
      this.router.navigateByUrl('/support/home')
    }
    this.createForm()
  }

  ngOnInit(): void {
    if (localStorage.getItem('email')) {
      this.support.email = localStorage.getItem('email')
      this.remember = true
    }
  }

  createForm() {
    this.formGroup = this.fb.group({
      'email':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(3), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'password': [null, [Validators.required]]
    })
    this.resetFormGroup = this.fb.group({
      'email':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(3), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    })
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get resetMail() {
    return this.resetFormGroup.get('email') as UntypedFormControl
  }

  get password() {
    return this.formGroup.get('password') as UntypedFormControl
  }

  loginSupport(form) {
    Swal.showLoading()
    this.auth.loginSupport(form).subscribe(resp => {
      localStorage.setItem('token', resp.token)
      Swal.close()
      if (this.remember) {
        localStorage.setItem('email', this.support.email)
      }
      this.router.navigateByUrl('/support/home')
    }, error => {
      showMessage('¡Error!', error, false)
    })
  }

  resetPassword(form: NgForm) {
    const temporal = form.value
    Swal.showLoading()
    this.auth.resetPassword(temporal)
      .subscribe(resp => {
          if (resp) {
            Swal.close()
            showMessage(resp, '', true)
          }
        },
        error => {
          showMessage('Error', error, false);
        });
  }

  showPreviewReset() {

    this.showPreview = true;
  }

  canceled() {
    this.showPreview = false;
  }

}
