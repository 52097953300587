import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SubscriptionService} from '../../../core/services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html'
})
export class SubscriptionComponent implements OnInit {

  displayedColumns: string[] = ['id', 'building', 'date_start', 'date_end', 'plan', 'status', 'actions'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  getStatus(status): string {
    if (status) {
      return 'activo';
    } else {
      return 'inactivo';
    }
  }
  constructor(private subscriptionservice: SubscriptionService) {
  }

  ngOnInit(): void {
    this.subscriptionservice.getSubscription().subscribe(
      data => {
        this.dataSource.data = data;
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



}
