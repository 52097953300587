<app-website-header></app-website-header>

<div class="">
    <header>
        <div class="overlay"></div>
        <div class="container header-message animate__animated animate__backInUp">
            <div class="row">
                <div class="col-md-6 message">
                    <div class="">
                        <h1>DIGITALIZA TU PROPIEDAD HORIZONTAL</h1> 
                        <p>BIRU llega para hacer tu trabajo más sencillo. Ahora se podrá registrar ágilmente todo lo que llegue a la recepción del edificio, así como también se resolverán las dudas entre administración e inquilinos de una forma rápida y fácil. </p>
                        <a class="btn" href="about.html">Contáctanos</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="assets/img/phones2.png" alt="">
                </div>
            </div>     
        </div>
    </header>
    
    <section id="intro-message">
        <div class="container text-center pt-5 pb-5">
           <h2>TODO TU DÍA EN UNA APP</h2> 
           <p>Con BIRU podrás revisar que paquetes recibes, programar las visitas de tus familiares, <br>
             comunicarte con la administración y estar al tanto de lo que sucede en tu copropiedad, todo en una sola APP. </p>
        </div>          
    </section>
    
    <section id="info">
       <article class="media-block container">
           <div class="media-block-image">
               <img src="assets/website/messages_phone.png" />
               <img src="assets/website/request_phone.png" />
           </div>
           <div class="media-block-content animate__animated animate__fadeInUp">
                <span>01</span>
                <h2>MENSAJERÍA Y<br>SOLICITUDES</h2>
                <p>No te pierdas lo que pasa en tu comunidad, Con este módulo el administrador te mantendrá informado de lo que pasa en la copropiedad, allí también podrás comunicarte con el administrador para cualquier solicitud, con la portería o con cualquier miembro de la copropiedad ya seas el arrendador o el arrendatario.</p>
           </div>
       </article>
    </section>
    
    <section id="info-2">
       <article class="media-block container">
            <div class="media-block-content">
                <span>02</span>
                <h2>REGISTRO DE<br/>
                    PAQUETES, DOMICILIOS Y RECIBOS</h2>
                <p>Con BIRU podrás registrar tus paquetes, domicilios y recibos a cualquier hora del día para que nunca pierdas su rastro.</p>
            </div>
            <div class="media-block-image">
                <img src="assets/website/phones.png" />
            </div>
       </article>
    </section>
    
    <section id="info-3">
        <article class="media-block container">
            <div class="media-block-image">
                <img src="assets/website/visit_phone.png" />
                <img src="assets/website/directory_phone.png" />                
            </div>
            <div class="media-block-content">
                <span>03</span>
                <h2>VISITAS Y<br/>DIRECTORIO</h2>
                <p>Con BIRU podrás registrar tus visitas y programar su ingreso y podrás consultar el directorio local cuando necesites ubicarte, sin importar donde te encuentres. </p>
            </div>
        </article>
    </section>

    <section id="info-4">
        <article class="media-block container">
             <div class="media-block-content">
                 <span>04</span>
                 <h2>RESERVA TUS ESPACIOS</h2>
                 <p>Con BIRU podrás separar y alquilar tus espacios favoritos. Ya sea el BBQ, la terraza o los salones comunales para tus fiestas y eventos.</p>
             </div>
             <div class="media-block-image">
                 <img src="assets/website/booking_phone.png" />
             </div>
        </article>
     </section>

    <section id="download" class="padding-100">
        <div class="overlay"></div>
        <div class="container text-center">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <h2>¡Descarga Ya!, disponible en tiendas.</h2>
                    <p>Ya puedes descargar Biru.</p>
                    <div class="space-50"></div>
                    <div class="row">
                        <div class="col-md-4 offset-md-2 text-center aos-init aos-animate" data-aos="fade-right" data-aos-delay="300">
                            <a class="btn" href="https://apps.apple.com/us/app/biru/id1558359322" role="button">
                                <mat-icon aria-hidden="false">apple</mat-icon>
                                <span>App Store</span></a>
                        </div>
                        <div class="col-md-4 text-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <a class="btn" href="https://play.google.com/store/apps/details?id=com.solutechholding.biru" role="button">
                                <mat-icon aria-hidden="false">android</mat-icon>
                                <span>Google Play</span>
                            </a>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </section>
    
    <section id="contact">
        <div class="overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 section-heade white text-center">
                    <h2>CONTACTANOS</h2>
                    <div class="space-25"></div>
                    <p>Envianos un mensaje y pronto nuestro equipo de ventas se pondrá en contacto.</p>
                    <div class="space-50"></div>
                </div>
                <div class="col-md-8">
                    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" formControlName="name" type="text" placeholder="Nombre" required="">
                                    <div *ngIf="!name.valid && name.touched">
                                        <mat-error *ngIf="name.errors.required">No olvide ingresar su nombre.</mat-error>
                                        <mat-error *ngIf="name.errors.maxlength">El nombre supera el total de caracteres permitidos.</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email" required="">
                                    <div *ngIf="!email.valid && email.touched">
                                        <mat-error *ngIf="email.errors.required">Debe ingresar un email válido.</mat-error>
                                        <mat-error *ngIf="email.errors.email">Debe ingresar un email válido.</mat-error>
                                        <mat-error *ngIf="email.errors.maxlength">El email supera el total de caracteres permitidos.</mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="building" placeholder="Nombre de la propiedad horizontal" required="">
                                    <div *ngIf="!building.valid && building.touched">
                                        <mat-error *ngIf="building.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <textarea class="form-control" rows="6" placeholder="Mensaje" formControlName="message" required=""></textarea>
                            <div *ngIf="!message.valid && message.touched">
                                <mat-error *ngIf="name.errors.required">No olvide ingresar el mensaje.</mat-error>
                                <mat-error *ngIf="name.errors.maxlength">El mensaje supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-default center-block" [disabled]="formGroup.invalid">Enviar</button>
                    </form>
                </div>
                <div class="col-md-4">
                    <ul class="list-unstyled contact-info">
                        <li>
                            <div class="icon"><mat-icon aria-hidden="false">smartphone</mat-icon></div>
                            <div class="text">+57 3194056655</div>
                        </li>
                        <li>
                            <div class="icon"><mat-icon aria-hidden="false">email</mat-icon></div>
                            <div class="text"><a href="mailto:ventas@biru.com.co">ventas@biru.com.co</a></div>
                        </li>
                        <li>
                            <div class="calendly-widget" data-auto-load="false"></div>
                        </li>
                    </ul>
                    <ul class="list-inline social-icons">
                        <li class="facebook"><a href="#" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li class="twitter"><a href="#" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li class="google-plus"><a href="#" target="_blank"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                        <li class="linkedin"><a href="#" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        <li class="pinterest"><a href="#" target="_blank"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    
    <app-website-footer></app-website-footer>

</div>
