<p>{{ deviceInfo.os }}</p>

<div class="container" *ngIf="isDesktopDevice">
    <div class="row">
        <div class="col-md-12 downloads">
            <h2>Descarga Biru</h2>
            <a href='https://play.google.com/store/apps/details?id=com.solutechholding.biru&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png' style="border-radius: 13px; width: auto; height: 110px;"></a>
            <a href="https://apps.apple.com/us/app/biru/id1558359322?itsct=apps_box_badge&amp;itscg=30200" style="overflow: hidden; border-radius: 13px; width: 250px; height: 83px; margin: 15px;"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx?size=250x83&amp;releaseDate=1241395200&h=96f84fc19532ee4e568ddadbd1d3d0f9" alt="Download on the App Store" style="border-radius: 13px; width: 250px; height: 83px;"></a>
        </div>
    </div>
</div>