import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MessageService } from 'src/app/core/services/message.service'
import { CreateMessageComponent } from './create-message/create-message.component'
import { Message } from 'src/app/core/models/message'
import Swal from 'sweetalert2'
import { showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-message-admin',
  templateUrl: './message-admin.component.html'
})
export class MessageAdminComponent implements OnInit {

  messages: Message[]
  message: Message
  files: File[]

  constructor(private messageService: MessageService, public dialog: MatDialog) { 
    this.messageService.getMessages().subscribe(data => {
      this.messages = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
    this.message = new Message()
    this.message.id = 0
    this.messages = []
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase()
    //this.messages.filter = filterValue.trim().toLowerCase()
  }

  getMessage(message){
    this.message = new Message()
    this.messageService.getMessage(message.id).subscribe(data => {
      this.message = data.mmessage
      this.files = data.files
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  createMessage() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {}
    dialogConfig.width = '80%'
    const dialogRef = this.dialog.open(CreateMessageComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.messages.unshift(result)
      }
    })
  }

}
