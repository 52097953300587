import { Component, OnInit } from '@angular/core'
import { Lightbox } from 'ngx-lightbox'
import { Logbook } from 'src/app/core/models/logbook'
import { BuildingService } from 'src/app/core/services/building.service'

@Component({
  selector: 'app-logbook',
  templateUrl: './logbook.component.html'
})
export class LogbookComponent implements OnInit {

  logbooks: Logbook[] = []
  filteredLogbooks: Logbook[] = []

  constructor(private buildingService: BuildingService, private lightbox: Lightbox) { 
    this.loadData()
  }

  ngOnInit(): void {

  }

  loadData() {
    this.buildingService.getLogbook().subscribe(data => {
      this.logbooks = data
      this.filteredLogbooks = data
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase()
    this.filteredLogbooks = this.logbooks.filter(
      (data) =>  JSON.stringify(data).toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
    )
  }

  openImage(file) {
    const image = {
      src: file,
      caption: "Biru",
      thumb: file
    }
    this.lightbox.open([image], 0)
  }

}
