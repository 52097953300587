import {Injectable, Output, EventEmitter} from '@angular/core'
import {Session, SessionType} from '../models/session.modules'
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import {Admin} from '../models/admin'
import {AppSettings} from '../appsettings'
import {Observable, throwError} from 'rxjs'
import {catchError, map, retry} from 'rxjs/operators'
import {Support} from '../models/support.modules'
import {Router} from '@angular/router'
import { Building } from '../models/building'
import { GlobalErrorHandler } from '../util/common'

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  @Output() loginEvent: EventEmitter<Session> = new EventEmitter()

  supportToken: string
  adminToken: string
  session: Session

  httpOptions: {}
  errorHandler = new GlobalErrorHandler()

  constructor(private http: HttpClient, private router: Router) {
    this.loginEvent.subscribe(session => {
      if(session != null) {
        this.updateToken(session ? session.token : '', session.session)
      }
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  logout() {
    localStorage.removeItem('session');
    localStorage.removeItem('token');
    this.loginEvent.emit(null);
  }

  loginAdminToken(iv: string, token: string): Observable<Session> {
    const authData = {
      iv: iv,
      token: token
    };
    return this.http.post<Session>(
      `${AppSettings.url}/admin/authentication/token`,
      authData,
      {observe: 'response'}
    ).pipe(
      map(resp => {
        if (resp) {
          if (resp.status === 200) {
            this.session = new Session()
            this.session.admin = new Admin().deserialize(resp.body.admin)
            this.session.token = resp.body.token
            this.session.building = new Building().deserialize(resp.body.building)
            this.session.type = SessionType.Admin
            this.session.session = "/admin/"
            this.loginEvent.emit(this.session)
            this.saveSession()
            return this.session
          }
        }
        return null
      })
    );
  }

  loginAdmin(form): Observable<Session> {
    const data = {
      email: form.email.toLowerCase(),
      building_id: form.building_id,
      password: form.password
    }
    return this.http.post<Session>(
      `${AppSettings.url}/admin/authentication`, data, {observe: 'response'}
    ).pipe(
      map(resp => {
        this.session = new Session()
        this.session.admin = new Admin().deserialize(resp.body.admin)
        this.session.token = resp.body.token
        this.session.building = new Building().deserialize(resp.body.building)
        this.session.type = SessionType.Admin
        this.session.session = "/admin/"
        this.loginEvent.emit(this.session)
        this.saveSession()
        return this.session
      }), 
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  loginSupport(form): Observable<Session> {
    const data = {
      email: form.email,
      password: form.password
    }
    return this.http.post<Session>(
    `${AppSettings.url}/support/authentication`, data, {observe: 'response'}
    ).pipe(
      map(resp => {
        if (resp) {
          if (resp.status == 200) {
            localStorage.setItem('token', resp.body.token)
            this.session = new Session()
            this.session.support = resp.body.support
            this.session.token = resp.body.token
            this.session.type = SessionType.Support
            this.session.session = "/support/"
            this.loginEvent.emit(this.session)
            this.saveSession()
            return this.session
          }
        }
        return null
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  resetPassword(form): Observable<any> {
    return this.http.post<any>(
      // Se coloca el api que se utlice para restablecer la contraseña
      `${AppSettings.url}/support/resetpassword`, form
    ).pipe(
      map(resp => {
        return resp['message'];
      })
    );
  }

  updatePassword(form): Observable<string> {
    const data = {
      password: form.password
    };
    return this.http.post<string>(
      `${AppSettings.url}/admin/ft/password`, data, this.httpOptions
    ).pipe(
      map(resp => {
        console.log(resp)
        return resp['message']
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  resetPasswordAdmin(form): Observable<any> {

    return this.http.post<any>(
      // Se coloca el api que se utlice para restablecer la contraseña
      `${AppSettings.url}/admin/resetpassword`, form
    ).pipe(
      map(resp => {
        return resp['message'];
      })
    );
  }

  enableAdmin() {
    var sessionJson = JSON.parse(localStorage.getItem('session'))
      //var admin = new Admin().deserialize(value.admin)
      console.log(sessionJson)
      var session = new Session().deserialize(sessionJson)
      console.log(session)
      session.admin.accepted = true
      this.session = session
      console.log(session)
      localStorage.setItem('session', JSON.stringify(this.session))
      this.loginEvent.emit(session)
  }

  private saveSession() {
    localStorage.setItem('session', JSON.stringify(this.session))
    localStorage.setItem('token', this.session.token)
    localStorage.setItem('type', JSON.stringify(this.session.session))
  }

  readToken() {
    if (localStorage.getItem('session')) {
      this.session = JSON.parse(localStorage.getItem('session'), (key, value) => {
        return value
      })
    } else {
      this.session = null
    }
    return this.session
  }

  isAuthenticated(): boolean {
    if(this.session == null){
      this.readToken()
    }
    if (!this.session) {
      return false;
    }
    return true;
  }

}
