import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Contact } from 'src/app/core/models/contact'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html'
})
export class EditContactComponent implements OnInit {

  formGroup: UntypedFormGroup
  contact: Contact

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<EditContactComponent>) { 
    this.contact = data.contact
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name': [this.contact.name, [Validators.required, Validators.maxLength(60), Validators.minLength(4)]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.contactService.updateContact(form, this.contact.id).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
        this.dialogRef.close(data.contact)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}