import {Desearizable} from './desearizable';

export class Package implements Desearizable {

  public id: number
  public delivered_company_id: number
  public apartment_id: number 
  public building_id: number 
  public doorman_id: number
  public note: string
  public created_at: string
  public updated_at: string
  public delivered_at: string
  public user_id: number
  public delivered: boolean
  public delivered_note: string
  public image: string
  public doorman_delivered_id: number
  
  public delivery_company: string
  public doorman: string
  public doorman_delivered: string


  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

  getStatus() {
    return this.delivered ? "Entregado" : "En porteria"
  }

}
