import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Place } from 'src/app/core/models/place'
import { PlaceService } from 'src/app/core/services/place.service'
import { showMessage } from 'src/app/core/util/common'
import { CreatePlaceComponent } from '../../shared/place/create-place/create-place.component'
import { EditPlaceComponent } from '../../shared/place/edit-place/edit-place.component'

@Component({
  selector: 'app-admin-place',
  templateUrl: './admin-place.component.html'
})
export class AdminPlaceComponent implements OnInit {

  displayedColumns: string[] = ['name', 'price_type', 'price', 'image', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private placeService: PlaceService, public dialog: MatDialog) {
    this.placeService.getPlaces().subscribe(data => {
      this.dataSource.data = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  createPlace(){
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: 0}
    dialogConfig.width = '650px'
    const dialogRef = this.dialog.open(CreatePlaceComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        data.push(result)
        this.dataSource.data = data
      }
    })
  }

  editPlace(place: Place){
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {place: place}
    dialogConfig.width = '650px'
    const dialogRef = this.dialog.open(EditPlaceComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.dataSource.data
        const index = data.findIndex((p: Place) => p.id == result.id)
        data[index] = result
        this.dataSource.data = data
      }
    })
  }

}
