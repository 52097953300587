<div class="mt-30" id="sidebar-wrapper" *ngIf="type == sessionType.Support && isWebsite == false"> 
    <div class="list-group list-group-flush">
        <a [routerLink]="['admin/home']" class="logo">
            <img src="assets/img/logo.png" alt="biru"/>
            <h1>Biru</h1>
        </a>
        <a [routerLink]="['support/user']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">supervisor_account</mat-icon>
            <span>Usuarios</span>
        </a>
        <a [routerLink]="['support/building']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">business</mat-icon>
            <span>Edificios</span>
        </a>
        <a [routerLink]="['support/servicecompany']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">contacts</mat-icon>
            <span>Contactos</span>
        </a>
        <a [routerLink]="['support/blog']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">book</mat-icon>
            <span>Blog</span>
        </a>
        <a class="list-group-item list-group-item-action exit" (click)="leave()">
            <mat-icon aria-hidden="false">exit_to_app</mat-icon>
            <span>Cerrar sesión</span>
        </a>
    </div>
</div>

<div  class="mt-30" id="sidebar-wrapper" *ngIf="type == sessionType.Admin">
    <div class="list-group list-group-flush">
        <a [routerLink]="['admin/home']" class="logo">
            <img src="assets/img/logo.png" alt="biru"/>
            <h1>Biru</h1>
        </a>
        <a [routerLink]="['admin','apartment']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">apartment</mat-icon>
            <span>Apartamentos</span>
        </a>
        <a [routerLink]="['admin','d','doorman']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">account_box</mat-icon>
            <span>Porteros</span>
        </a>
        <a [routerLink]="['admin','admin-places']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">sports_tennis</mat-icon>
            <span>A. Comunes</span>
        </a>
        <a [routerLink]="['admin','admin-news']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">mail_outline</mat-icon>
            <span>Mensajes</span>
        </a>
        <a [routerLink]="['admin','admin-request']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">chat</mat-icon>
            <span>Solicitudes</span>
        </a>
        <a [routerLink]="['admin','admin-logbook']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">auto_stories</mat-icon>
            <span>Bitácora</span>
        </a>
        <!--a [routerLink]="['admin-request']" [routerLinkActive]="['active']" class="list-group-item list-group-item-action">
            <mat-icon aria-hidden="false">attach_money</mat-icon>
            <span>Pagos</span>
        </a-->
        <a class="list-group-item list-group-item-action" (click)="leave()">
            <mat-icon aria-hidden="false">exit_to_app</mat-icon>
            <span>Cerrar sesión</span>
        </a>
    </div>
</div>


