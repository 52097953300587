import { Contact } from './contact'
import { Desearizable } from './desearizable'

export class Dependency implements Desearizable {

    public id: number = -1
    public name: string
    public service_company_id: number
    public company: string
    public note: string

    public contacts: Contact[]

    deserialize(input: any): this {
      Object.assign(this, input)
      return this
    }

}