<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <h1>Usuarios</h1>
            <!--div class="options">
                <mat-form-field class="angularformcss no-line " appearance="none" floatLabel="never">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" autocomplete="off">
                </mat-form-field>
                <button type="button" class="btn" data-toggle="modal" data-target="#modalForm" (click)="newUser()">Nuevo +</button>
            </div-->
        </div>

        <!--div class="card-body">
            <mat-spinner *ngIf="dataSource.data.length == 0"></mat-spinner>
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>Apellido</th>
                    <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Correo</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Rol</th>
                    <td mat-cell *matCellDef="let element">{{ element.getType() }}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let element">{{ element.getStatus() }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let support">
                        <button class="btn btn-link" type="button" data-toggle="modal" data-target="#modalForm" (click)="editUser(support)"> <mat-icon aria-hidden="false">create</mat-icon> </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div-->
    </div>
</div>

<div class="modal fade" id="modalForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <!--div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="exampleModalLabel">{{title}}</h2>
              <button type="button" #closeModal class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="container">
                  <app-edit-user (supportEvent)="reloadData()" [support]="support" *ngIf="modalForm == allModalForm.editUser"></app-edit-user>
                  <app-create-user (supportEvent)="reloadData()" [support]="support" *ngIf="modalForm == allModalForm.createUser" ></app-create-user>
                </div>
            </div>
        </div>
    </div-->
  </div>
