import { Desearizable } from './desearizable'

export class Delivery implements Desearizable {

  public id: number
  public delivery_company: string
  public name: string
  public note: string

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }

}