<div class="container modal-ag">
    <h2>Crear Portero</h2>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="first_name">
            <div *ngIf="!firstName.valid && firstName.touched">
                <mat-error *ngIf="firstName.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="firstName.errors.minlength">El nombre ingresado debe contener al menos 3 caracteres.</mat-error>
                <mat-error *ngIf="firstName.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="last_name">
            <div *ngIf="!lastName.valid && lastName.touched">
                <mat-error *ngIf="lastName.errors.required">Debe ingresar un apellido válido.</mat-error>
                <mat-error *ngIf="lastName.errors.minlength">El apellido ingresado debe contener al menos 4 caracteres.</mat-error>
                <mat-error *ngIf="lastName.errors.maxlength">El apellido ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label></mat-label>
            <ngx-mat-intl-tel-input
                [preferredCountries]="['co']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                describedBy="phoneInput"
                formControlName="cellphone">
            </ngx-mat-intl-tel-input>
            <div *ngIf="!cellphone.valid && cellphone.touched">
                <mat-error *ngIf="cellphone.errors.required">Debe ingresar un número de celular válido.</mat-error>
                <mat-error *ngIf="cellphone.errors.minlength">El número de celular ingresado debe contener al menos 10 caracteres.</mat-error>
                <mat-error *ngIf="cellphone.errors.maxlength">El número de celular ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Documento</mat-label>
            <input matInput formControlName="document_id">
            <div *ngIf="!documentId.valid && documentId.touched">
                <mat-error *ngIf="documentId.errors.required">Debe ingresar un número de documento válido.</mat-error>
                <mat-error *ngIf="documentId.errors.minlength">El número de documento ingresado debe contener al menos 5 caracteres.</mat-error>
                <mat-error *ngIf="documentId.errors.maxlength">El número de documento ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Crear</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()" >Cancelar</button>
        </div>

    </form>
</div>
