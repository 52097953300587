import { Desearizable } from './desearizable'

export class File implements Desearizable {

    public id: number
    public name: string
    public file: string

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    checkImage(): boolean {
        let re = /(?:\.([^.]+))?$/
        let ext = re.exec(this.file).toString().slice(1,4)
        if(ext == "png" || ext == "jpg"){
            return true
        }
        return false
    }

    checkFile(): boolean {
        let re = /(?:\.([^.]+))?$/
        let ext = re.exec(this.file).toString().slice(1,4)
        if(ext == "png" || ext == "jpg"){
            return false
        }
        return true
    }

    getFileIcon(): string {
        let re = /(?:\.([^.]+))?$/
        let ext = re.exec(this.file).toString().slice(1,4)
        if(ext == "doc" || ext == "docx"){
            return "assets/icon/word.png"
        }
        if(ext == "xlsx"){
            return "assets/icon/excel.png"
        }
        if(ext == "pdf"){
            return "assets/icon/pdf.png"
        }
        return "assets/icon/file.png"
    }

}
