<div class="container">

    <!--div class="card active-form" *ngIf="showForm">
        <h2>Asignar Contraseña</h2>
        <p>Por tu seguridad debes crear una contraseña, ten presente que tendrás que usarla para acceder a tu cuenta desde la app de Biru.</p>
        <form [formGroup]="passwordFormGroup" (ngSubmit)="onSubmit(passwordFormGroup.value)">

            <mat-form-field class="form-group" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" formControlName="password" validateOnBlur [validateFormControl]="passwordFormGroup.controls['password']">
                <div *ngIf="password.touched && password.invalid">
                    <mat-error *ngIf="password.errors.required">Debe ingresar una contraseña.</mat-error>
                    <mat-error *ngIf="password.errors.passwordFormat">La contraseña debe tener al menos 6 caracteres, una mayúscula, una minúscula y un digito.</mat-error>
                </div>
            </mat-form-field>

            <mat-form-field class="form-group" appearance="outline">
                <mat-label>Confirmar Contraseña</mat-label>
                <input matInput type="password" formControlName="confirmpassword" validateOnBlur [validateFormControl]="passwordFormGroup.controls['confirmpassword']">
                <div *ngIf="confirmPassword.touched && confirmPassword.invalid">
                    <mat-error *ngIf="confirmPassword.errors.required">Debe ingresar una contraseña.</mat-error>
                    <mat-error *ngIf="confirmPassword.errors.confirmedValidator">Las contraseñas no coinciden.</mat-error>
                </div>
            </mat-form-field>

            <div class="form-group text-right">
                <button mat-button type="submit" class="btn btn-ok d-block w-100" [disabled]="passwordFormGroup.invalid">Guardar</button>
            </div>

        </form>
    </div-->

    <div class="card welcome" *ngIf="accountCreated == true">
        <h2>¡Bienvenido a Biru!</h2>
        <p>Tu cuenta está lista, descarga nuestra app gratuita disponible en el App Store y Google Play</p>
        <a href="https://apps.apple.com/us/app/biru/id1558359322?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-top-left-radius: 13px; border-top-right-radius: 13px; border-bottom-right-radius: 13px; border-bottom-left-radius: 13px; width: 250px; height: 83px;"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx?size=250x83&amp;releaseDate=1241395200&h=96f84fc19532ee4e568ddadbd1d3d0f9" alt="Download on the App Store" style="border-top-left-radius: 13px; border-top-right-radius: 13px; border-bottom-right-radius: 13px; border-bottom-left-radius: 13px; width: 250px; height: 83px;"></a>
        <a href='https://play.google.com/store/apps/details?id=com.solutechholding.biru&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png'/></a>
    </div>

</div>