<div class="container modal-ag modal-place">
    <h2>Crear Área Común</h2>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <div class="row">
                    <div class="col-md-4">
                        <div class="place-image" [style.background-image]="imagePath == '' ? 'url(assets/img/placeholder.jpg)' : 'url(' + imagePath + ')'">
                            <div class="inner">
                                <button mat-raised-button type="button" class="btn" (click)="addFile()">
                                    <span class="material-icons">photo_camera</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="form-group" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="name" autocomplete="off">
                            <div *ngIf="!name.valid && name.touched">
                                <mat-error *ngIf="name.errors.required">Debe ingresar un nombre válido.</mat-error>
                                <mat-error *ngIf="name.errors.maxlength">El nombre supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </mat-form-field>
        
                        <mat-form-field class="form-group" appearance="outline">
                            <mat-label>Descripción</mat-label>
                            <textarea matInput formControlName="description" rows="3" autocomplete="off">
                            </textarea>
                            <div *ngIf="!description.valid && description.touched">
                                <mat-error *ngIf="!description.errors.maxlength">La descripción supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </mat-form-field>

                    </div>
                </div>

                <input type="file" #fileInput id="uploadFile" (change)="onFileChange($event)" name="uploadFile" multiple="multiple" accept="image/jpeg,image/jpg,image/png" class="d-none" />

                <div class="form-group text-right">
                    <button mat-raised-button type="button" class="btn btn-ok" [disabled]="name.invalid" matStepperNext>Siguiente</button>
                </div>
        
            </mat-step>
            <mat-step>
    
                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Tipo de Reserva</mat-label>
                    <mat-select formControlName="price_type" >
                        <mat-option *ngFor="let type of priceTypes" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="!price.valid && price.touched">
                        <mat-error *ngIf="price.errors.pattern">Debe ingresar una tarifa válido.</mat-error>
                    </div>
                </mat-form-field>
        
                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Precio</mat-label>
                    <input matInput currencyMask formControlName="price" [options]="{ prefix: '$ ', thousands: '.', decimal: ',' }"  autocomplete="off">
                    <div *ngIf="!price.valid && price.touched">
                        <mat-error *ngIf="price.errors.pattern">Debe ingresar una tarifa válido.</mat-error>
                        <mat-error *ngIf="price.errors.maxlength">El monto ingresado supera el total de caracteres permitidos.</mat-error>
                    </div>
                </mat-form-field>
        
                <mat-form-field class="form-group" appearance="outline">
                    <mat-label>Tiempo minimo para cancelar</mat-label>
                    <mat-select formControlName="cancellation_type" #cancellationType>
                        <mat-option *ngFor="let type of cancelType" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                    <!--div *ngIf="!cancellationType.valid && cancellationType.touched">
                        <mat-error *ngIf="cancellationType.errors.pattern">Debe seleccionar una opción.</mat-error>
                    </div-->
                </mat-form-field>

                <mat-form-field class="form-group" appearance="outline" *ngIf="cancellationType.value > 1">
                    <mat-label>Days/Horas minimas para cancelar</mat-label>
                    <input matInput formControlName="cancellation_minimum" autocomplete="off">
                    <!--div *ngIf="!cancellationMinimum.valid && cancellationMinimum.touched">
                        <mat-error *ngIf="cancellationMinimum.errors.pattern">Debe ingresar un tiempo válido.</mat-error>
                        <mat-error *ngIf="cancellationMinimum.errors.maxlength">El tiempo insgreado supera el total de caracteres permitidos.</mat-error>
                    </div-->
                </mat-form-field>

                <div class="form-group text-right">
                    <button mat-raised-button type="submit" class="btn btn-ok d-inline mr-2" [disabled]="formGroup.invalid">Guardar</button>
                    <button mat-raised-button type="button" class="btn btn-outline-dark" (click)="close()">Cancelar</button>
                </div>

            </mat-step>
        </mat-horizontal-stepper>

    </form>
</div>