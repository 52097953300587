import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html'
})
export class CreateRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
