<div class="container modal-ag">
    <h2>Crear Administrador</h2>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label></mat-label>
            <mat-select formControlName="title" >
                <mat-option *ngFor="let title of titles" [value]="title.id">
                {{ title.name }}
                </mat-option>
            </mat-select>
          </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="first_name">
            <div *ngIf="firstName.touched && firstName.invalid">
                <mat-error *ngIf="firstName.errors.required">Debe ingresar un nombre válido.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="last_name">
            <div *ngIf="lastName.touched && lastName.invalid">
                <mat-error *ngIf="lastName.errors.required">Debe ingresar un apellido válido.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group d-block" appearance="outline">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput placeholder="Email" formControlName="email" validateOnBlur [validateFormControl]="formGroup.controls['email']">
            <div *ngIf="email.touched && email.invalid">
                <mat-error *ngIf="email.errors.required || email.errors.email || email.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
            </div>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Crear</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form>
</div>