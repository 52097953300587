<app-website-header [isHome]="false"></app-website-header>

<div class="website container">
    <article>
        <h1>Términos y Condiciones</h1>

        <h2>Términos del servicio</h2>
<h3>Entre las Partes</h3>
<p>Los términos expresados en este contrato constituyen las condiciones de uso
    y privacidad para todos los servicios prestados en la actualidad y los
    añadidos en el futuro, Solutech Holding SAS en su sitio
    web www.solutechholding.com,subdominios y dominios que Solutech Holding
    SAS tenga dentro de su portafolio.
    El usuario acepta, al momento de empezar a utilizar el servicio, respetar
    todas las condiciones impuestas por este contrato.</p>
<h3>Términos y condiciones</h3>
<h3>Aceptación</h3>

<p>El usuario acepta por simple causal de uso del sistema de Solutech Holding
    SAS lo dispuesto en este contrato y en sus Términos y Condiciones,
    susceptible de cambio sin previo aviso por parte de Solutech Holding SAS. Si
    el usuario representa una organización, está dando por entendido que la
    organización acepta ceñirse a este contrato y que tiene las facultades para
    actuar en nombre de aquella y por lo tanto obligarla frente a Solutech Holding
    SAS y aceptar las obligaciones establecidas en el presente contrato. El
    usuario que no esté de acuerdo con esto no podrá hacer uso de los servicios
    prestados por Solutech Holding SAS.</p>
    
<h3>Capacidad de Celebración de Contratos</h3>
<p>Según la ley de Colombia, el usuario que acepte este acuerdo de términos y
    condiciones debe ser legalmente apto para celebrar un contrato según lo
    permita su autonomía de la voluntad y las leyes que le sean aplicables.
    Refiérase entonces, el que quiera celebrar este contrato a la teoría general de
    celebración de contratos de la ley que aplica en su país.
    Solutech Holding SAS da por entendido que aquel usuario que acepte este
    acuerdo de términos y condiciones conoce previamente si está o no en
    capacidad de celebrar contratos a nombre de la persona que se determine
    como Usuario. Quienes sean considerados incapaces absolutos o relativos o
    
    parciales deberán tener autorización de sus representantes legales para
    celebrar este contrato, y serán estos últimos considerados responsables de
    cualquier conducta de sus apoderados.

<h3>Registro de Cuentas y Usuarios</h3>
<p>Solutech Holding SAS presta un servicio de software que se accede a través
    de su sitio web <a href="www.solutechholding.com" target="_blank">www.solutechholding.com</a> y sus subdominios. Los usuarios
    que accedan a este servicio deberán registrar una cuenta y brindar la
    información solicitada en los formularios que se habilitan a la hora de registrar
    una cuenta. Solutech Holding SAS da por entendido que cualquier
    información ingresada en estos formularios es hecha bajo juramento y por lo
    tanto exonera a Solutech Holding SAS de poseer información falsa sobre
    cualquier usuario.
    Solutech Holding SAS permite el ingreso de una cantidad limitada de usuarios
    según el plan que cada titular o usuario de cuenta haya elegido al momento
    de registrar su cuenta. Solutech Holding SAS no permite, en ninguna
    circunstancia, que estos usuarios sean distintos de aquellos que el titular de
    la cuenta haya decidido habilitar como tales para el uso de la plataforma y
    estos no podrán ser reemplazados por otras personas que conozcan las
    credenciales para ingresar a la plataforma. De suceder una situación como
    ésta, Solutech Holding SAS no se hace responsable por el uso pernicioso de
    la información de la cuenta en contra del titular de esta ni frente al usuario ni
    frente a terceros.</p>

<h3>Descripción del Servicio</h3>
<p>Los derechos de autor sobre las obras de software que componen la
    plataforma y los Servicios serán de titularidad de Solutech Holding SAS y bajo
    ninguna interpretación de estos términos de servicio se entenderán
    transferidos al usuario. Los servicios contratados con Solutech Holding SAS
    no representan un licenciamiento en ninguna de sus formas.
    El servicio de Solutech Holding SAS se inicia al momento de registrar una
    cuenta en el sitio web de Solutech Holding SAS o en cualquiera de sus
    dominios, tras haber aceptado las condiciones expresadas en este contrato.
    El servicio consiste en el uso del software Solutech Holding SAS disponible
    
    en <a href="www.solutechholding.com" target="_blank">www.solutechholding.com</a>, todos los servicios disponibles en sitios web
    asociados, aplicaciones móviles y Api.
    El usuario se hace conocedor de los servicios por los que está pagando a la
    hora de usar alguno de los planes que ofrece Solutech Holding SAS. Solutech
    Holding SAS no se hará responsable en ningún caso por los errores
    cometidos por el usuario a la hora de elegir su plan, así como tampoco al
    momento de digitar o ingresar su información tanto personal como de la
    operación y marcha de su actividad empresarial ni tampoco de la clasificación
    que realice de la misma lo que afectará irremediablemente los resultados
    arrojados por el Software.
    Solutech Holding SAS da por entendido que el titular de la cuenta y los
    usuarios conocen cualquier cambio que se haga en la configuración de ésta,
    así como que realizará los cambios que considere pertinentes. Solutech
    Holding SAS no se responsabilizará por la pérdida de información que ocurra
    por fuerza mayor, caso fortuito o hecho de un tercero tal y como se explica
    bajo el título “Operación del Sitio”.
    Solutech Holding SAS garantiza al Usuario el libre acceso al sitio
    web <a href="www.solutechholding.com" target="_blank">www.solutechholding.com</a> y sus subdominios como también a sus
    aplicaciones móviles publicadas en las plataformas Googleplay o Appstore
    para ver la información allí disponible, bajo condiciones normales, sin
    embargo el usuario acepta que existan circunstancias técnicas por las que
    ésta información puede llegar a estar inaccesible de manera temporal y por lo
    tanto exonera a Solutech Holding SAS de cualquier tipo de responsabilidad
    por este hecho, bajo el entendido de que esto puede obedecer a limitaciones
    inherentes al estado de la tecnología en la actualidad.

<h3>Información de la cuenta</h3>
<p>Solutech Holding SAS es un aplicativo Web, el cual proporciona un sitio
    donde se alojan datos que el usuario administra bajo su cuenta y riesgo.
    Solutech Holding SAS vela por mantener la información de los usuarios,
    segura y toma las precauciones a su juicio necesarias para ello, más no se
    responsabiliza por actos mal intencionados de terceros y las consecuencias
    de ello frente al usuario o frente a terceros.
    
    Solutech Holding SAS pone en conocimiento de sus usuarios, que la
    información que suministre se albergará en servidores de terceros, que
    cumplen con los más altos estándares de seguridad e idoneidad.
    Solutech Holding SAS tampoco se hace responsable por el tipo de
    información ingresada por cada usuario en su cuenta ni por los resultados
    inadecuados si la misma se ingresó de manera inadecuada de acuerdo con
    los parámetros contables y fiscales aplicables a la materia en cada caso. Se
    da a entender que el usuario, al usar los servicios de Solutech Holding SAS,
    hará un uso sano y legal de todas las herramientas que se ponen a su
    disposición y esto exonera a Solutech Holding SAS de cualquier uso indebido
    de su información por parte de cualquier usuario, entendiéndose por ello el
    usar información para evadir obligaciones tributarias, entre otras.
    Solutech Holding SAS no estará obligada a velar por la legalidad del
    contenido e información que los usuarios alberguen en su cuenta a través de
    los Servicios prestados, sin embargo, podrá tomar los correctivos en contra
    de información ilegal, cuando lo considere pertinente.
    El usuario se obliga a la hora de usar cualquiera de los servicios de Solutech
    Holding SAS a (i) no causar daño físico, moral o mental a otros usuarios del
    servicio (ii) no utilizar el servicio con fines maliciosos o de mala voluntad, ni
    para beneficiarse en perjuicio de terceros y mucho menos del Estado
    entendiendo por éste, aquel que de acuerdo con la normatividad que le sea
    aplicable, sea quien deba recibir dinero por concepto de impuestos bajo
    cualquier denominación por el resultado de la operación mercantil del usuario.
    (iii) no usar el servicio con fines criminales o ilegales ni para sacar provecho o
    beneficio ilícito para sí o para terceros, bien sea remunerado o no, (iv) no
    publicar información que vulnere derechos de terceros, tales como derechos
    de propiedad intelectual, secretos industriales o cualquier otro que sea de
    propiedad de terceros y respecto de los cuales no se encuentre autorizado (v)
    publicar información información sensible que ya no es vigente o que pueda
    inducir a error a terceros o al Estado
    El usuario de Solutech Holding SAS manifiesta ser dueño de la información
    que ingresa en el sistema y en ninguna circunstancia esta información pasará
    a ser propiedad de Solutech Holding SAS. De manera inversa, Solutech
    Holding SAS es la única dueña de la plataforma sobre la cual el usuario
    
    ingresa la información, sin que respecto de ésta se considera surtida
    transferencia alguna en razón de éste contrato. Si el usuario da por terminado
    el contrato tendrá la información a su alcance, pero en ningún momento
    Solutech Holding SAS se obliga a entregar la misma en formato alguno ni a
    llevar a cabo ningún tipo de proceso de migración, ni mucho menos a
    efectuar un desarrollo para que la información pueda ser analizada, ingresada
    o digitalizada en cualquier otro software, pues sólo se ingresan datos, para su
    consulta y procesamiento en aras de su funcionamiento y uso.
    Adicional a lo anterior, Solutech Holding SAS no se responsabiliza por el mal
    diligenciamiento de un formulario o por la información que erróneamente
    suministre el USUARIO al momento de realizar un trámite o de ingresar la
    información al software.</p>

<h3>Política de privacidad</h3>
<h3>Funciones publicitarias de Google Analytics implementadas en Solutech
    Holding SAS</h3>
<p>Solutech Holding SAS utiliza las audiencias de remarketing de Google
    Analytics para su uso en Google AdWords y DoubleClick Bid Manager, esto
    con el fin de publicar campañas de remarketing dirigidas a sus usuarios. El
    usuario acepta que su información sea tratada para efectos de Big Data y por
    lo tanto permite que Solutech Holding SAS utilice estas herramientas con
    fines estadísticos.</p>

<h3>Modo de uso de las cookies</h3>
<p>Solutech Holding SAS, Mediante la instalación de cookies e identificadores
    propios y de terceros y a través de herramientas para el análisis de uso de
    cuenta, podrá realizar actividades de seguimiento a los usuarios que utilizan
    su aplicación. Con estas herramientas, Solutech Holding SAS podrá recopilar
    información de cada usuario para el análisis de estadísticas e implementación
    de estrategias de comunicación y publicidad. Estas herramientas podrán
    realizar seguimiento de la configuración del usuario y hacen que su
    experiencia en la aplicación sea más práctica, reconociendo y recordando sus
    preferencias y ajustes.
    En esta medida, Solutech Holding SAS podrá recopilar datos de uso, como la
    duración de uso, o datos demográficos como el origen, el sexo y la edad.
    
    Solutech Holding SAS usa esta información para fines analíticos. El usuario
    de Solutech Holding SAS podrá deshabilitar estas herramientas.
    Las actividades de seguimiento adelantadas por Solutech Holding SAS
    también podrán realizarse dentro de la aplicación, para análisis interno,
    ejemplo, pero no exclusivamente para determinar tendencias y participación
    en la aplicación, tiempo de uso, preferencia, entre otros. Estas herramientos
    podrán ser desactivadas por el usuario ‘para conservar su privacidad.</p>

<h3>Análisis de datos.</h3>
<p>El usuario al crear su cuenta y hacer uso de cualquiera de las aplicaciones
    publicadas por Solutech Holding SAS en su pagina <a href="www.solutechholding.com" target="_blank">www.solutechholding.com</a>
    , acepta que Solutech Holding SAS puede suministrar, transferir, compartir y
    analizar los datos del giro ordinario de su negocio, con empresas aliadas,
    filiales, vinculadas o subordinadas de Solutech Holding SAS en Colombia o
    cualquier otro país, esta información podrá ser usada para fines comerciales
    por parte de los terceros.
    El usuario en cualquier momento puede solicitar a Solutech Holding SAS que
    detenga de forma indefinida el proceso de compartir sus datos con terceros,
    para esto debe realizar su solicitud al
    correo servicioalcliente@solutechholding.com  o comunicarse a través de los
    canales dispuestos para ello.</p>

<h3>Seguridad de la Cuenta</h3>
<p>Solutech Holding SAS vela por la seguridad de la contraseña que provea el
    titular de cuenta y los usuarios a la hora de registrar una cuenta con Solutech
    Holding SAS en cualquiera de sus productos y garantiza que tomará las
    medidas que se encuentren a su alcance para que esta contraseña no sea
    vista por terceros, mas no puede arrogarse la responsabilidad de garantizar
    su confidencialidad. Por otro lado Solutech Holding SAS no se responsabiliza
    por el mal uso de la contraseña por parte del usuario ni por el uso de
    contraseñas que sean fáciles de descifrar, asumiendo que siempre que se
    acceda al sistema, lo hace el usuario directamente.</p>

<h3>Operación del Sitio</h3>
<p>Solutech Holding SAS llevará a cabo las gestiones que a su juicio estime
    conducentes, tendientes a que los sitios webs asociados siempre estén
    disponibles para el usuario, pero no garantiza lo anterior, tanto por los daños
    en las comunicaciones, por actos de terceros, mantenimiento o
    reestructuración de los sitios u otro tipo de actos que se escapen del alcance
    y responsabilidad directa de Solutech Holding SAS. No obstante lo anterior,
    Solutech Holding SAS garantiza a los usuarios que pagan por el servicio, que
    el software estará disponible desde cualquier lugar del mundo que permita
    una conexión a internet, siempre y cuando el usuario recuerde las
    credenciales de su cuenta y las condiciones de prestación del servicio de
    conexión a Internet por parte de cada proveedor, lo permitan, lo cual escapa
    de las obligaciones de Solutech Holding SAS, de manera tal que si no se
    pudiera acceder por el tipo de conexión y las características que le sean
    propias, por fallas en la comunicación o por el deficiente servicio del
    mencionado proveedor, ello no implica en ningún momento incumplimiento de
    la prestación del servicio contratado por parte de Solutech Holding SAS. En
    cuanto al mantenimiento y reestructuración del sitio, Solutech Holding SAS se
    reserva el derecho de hacerlo sin previo aviso a los usuarios, pero procurará
    que se les brinde un aviso con la antelación que a su juicio considere
    conveniente o prudente para evitar incomodidades o eventuales perjuicios en
    el procesamiento de datos o de información.</p>

<h3>Limitaciones</h3>
<p>EL CLIENTE no podrá aplicar técnicas de ingeniería inversa, descompilar o
    desensamblar el software, ni realizar cualquier otra operación que tienda a
    descubrir el código fuente. Además queda prohibida la separación de los
    componentes. Solutech Holding SAS autoriza el uso del software como un
    producto único. Las partes que lo componen no se podrán separar para
    utilizarlas en más de aquellas unidades o estaciones de trabajo en las que lo
    instale SOLUTECH HOLDING SAS, ni hacer uso de ellas por separado. El
    usuario comprende que faltar a lo dispuesto en ésta cláusula constituye un
    delito de acuerdo al artículo 272 del Código Penal Colombiano.</p>

<h3>Interrupción y Terminación del Servicio</h3>
<p>Solutech Holding SAS se reserva el derecho de terminar el servicio en
    cualquier momento, tanto de manera permanente como temporal, para
    aquellos casos en los que se deban realizar pagos sucesivos.
    Solutech Holding SAS podrá terminar de manera unilateral la cuenta de un
    usuario en los siguientes escenarios: (i) En caso de que el USUARIO utilice
    los servicios prestados por Solutech Holding SAS para fines contrarios a la
    ley, especialmente aquellos que contraríen derechos de propiedad intelectual
    de terceros y sobre de todos de Solutech Holding SAS y de otros usuarios; (ii)
    En caso de que Solutech Holding SAS encuentre que el USUARIO está
    haciendo uso de su cuenta para la transmisión de programas malignos como
    virus, malwares, spywares, troyanos o similares, que puedan comprometer el
    debido funcionamiento de la plataforma de Solutech Holding SAS o que
    perjudiquen a terceros; (iii) Cuando existan elementos que permitan inferir a
    Solutech Holding SAS que el USUARIO no cuenta con la edad mínima para
    contratar los Servicios, en los términos del artículo segundo de estas
    Condiciones.
    Solutech Holding SAS se reserva el derecho a decidir si el contenido
    publicado por los usuarios, al igual que el material de texto o fotográfico que
    sea cargado a la página web de Solutech Holding SAS resulta apropiado y se
    ajusta a las Condiciones. En éste sentido, Solutech Holding SAS podrá
    impedir la publicación y comercialización de contenido que infrinja derechos
    de imagen, de habeas data y de privacidad de terceros, así como aquellos
    que resulten ofensivos, difamatorios o que constituyan infracciones a la ley.</p>

<h3>Parágrafo: Suspensión Del Servicio.</h3>
<p>Solutech Holding SAS se reserva el derecho de suspender la prestación de
    los servicios al USUARIO y de inhabilitar su acceso al Software, así como a
    cualquiera de los módulos creados para EL USUARIO en caso de no recibir
    el pago del servicio en la forma acordada.
    Se dará la suspensión del servicio al USUARIO con aviso anticipado.</p>

<h3>Legislación Aplicable y Jurisdicción</h3>
<p>Este contrato se rige por las leyes de la República de Colombia. Si cualquier
    parte de este contrato se declara nula o contraria a la ley, entonces la
    
    provisión inválida o no exigible se considerará sustituida por una disposición
    válida y aplicable que más se acerque a la intención del contrato original y el
    resto del acuerdo entre Solutech Holding SAS y el usuario continuará en
    efecto. A menos que se especifique lo contrario en este documento, estas
    condiciones constituyen el acuerdo completo entre el usuario y Solutech
    Holding SAS con respecto a los Servicios de Solutech Holding SAS y
    reemplaza a todas las comunicaciones previas y propuestas, tanto de manera
    electrónica, oral o escrita, entre el usuario y Solutech Holding SAS con
    respecto a los Servicios de Solutech Holding SAS.</p>

<h3>Derecho de retracto.</h3>
<p>El USUARIO podrá ejercer su derecho al retracto en los términos del artículo
    47 de la Ley 1480 de 2011, es decir, podrá solicitar que se reverse la
    transacción perdiendo el dominio sobre su cuenta y recibiendo la devolución
    de lo pagado. Para efectos de poder ejercer el derecho de retracto será
    necesario que el USUARIO lo ejerza dentro de la oportunidad legal, es decir
    durante los cinco (5) días posteriores a la celebración del contrato.</p>
    
    </article>
</div>

<app-website-footer></app-website-footer>
