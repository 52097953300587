<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <div class="options clearfix">
              <div class="calendar-options float-right">
                <div class="btn btn-outline-dark btn-calendar btn-left" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Anterior</div>
                <div class="btn btn-outline-dark btn-calendar" mwlCalendarToday [(viewDate)]="viewDate" >Hoy</div>
                <div class="btn btn-outline-dark btn-calendar btn-right" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Siguiente</div>  
              </div>
            </div>
            <h1 class="d-inline-block">Agenda</h1>
            <h2 class="d-inline-block">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h2>
        </div>
        <div class="card-body">

          <div [ngSwitch]="view">  
            <mwl-calendar-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
        
            <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
        
            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
          </div>

        </div>
    </div>
</div>