import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { BuildingService } from 'src/app/core/services/building.service'
import { showMessage } from 'src/app/core/util/common'
import { Building } from 'src/app/core/models/building'
import { Apartment } from 'src/app/core/models/apartment'
import { Block } from 'src/app/core/models/block'
import Swal from 'sweetalert2'
import { CreateBlockComponent } from 'src/app/components/shared/block/create-block/create-block.component'
import { EditBlockComponent } from 'src/app/components/shared/block/edit-block/edit-block.component'
import { CreateApartmentComponent } from 'src/app/components/shared/apartment/create-apartment/create-apartment.component'
import { EditApartmentComponent } from 'src/app/components/shared/apartment/edit-apartment/edit-apartment.component'
import { BlockService } from 'src/app/core/services/block.service'
import { ApartmentService } from 'src/app/core/services/apartment.service'

@Component({
  selector: 'app-building-detail',
  templateUrl: './building-detail.component.html'
})
export class BuildingDetailComponent implements OnInit {

  id: number
  building: Building
  apartments: Apartment[]
  selectedBlock = new Block()
  loadingApartments = false

  constructor(private route: ActivatedRoute, private router: Router, private buildingservice: BuildingService, private blockservice: BlockService, private apartmentservice: ApartmentService, public dialog: MatDialog) {  
    this.route.paramMap.subscribe(params => {
      this.id = Number(params.get('id'))
      this.loadData()
    })
  }

  ngOnInit(): void {
  }

  loadData(){
    this.buildingservice.getBuildingID(this.id).subscribe(resp => {
      this.building = resp.building
    }, error => {
      showMessage("Error!", error, false)
    })
  }

  createBlock() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(CreateBlockComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.building.blocks.push(result)
      }
    })
  }

  editBlock(block: Block) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id, block: block}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(EditBlockComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.building.blocks
        let index = data.findIndex((item: Block) => item.id == result.id)
        data[index] = result
        this.building.blocks = data
      }
    })
  }

  createApartment() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id, block: this.selectedBlock, building: this.building}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(CreateApartmentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.apartments.push(result)
      }
    })
  }

  editApartment(apartment: Apartment) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id, block: this.selectedBlock, building: this.building, apartment: apartment}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(EditApartmentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        let index = this.apartments.findIndex((item: Apartment) => item.id == result.id)
        this.apartments[index] = result
      }
    })
  }

  showApartments(block: Block) {
    this.selectedBlock = block
    this.loadingApartments = true
    this.apartments = []
    this.apartmentservice.getApartmentBlock(this.selectedBlock.id).subscribe(
      data => {
        this.apartments = data
        this.loadingApartments = false
      }
    ), error => {
      showMessage("¡Error!", error, false)
    }
  }

  showApartment(apartment: Apartment) {
    this.router.navigate(['/support/apartment',apartment.id])
  }

}