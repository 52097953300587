import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/core/services/auth.service'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { showMessage } from 'src/app/core/util/common'
import { Admin } from 'src/app/core/models/admin'

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html'
})
export class LoginAdminComponent implements OnInit {

  admin: Admin = new Admin()
  remember = false
  showPreview = false

  formGroup: UntypedFormGroup
  resetFormGroup: UntypedFormGroup

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private auth: AuthService) {
    this.route.paramMap.subscribe(params => {
      let iv = params.get('iv')
      let token = params.get('token')
      if(token != null){
        this.loginDAdmin(iv, token)
      }
    })
    this.createForm()
  }

  ngOnInit(): void {
    if (localStorage.getItem('email')) {
      this.admin.email = localStorage.getItem('email')
      this.remember = true
    }
  }

  createForm() {
    this.formGroup = this.fb.group({
      'email':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(3), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'building_id': [null, [Validators.required]],
      'password': [null, [Validators.required]]
    })
    this.resetFormGroup = this.fb.group({
      'email':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(3), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    })
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get resetMail() {
    return this.resetFormGroup.get('email') as UntypedFormControl
  }

  get password() {
    return this.formGroup.get('password') as UntypedFormControl
  }

  get building() {
    return this.formGroup.get('building_id') as UntypedFormControl
  }

  loginDAdmin(iv: string, token: string){
    Swal.showLoading()
    this.auth.loginAdminToken(iv, token)
      .subscribe(resp => {
          if (resp) {
            Swal.close();
            if (this.remember) {
              localStorage.setItem('email', this.admin.email)
            }
            this.router.navigateByUrl('/home')
          } else {
            showMessage('Error iniciando sesion', 'Error de autentificacion', false)
          }
        },
        error => {
          showMessage('Error', error.error.message, false)
        })
  }

  loginAdmin(form) {
    Swal.showLoading()
    this.auth.loginAdmin(form).subscribe(resp => {
      localStorage.setItem('token', resp.token)
      Swal.close()
      //if (this.remember) {
      //  localStorage.setItem('email', this.admin.email)
      //}
      this.router.navigateByUrl('admin/home')
    }, error => {
      showMessage('¡Error!', error, false)
    })

    /*Swal.showLoading()
    this.auth.loginAdmin(this.admin).subscribe(resp => {
          if (resp) {
            Swal.close()
            if (this.remember) {
              localStorage.setItem('email', this.admin.email)
            }
            this.router.navigateByUrl('/home')
          } else {
            showMessage('Error iniciando sesion', 'Error de autentificacion', false)
          }
        },
        error => {
          showMessage('Error', error, false)
        })*/
  }

  resetPassword(form: NgForm) {
    Swal.showLoading()
    this.auth.resetPasswordAdmin(form.value)
      .subscribe(resp => {
          if (resp) {
            Swal.close();
            showMessage("", resp, true)
          }
        },
        error => {
          showMessage('Error', error, false)
        })
  }

  showPreviewReset() {
    this.showPreview = true
  }

  canceled() {
    this.showPreview = false
  }

}