import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {

  deviceInfo = null
  isDesktopDevice = false

  constructor(private deviceService: DeviceDetectorService) { 
    this.checkDevice()
  }

  ngOnInit(): void {
  }

  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo()
    const isMobile = this.deviceService.isMobile()
    const isTablet = this.deviceService.isTablet()
    this.isDesktopDevice = this.deviceService.isDesktop()
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(this.isDesktopDevice); // returns if the app is running on a Desktop browser.
    
    if (this.deviceInfo.os == "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.solutechholding.biru"
    }

    if (this.deviceInfo.os == "iOS") {
      window.location.href = "https://apps.apple.com/us/app/biru/id1558359322"
    }

    if (this.deviceInfo.os != "Android" && this.deviceInfo.os != "iOS") {
      this.isDesktopDevice = true
    }
    
  }
}
