<div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">

          <form [formGroup]="formGroup" (ngSubmit)="loginAdmin(formGroup.value)" class="form-signin" *ngIf="showPreview == false">
            <h2 class="card-title ">Iniciar Sesión</h2>

            <mat-form-field class="form-group" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" autocomplete="off">
              <div *ngIf="!email.valid && email.touched">
                <mat-error *ngIf="email.errors.required || email.errors.email || email.errors.pattern">Debe ingresar un correo electrónico válido.</mat-error>
              </div>
            </mat-form-field>

            <mat-form-field class="form-group" appearance="outline">
              <mat-label>Código Edificio</mat-label>
              <input matInput formControlName="building_id" type="number" autocomplete="off">
              <div *ngIf="!building.valid && building.touched">
                <mat-error *ngIf="building.errors.required">Debe ingresar código de edificio válido.</mat-error>
              </div>
            </mat-form-field>

            <mat-form-field class="form-group" appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input matInput formControlName="password" type="password" autocomplete="off">
              <div *ngIf="!password.valid && password.touched">
                <mat-error *ngIf="password.errors.required">Debe ingresar una contraseña.</mat-error>
              </div>
            </mat-form-field>

            <button class="btn btn-main btn-lg btn-block" type="submit">Iniciar</button>
            <button class="btn btn-default float-right" type="button" (click)="showPreviewReset()">Recuperar contraseña</button>

          </form>


          <!--h4 class="card-title ">Iniciar Sesión</h4>
          <form (ngSubmit)="loginAdmin(registryForm)" #registryForm="ngForm" class="form-signin" *ngIf="showPreview == false">
            
            <span *ngIf="registryForm.submitted && registryForm.controls['email'].errors" class="text-danger animated fadeIn">El correo es obligatorio y debe ser del tipo: example@example.com</span>
            <div class="form-group" appearance="outline">
              <input class="input100" type="email" name="email" [(ngModel)]="admin.email" required email placeholder="Email" class="form-control">
              <span class="focus-input100"></span>
            </div>

            <div class="form-group" appearance="outline">
              <input class="input100" type="number" name="building_id" [(ngModel)]="admin.building_id" required email placeholder="Edificio" class="form-control">
              <span class="focus-input100"></span>
            </div>

            <span *ngIf="registryForm.submitted && registryForm.controls['password'].errors" class="text-danger animated fadeIn">Al menos 6 letras</span>
            <div class="form-group" appearance="outline" data-validate="Password is required">
              <input class="input100" type="password" name="password" [(ngModel)]="admin.password" required minlength="6" placeholder="Password" class="form-control">
              <span class="focus-input100"></span>
            </div>

            <!--div class="custom-control custom-checkbox mb-3">
              <input [(ngModel)]="remember" class="custom-control-input" id="ckb1" type="checkbox" name="remember-me">
              <label class="custom-control-label" for="ckb1">Recordar mi usuario</label>
            </div-- >

            <button class="btn btn-main btn-lg btn-block" type="submit">Iniciar</button>
            <button class="btn btn-right float-right" (click)="showPreviewReset()">Recuperar contraseña</button>
          </form-->

          <form (ngSubmit)="resetPassword(resetForm)" #resetForm="ngForm" class="form-reset"
                *ngIf="showPreview == true">
            <!--h2>Recuperar contraseña</h2-->
            <span *ngIf="resetForm.submitted && resetForm.controls['email'].errors" class="text-danger animated fadeIn">El correo es obligatorio</span>
            <div class="form-group" appearance="outline">
              <p>Para recuperar tu contraseña por favor ingresa el correo electrónico y pronto recibirás las instrucciones.</p>
              <input class="input100" type="text" name="email" required email placeholder="Email" class="form-control" ngModel/>
              <span class="focus-input100"></span>
              <button class="btn btn-lg btn-main btn-block" type="submit">Enviar</button>
              <button class="btn btn-right float-right" type="button" (click)="canceled()">Cancelar</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
