import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import Swal from 'sweetalert2'

interface Message {
  id: number;
  name: string;
}

interface Email {
  id: number;
  name: string
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit {

  messages: Message[] = [
    {id: 1, name: "Prueba"},
    {id: 2, name: "Prueba de que sirve"},
    {id: 3, name: "Funciona"}
  ]

  emails: Email[] = [
    {id: 1, name: "Correo aleatorio"},
    {id: 2, name: " Correo nuevo"},
    {id: 3, name: " esta corriendo el paquete"}
  ]

  formGroup: UntypedFormGroup
  showPreview = false

  constructor(private fb: UntypedFormBuilder) {
    this.createForm();
   }

  ngOnInit(): void {
  }
  createForm(){
    this.formGroup = this.fb.group({
      'message': [null]
    })
  }

  get message(){
    return this.formGroup.get('message') as UntypedFormControl
  }

  onSubmit(form){
    this.showMessage("Guardando", "Por favor espere...")
    Swal.showLoading();

  }

  showEmail(){
    this.showPreview = true
  }

  showMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text
    })
  }

}
