<div class="container admin-container">
    
    <div class="card">
        <div class="card-header">
            <h1>Solicitudes</h1>
            <div class="options">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="user">
                    <mat-button-toggle value="user" (click)="loadUserRequests()">Usuarios</mat-button-toggle>
                    <mat-button-toggle value="doorman" (click)="loadDoormanRequests()">Porteros</mat-button-toggle>
                </mat-button-toggle-group>
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <select class="form-select" (change)="filterRequest($event)">
                    <option *ngFor="let type of types" [value]="type.id">{{ type.name }}</option>
                </select>
            </div>
        </div>
        <div class="card-body requests">
            <div class="row">
                <div class="col-md-3 list">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let request of filteredRequests" (click)="getRequest(request)" [ngClass]="request.status == 2 ? 'closed' : ''">
                            <div *ngIf="isUserRequests == true">
                                <span [innerHTML]="request.getMDate()"></span>
                                <h2>{{ request.users }}</h2>
                                <h3>Torre: {{ request.block }} - Apartamento: {{ request.apartment }}</h3>
                                <p>{{ request.subject }}</p>
                            </div>
                            <div *ngIf="isUserRequests == false">
                                <span [innerHTML]="request.getMDate()"></span>
                                <h2>{{ request.doorman }}</h2>
                                <p>{{ request.subject }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9 detail">
                    <mat-spinner *ngIf="request.id < 0 && loading" [diameter]="50"></mat-spinner>
                    <div class="container" *ngIf="request.id > 0">
                        
                        <div class="info" *ngIf="isUserRequests == true">
                            <button class="btn btn-ok" (click)="closeRequest(request)" *ngIf="request.status == 1">Cerrar Solicitud</button>
                            <h2>{{ request.users }}</h2>
                            <p>Torre: {{ request.block }}  -  Apartamento: {{ request.apartment }}</p>
                        </div>
                        <!--div class="info" *ngIf="isUserRequests == false">
                            <button class="btn btn-ok" (click)="closeRequest(request)" *ngIf="request.status == 1">Cerrar Solicitud</button>
                            <h2>{{ request.doorman }}</h2>
                        </div-->
                        
                        <hr class="mt-0 mb-0">
                        <div class="message-body" #scrollMe [scrollTop]="scrolltop">
                            <ul class="list-group messages" [ngClass]="request.status == 1 ? 'p-footer' : 'pb-1'">
                                <li class="list-group-item">
                                    <div class="message" [ngClass]="'left'">
                                        <span [innerHTML]="request.getMDate()"></span>
                                        <h3 class="user" *ngIf="isUserRequests == true">{{ request.users }}</h3>
                                        <!--h3 class="user" *ngIf="isUserRequests == false">{{ request.doorman }}</h3-->
                                        <p>{{ request.subject }}</p>
                                    </div>
                                </li>
                                <li class="list-group-item" *ngFor="let message of messages">

                                    <div *ngIf="isUserRequests == true">
                                        <div class="message" [ngClass]="message.user_id != null ? 'left' : 'right'">
                                            <span [innerHTML]="message.getMDate()"></span>
                                            <h3 class="user" *ngIf="message.user_id != null">{{ message.users }}</h3>
                                            <h3 class="user" *ngIf="message.admin_id != null"> {{ message.admins }}</h3>
                                            <p>{{ message.message }}</p>
                                        </div>
                                        <div *ngIf="message.files" class="d-block clearfix w-100"></div>
                                        <div *ngIf="message.files" class="message files" [ngClass]="message.user_id != null ? 'left' : 'right'">
                                            <ul *ngIf="message.files">
                                                <li *ngFor="let file of message.getFiles()">
                                                    <img [src]="file" (click)="openImage(file)" *ngIf="isImage(file)"/>
                                                    <img src="assets/img/pdf-placeholder.jpg" (click)="openPDF(file)" *ngIf="isPDF(file)"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div *ngIf="isUserRequests == false">
                                        <div class="message" [ngClass]="message.doorman_id != null ? 'left' : 'right'">
                                            <span [innerHTML]="message.getMDate()"></span>
                                            <h3 class="user" *ngIf="message.doorman_id != null">{{ message.doorman }}</h3>
                                            <h3 class="user" *ngIf="message.admin_id != null"> {{ message.admins }}</h3>
                                            <p>{{ message.message }}</p>
                                        </div>
                                        <div *ngIf="message.files" class="d-block clearfix w-100"></div>
                                        <div *ngIf="message.files" class="message files" [ngClass]="message.doorman_id != null ? 'left' : 'right'">
                                            <ul *ngIf="message.files">
                                                <li *ngFor="let file of message.getFiles()">
                                                    <img [src]="file" (click)="openImage(file)" *ngIf="isImage(file)"/>
                                                    <img src="assets/img/pdf-placeholder.jpg" (click)="openPDF(file)" *ngIf="isPDF(file)"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div class="footer" *ngIf="request.status == 1">
                            <div class="images" *ngIf="filesPath.length > 0">
                                <div class="preview" *ngFor="let path of filesPath; index as i" [style.background-image]="'url(' + path + ')'">
                                    <!--img [src]="path" -->
                                    <button class="btn" (click)="removeImage(i)"><span class="material-icons">close</span></button>
                                </div>
                            </div>
                            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

                                <mat-form-field class="form-group" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea matInput formControlName="message" autocomplete="off"></textarea>
                                </mat-form-field>
                                <div class="buttons">
                                    <button mat-raised-button type="button" class="btn" (click)="attachFile()">
                                        <span class="material-icons">attach_file</span>
                                    </button>
                                    <button mat-raised-button type="submit" class="btn send" [disabled]="formGroup.invalid">
                                        <span class="material-icons">send</span>
                                    </button>
                                </div>

                                <input type="file" #fileInput id="uploadFile" (change)="onFileChange($event)" name="uploadFile" multiple="multiple" accept="image/jpeg,image/jpg,image/png,application/pdf" class="file" />
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>