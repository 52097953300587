<div class="container admin-container p-4">
    <div class="card pl-0">
        <div class="card-header mt-0">
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
            </div>
        </div>

        <div class="card-body">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container-->

                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Recibido Por</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef> Nota </th>
                <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                </ng-container>

                <ng-container matColumnDef="delivery_company">
                    <th mat-header-cell *matHeaderCellDef>Compañia de envio</th>
                    <td mat-cell *matCellDef="let element"> {{element.delivery_company}} </td>
                    </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

