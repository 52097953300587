import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ServiceCompany } from 'src/app/core/models/service.company'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'
import { CreateServiceCompanyComponent } from '../../create-service-company/create-service-company.component'

@Component({
  selector: 'app-create-dependency',
  templateUrl: './create-dependency.component.html'
})
export class CreateDependencyComponent implements OnInit {

  formGroup: UntypedFormGroup
  serviceCompany: ServiceCompany

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<CreateServiceCompanyComponent>) { 
    this.serviceCompany = data.company
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name':[null, [Validators.required, Validators.maxLength(60), Validators.minLength(4)]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.contactService.createDependency(form, this.serviceCompany.id).subscribe(
      data => {
        Swal.close()
        showMessage("¡Completo!", data.message, true)
        this.dialogRef.close(data.dependency)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}