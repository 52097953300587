<mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
<div class="container admin-container" *ngIf="loading == false">
  <div class="card">
    <div class="card-header">
      <h1>Edificios</h1>
      <div class="options">
        <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
        <select class="form-select" (change)="filterRequestCity($event)">
          <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
        </select>
        <select class="form-select" (change)="filterRequestState($event)">
          <option *ngFor="let state of states" [value]="state.id">{{ state.name }}</option>
        </select>
        <select class="form-select" (change)="filterRequestType($event)">
          <option *ngFor="let type of types" [value]="type.id">{{ type.name }}</option>
        </select>
        <button type="button" class="btn" (click)="createBuilding()">Nuevo +</button>
      </div>
    </div>

    <div class="card-body">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let building">{{ building.id }}</td>
        </ng-container>

        <ng-container matColumnDef="building">
          <th mat-header-cell *matHeaderCellDef>Edificio</th>
          <td mat-cell *matCellDef="let building">{{ building.name }}</td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>Ciudad</th>
          <td mat-cell *matCellDef="let building">{{ building.city }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Tipo</th>
          <td mat-cell *matCellDef="let building">{{ building.getType() }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Dirección</th>
          <td mat-cell *matCellDef="let building">{{ building.address }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let building">{{ building.getStatus() }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let building">
                <button class="btn" type="button" [routerLink]="['/support/building',building.id]" ><mat-icon aria-hidden="false">more_horiz</mat-icon></button>
                <button class="btn" (click)="editBuilding(building)"><mat-icon aria-hidden="false">create</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>