<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <h1>Bitácora</h1>
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
            </div>
        </div>
        <div class="card-body logbook">
            <ul>
                <li *ngFor="let logbook of filteredLogbooks">
                    <span>{{ logbook.getDate() }}<br>{{ logbook.getTime() }}</span>
                    <h3>{{ logbook.doorman }}</h3>
                    <p>{{ logbook.message }}</p>
                    <div *ngIf="logbook.image">
                        <img [src]="logbook.image" (click)="openImage(logbook.image)" >
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>