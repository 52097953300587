<app-website-header [isHome]="false"></app-website-header>

<div class="website container">
    <h1>Contáctanos</h1>
    <p>Envianos un mensaje y pronto nuestro equipo de ventas se pondrá en contacto.</p>
    <div class="row">
        <div class="col-md-8">
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="name" type="text" placeholder="Nombre" required="">
                            <div *ngIf="!name.valid && name.touched">
                                <mat-error *ngIf="name.errors.required">No olvide ingresar su nombre.</mat-error>
                                <mat-error *ngIf="name.errors.maxlength">El nombre supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group">
                            <input type="email" class="form-control" formControlName="email" placeholder="Email" required="">
                            <div *ngIf="!email.valid && email.touched">
                                <mat-error *ngIf="email.errors.required">Debe ingresar un email válido.</mat-error>
                                <mat-error *ngIf="email.errors.email">Debe ingresar un email válido.</mat-error>
                                <mat-error *ngIf="email.errors.maxlength">El email supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="building"  placeholder="Nombre del Conjunto Residencial" required="">
                            <div *ngIf="!building.valid && building.touched">
                                <mat-error *ngIf="building.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <textarea class="form-control" rows="6" placeholder="Mensaje" formControlName="message"  required=""></textarea>
                    <div *ngIf="!message.valid && message.touched">
                        <mat-error *ngIf="name.errors.required">No olvide ingresar el mensaje.</mat-error>
                        <mat-error *ngIf="name.errors.maxlength">El mensaje supera el total de caracteres permitidos.</mat-error>
                    </div>
                </div>

                <button type="submit" class="btn btn-default center-block" [disabled]="formGroup.invalid">Enviar</button>
            </form>
        </div>
    </div>
</div>

<app-website-footer></app-website-footer>