<div class="container modal-ag">
    <h2>Crear Blog</h2>

    <!--form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Foto</mat-label>
            <ngx-mat-file-input formControlName="image" placeholder="Foto" (change)="onFileChange($event)" class="form-control">
            </ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Titulo</mat-label>
            <input matInput formControlName="title" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="description" rows="3" autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Contenido</mat-label>
            <textarea matInput formControlName="content" rows="3" autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Tag</mat-label>
            <input matInput formControlName="tag"  autocomplete="off">
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="status" >
                <mat-option *ngFor="let status of statusArr" [value]="status.id">
                    {{ status.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-inline mr-2" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark" (click)="close()">Cancelar</button>
        </div>

    </form-->
</div>