import { Desearizable } from './desearizable'

export class Place implements Desearizable {

    public id: number
    public place_type_id: number
    public name: string
    public building_id: number
    public price: number
    public image: string
    public price_type: number
    public description: string
    public cancellation_type: number
    public cancellation_minimum: number
  
    deserialize(input: any): this {
      Object.assign(this, input)
      return this
    }

    getType(){
      switch(this.price_type){
        case 1: return "Hora"
        case 2: return "Día"
        case 3: return "Evento"
        default: return "-"
      }
    }

}