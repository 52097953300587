import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { UntypedFormBuilder } from '@angular/forms'
import { CreateUserComponent } from './user/create-user/create-user.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditUserComponent } from './user/edit-user/edit-user.component'
import { User } from 'src/app/core/models/users'
import { Apartment } from 'src/app/core/models/apartment'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { ActivatedRoute } from '@angular/router'
import { BlockService } from 'src/app/core/services/block.service'
import { UserService } from 'src/app/core/services/user.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-apartment-detail',
  templateUrl: './apartment-detail.component.html'
})
export class ApartmentDetailComponent implements OnInit {

  displayedColumns: string[] = ['name', 'phone', 'email', 'type', 'state', 'activation', 'actions']
  dataSource = new MatTableDataSource()

  id: number
  user: User
  apartment: Apartment

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private fb: UntypedFormBuilder, private apartmentService: ApartmentService, private route: ActivatedRoute, private blockservice: BlockService, private userService: UserService, public dialog: MatDialog) {
    this.route.paramMap.subscribe(params => {
      this.id = Number(params.get('id'))
      this.loadData()
    })
  }

  ngOnInit(): void {
  }

  loadData() {
    this.apartmentService.getApartmentID(this.id).subscribe(data => {
      this.dataSource.data = data.users.map(x => new User().deserialize(x))
      this.apartment = data
    }, error => {
      showMessage("Error", error.error.message, false)
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createUser() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id, type: this.apartment.building.type}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(CreateUserComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const user = new User().deserialize(result)
        const data = this.dataSource.data
        data.push(user)
        this.dataSource.data = data
      }
    })
  }

  editUser(user) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.id, user: user, type: this.apartment.building.type}
    dialogConfig.width = '450px'
    const dialogRef = this.dialog.open(EditUserComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const user = new User().deserialize(result)
        const data = this.dataSource.data
        let index = data.findIndex((item: User) => item.id == user.id)
        data[index] = user
        this.dataSource.data = data
      }
    })
  }

  sendActivation(user: User) {
    Swal.showLoading()
    this.userService.sendActivation(user.id).subscribe(
      data => {
        Swal.close()
        showMessage('¡Completo!', data.message, true)
      }, error => {
        showMessage("Error", error, false)
      })
  }

}
