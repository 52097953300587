import { Component, OnInit } from '@angular/core'
import { BlogService } from 'src/app/core/services/blog.service'
import { Blog } from 'src/app/core/models/blog'

@Component({
  selector: 'app-website-blog',
  templateUrl: './website-blog.component.html',
  styleUrls: ['./website-blog.component.sass']
})
export class WebsiteBlogComponent implements OnInit {

  blogs: Blog[] = []

  constructor(private service: BlogService) {
    service.getBlogs().subscribe(data => {
      this.blogs = data
    })
  }

  ngOnInit(): void {
  }

}
