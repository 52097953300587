import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Admin } from 'src/app/core/models/admin'
import { Booking } from 'src/app/core/models/booking'
import { Logbook } from 'src/app/core/models/logbook'
import { Session } from 'src/app/core/models/session.modules'
import { AuthService } from 'src/app/core/services/auth.service'
import { BuildingService } from 'src/app/core/services/building.service'
import { WalkthroughComponent } from '../walkthrough/walkthrough.component'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  admin: Admin = null
  session: Session

  totalApartments = 0
  totalBlocks = 0
  totalUsers = 0
  totalDoormans = 0

  requests: Request[] = []
  bookings: Booking[] = []
  logbooks: Logbook[] = []

  constructor(private auth: AuthService, private router: Router, private buildingService: BuildingService, public dialog: MatDialog) {

    auth.loginEvent.subscribe(session => {
      if (session) {
        this.admin = new Admin().deserialize(session.admin)
      } else {
        //this.type = this.sessionType.None
      }
    })

    var session = this.auth.readToken()
    if (session) {
      this.admin = new Admin().deserialize(session.admin)
      if(!this.admin.accepted){
        this.showWalkthrough()
      }
    }

    buildingService.getInfo().subscribe(data => {
      this.totalApartments = data.total_apartments
      this.totalBlocks = data.total_blocks
      this.totalUsers = data.total_users
      this.totalDoormans = data.total_doormans

      this.requests = data.requests
      this.bookings = data.bookings
      this.logbooks = data.logbooks
    })
  }

  ngOnInit(): void {
  }

  showWalkthrough() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {admin: this.admin}
    dialogConfig.width = '600px'
    dialogConfig.disableClose = true
    const dialogRef = this.dialog.open(WalkthroughComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
    
      }
    })
  }

  showLogbook() {
    this.router.navigateByUrl('/admin-logbook')
  }

}
