import {Component, Input, OnInit, ViewChild, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from '../../core/services/user.service';
import {Support} from '../../core/models/support.modules';
import {Subject} from 'rxjs';

export enum ModalForm {
  createUser,
  editUser,
  none
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html'
})
export class SupportComponent implements OnInit {
  @Output() supportEvent = new EventEmitter();
  reloadSubject: Subject<boolean> = new Subject<boolean>();
  support: Support;

  modalForm: ModalForm;
  allModalForm = ModalForm;
  title = '';

  isEditing = false;
  newForm = false;

  displayedColumns: string[] = ['id', 'first_name', 'last_name', 'email', 'role', 'state', 'actions'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private service: UserService) {
    this.modalForm = ModalForm.none;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    this.service.getSupport().subscribe((data: any[]) => {
      this.dataSource.data = data;
    });
    this.reloadSubject.subscribe(resp => {
      if (resp) {
        this.loadData();
      }
    });
    this.loadData();
  }

  loadData() {
    this.service.getSupport().subscribe(data => {
      this.dataSource.data = data;
    });
  }

  reloadData() {
    this.reloadSubject.next(true);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  newUser() {
    this.title = 'Nuevo Usuario';
    this.modalForm = ModalForm.createUser;
  }

  editUser(n: Support): void {
    this.support = n;
    this.title = 'Editar Usuario';
    this.modalForm = ModalForm.editUser;
    this.service.getSupportID(this.support.id).subscribe(data => {
      this.support = data;
    });
  }
}
