import { DatePipe } from '@angular/common'
import { Desearizable } from './desearizable'

export class Message implements Desearizable {

    public id: number
    public subject: string
    public message: string 
    public created_at: Date
    public datepipe: DatePipe

    public admin_id: string 
    public admins: string 

    constructor() {
        this.id = -1
        this.datepipe = new DatePipe('en-US')
    }

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getDate(): string{
        return this.datepipe.transform(this.created_at, 'dd-MM-yyyy')
    }

    getTime(): string{
        return this.datepipe.transform(this.created_at, 'h:mm a')
    }

}