<div class="container admin-dashboard admin-container">
    <div *ngIf="admin != null">
        <div *ngIf="admin.accepted == true">
            <div class="row">
        
                <div class="card">
                    <div class="card-header home-info">
                        <ul>
                            <li>
                                <div class="box purple">
                                    <p>Torres<span>{{ totalBlocks }}</span></p>
                                </div>
                            </li>
                            <li>
                                <div class="box orange">
                                    <p>Apartamentos<span>{{ totalApartments }}</span></p>
                                </div>
                            </li>
                            <li>
                                <div class="box blue">
                                    <p>Usuarios<span>{{ totalUsers }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="box brown">
                                    <p>Porteros<span>{{ totalDoormans }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3 dashboard">
                    <h2>Bitácora</h2>
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let logbook of logbooks" (click)="showLogbook()">
                            <span>{{ logbook.getDate() }}<br>{{ logbook.getTime() }}</span>
                            <h3>{{ logbook.doorman }}</h3>
                            <p>{{ logbook.message }}</p>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 dashboard">
                    <h2>Últimas Reservas</h2>
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let booking of bookings">
                            <span>{{ booking.getDate() }}<br>{{ booking.getTime() }}</span>
                            <h3>{{ booking.place }}</h3>
                            <p>Bloque: {{ booking.block }} <br>Apartamento: {{ booking.apartment }}</p>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 dashboard">
                    <h2>Últimas Solicitudes</h2>
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let request of requests">
                            <span>{{ request.getDate() }}<br>{{ request.getTime() }}</span>
                            <h3>{{ request.subject }}</h3>
                            <p>Bloque: {{ request.block }} <br>Apartamento: {{ request.apartment }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            

            <div class="row">
                <div class="col-md-7 home-info">
                    
                </div>
                <div class="col-md-5">

                </div>
            </div>

        </div>
    </div>
    <div *ngIf="admin == null">

    </div>
</div>