import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Doorman } from '../models/doorman'
import { DoormanResponse } from '../reponse/data.response'
import { GlobalErrorHandler } from '../util/common'

@Injectable({
  providedIn: 'root'
})
export class DoormanService {

  userSelected = new EventEmitter<Doorman>()
  errorHandler = new GlobalErrorHandler()
  httpOptions: {}
  url = ""

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getAllDoormans(): Observable<Doorman[]> {
    console.log(`${this.url}doorman/all`)
    return this.http.get<any>(
      `${this.url}doorman/all`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp.map(d => new Doorman().deserialize(d))
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getDoormans(id: number): Observable<Doorman[]> {
    return this.http.get<any>(
      `${this.url}doorman/building/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp
      })
    )
  }

  getDoormanID(id: number): Observable<Doorman> {
    return this.http.get<any>(
      `${AppSettings.url}/support/doorman/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        // let support = new Support().deserialize(resp);
        return resp;
      })
    );
  }

  createDoorman(form, id: number): Observable<DoormanResponse> {
    const data = {
      building_id: id,
      first_name: form.first_name,
      last_name: form.last_name,
      cellphone: form.cellphone,
      document_id: form.document_id,
      document: 1,
      status: form.state == 1 ? true : false,
    }
    return this.http.post<DoormanResponse>(
      `${this.url}doormanregister`, data, this.httpOptions
    ).pipe(
      map(data => {
        const message = data["message"]
        const doorman = new Doorman().deserialize(data["doorman"])
        return new DoormanResponse(doorman, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateDoorman(form, id: number): Observable<DoormanResponse> {
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      cellphone: form.cellphone,
      document_id: form.document_id,
      status: form.state == 1 ? true : false,
    }
    return this.http.put<DoormanResponse>(
      `${this.url}doorman/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        const message = data["message"]
        const doorman = new Doorman().deserialize(data["doorman"])
        return new DoormanResponse(doorman, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
