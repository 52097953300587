<div class="container modal-ag">
    <h2>Crear Compañia de Servicios</h2>
    <!--form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off">
            <div *ngIf="!name.valid && name.touched">
                <mat-error *ngIf="name.errors.required">Debe ingresar un nombre válido.</mat-error>
                <mat-error *ngIf="name.errors.minlength">El nombre ingresado debe contener al menos 4 caracteres.</mat-error>
                <mat-error *ngIf="name.errors.maxlength">El nombre ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Content</mat-label>
            <input matInput formControlName="content" autocomplete="off">
            <div *ngIf="!content.valid && content.touched">
                <mat-error *ngIf="name.errors.maxlength">La descripción ingresado supera el total de caracteres permitidos.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="type" >
                <mat-option *ngFor="let type of types" [value]="type.id">
                {{ type.name }}
                </mat-option>
            </mat-select>
            <div *ngIf="!type.valid && type.touched">
                <mat-error *ngIf="type.errors.required">Debe seleccionar un tipo.</mat-error>
            </div>
        </mat-form-field>

        <mat-form-field class="form-group" appearance="outline">
            <mat-label>Pais</mat-label>
            <mat-select formControlName="country" >
                <mat-option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
                </mat-option>
            </mat-select>
            <div *ngIf="!country.valid && country.touched">
                <mat-error *ngIf="country.errors.required">Debe seleccionar un pais.</mat-error>
            </div>
        </mat-form-field>


        <div class="form-group text-right">
            <button mat-raised-button type="submit" class="btn btn-ok d-block w-100" [disabled]="formGroup.invalid">Guardar</button>
            <button mat-raised-button type="button" class="btn btn-outline-dark w-100" (click)="close()">Cancelar</button>
        </div>

    </form-->
</div>