import { Desearizable } from "./desearizable"

export class ServiceCompany implements Desearizable {

    public id: number
    public name: string
    public type: number
    public content: string
    public country_code: string

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }

    getType(): string {
        switch(this.type) {
            case 1: return "Seguridad"
            case 2: return "Salud"
            case 3: return "Bomberos"
            case 4: return "Constructora"
            default: return "-"
        }
    }

}