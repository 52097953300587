import { Injectable, EventEmitter } from '@angular/core'
import { Apartment } from '../models/apartment'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ApartmGroup } from '../models/apartm_group'
import { Package } from '../models/package'
import { GlobalErrorHandler } from '../util/common'
import { ApartmentResponse } from '../reponse/data.response'
import { Bill } from '../models/bill'
import { Delivery } from '../models/delivery'

@Injectable({
  providedIn: 'root'
})
export class ApartmentService {

  userSelected = new EventEmitter<Apartment>()
  errorHandler = new GlobalErrorHandler()
  httpOptions: {}
  url = ""

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, sessionType) {
    //this.url = AppSettings.url + (sessionType ? `/${String(sessionType).split('"').join('')}` : "")
    this.url = AppSettings.url + `${String(sessionType).split('"').join('')}`
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getApartmentBlock(id: number): Observable<Apartment[]> {
    return this.http.get<Apartment[]>(
      `${this.url}building/apartment/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        return data.map(data => new Apartment().deserialize(data))
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getApartmentID(id: number): Observable<Apartment> {
    return this.http.get<Apartment>(
      `${this.url}building/apartment/user/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        let apartment: Apartment = new Apartment().deserialize(resp["apartment"])
        apartment.bills = resp["bills"].map(data => new Bill().deserialize(data))
        apartment.deliveries = resp["deliveries"].map(data => new Delivery().deserialize(data))
        apartment.users = resp["users"]
        apartment.packages = resp["packages"].map(data => new Package().deserialize(data))
        apartment.visits = resp["visits"]
        apartment.building = resp["building"]
        apartment.block = resp["block"]
        return apartment
      })
      , catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createApartment(form, buildingId: number, blockId: number): Observable<ApartmentResponse> {
    const data = {
      name: form.name,
      building_id: buildingId,
      block_id: blockId,
      footage: form.footage,
      coefficient: form.coefficient
    }
    return this.http.post<Apartment>(
      `${this.url}building/apartment`, data, this.httpOptions
    ).pipe(
      map(data => {
        let apartment = new Apartment().deserialize(data["apartment"])
        let message = data["message"]
        return new ApartmentResponse(apartment, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  updateApartment(form, id: number): Observable<ApartmentResponse> {
    const data = {
      name: form.name,
      building_id: form.building_id,
      footage: form.footage,
      coefficient: form.coefficient,
    }
    return this.http.put<Apartment>(
      `${this.url}building/apartment/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        let apartment = new Apartment().deserialize(data["apartment"])
        let message = data["message"]
        return new ApartmentResponse(apartment, message)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  postApartmentDateFilter(form, id, type): Observable<ApartmGroup> {
    return this.http.post<ApartmGroup>(
      `${this.url}/building/apartment/info/${id}/${type}`, form, this.httpOptions
    ).pipe(
      map(data => {
        return data
      })
    )
  }

}
