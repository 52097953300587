<div class="container admin-container">
    <div class="card">
        <div class="card-header">
            <h1>Áreas Comunes</h1>
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <button type="button" class="btn" (click)="createPlace()">Nuevo +</button>
            </div>
        </div>
        <div class="card-body">
            
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let place">{{ place.name }}</td>
                </ng-container>

                <ng-container matColumnDef="price_type">
                    <th mat-header-cell *matHeaderCellDef>Tipo</th>
                    <td mat-cell *matCellDef="let place">{{ place.getType() }}</td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Precio </th>
                    <td mat-cell *matCellDef="let place">{{ place.price | currency }}</td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef>Imagen</th>
                    <td mat-cell *matCellDef="let place">
                        <img [src]="place.image" height="100"/>
                    </td>
                </ng-container>
  
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let place">
                        <a [routerLink]="['booking', place.id]" class="btn"><mat-icon aria-hidden="false">event</mat-icon></a>
                        <button class="btn" type="button" (click)="editPlace(place)"><mat-icon aria-hidden="false">create</mat-icon></button>
                    </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>