<div class="container admin-container sub-container">
    <div class="card pl-0">
        <div class="card-header mt-0">
            <div class="options">
                <input type="search" placeholder="Buscar" (keyup)="applyFilter($event)" autocomplete="off">
                <button type="button" class="btn" (click)="createDoorman()">Nuevo +</button>
            </div>
        </div>
        <div class="card-body">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let doorman">{{ doorman.id }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let doorman">{{ doorman.getName() }}</td>
                </ng-container>

                <ng-container matColumnDef="document_id">
                    <th mat-header-cell *matHeaderCellDef>Documento</th>
                    <td mat-cell *matCellDef="let doorman">{{ doorman.document_id }}</td>
                </ng-container>

                <ng-container matColumnDef="cellphone">
                    <th mat-header-cell *matHeaderCellDef>Celular</th>
                    <td mat-cell *matCellDef="let doorman">{{ doorman.cellphone }}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let doorman">{{ doorman.getStatus() }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let doorman">
                        <button class="btn btn-link" type="button" (click)="editDoorman(doorman)"><mat-icon aria-hidden="false">create</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>