import { Component, OnInit, Input } from '@angular/core'
import Swal from 'sweetalert2'
import { Resident } from 'src/app/core/models/resident'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'

export interface User {
  id: number;
  name: string;
  typeUser: string;
}

interface TypeUser {
  id: number
  name: string
}

const ELEMENT_DATA: User[] = [
  { id: 1, name: 'Emiliano Mrtinez', typeUser: 'Propietario'},
  { id: 2, name: 'Camilo Tellez', typeUser: 'Residente'}
] ;

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html'
})
export class AdminDetailComponent implements OnInit {
  @Input()resident: Resident

  formGroup: UntypedFormGroup
  titleAlert: string = "Este espacio es requerido"
  newForm = false

  displayedColumns: string[] = ['name', 'typeUser']
  dataSource = new MatTableDataSource(ELEMENT_DATA)

  constructor(private fb: UntypedFormBuilder) { 
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm(){
    this.formGroup = this.fb.group({
      'first_name': [null, Validators.required],
      'last_name': [null, Validators.required],
      'email': [null, Validators.required],
      'phone': [ null, Validators.required],
      //'typeUser': [null, Validators.required]
    })
  }

  get first_name(){
    return this.formGroup.get('first_name') as UntypedFormControl
  }
  get last_name(){
    return this.formGroup.get('last_name') as UntypedFormControl
  }
  get email(){
    return this.formGroup.get('email') as UntypedFormControl
  }
  get phone(){
    return this.formGroup.get('phone') as UntypedFormControl
  }
  get typeUser(){
    return this.formGroup.get('typeUser') as UntypedFormControl
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createUser(){
    this.newForm = true
  }

  newUser(){
    this.newForm = true
  }

  onSubmit(form){
    this.showMessage("Creando Usuario", "Por favor espere")
    Swal.showLoading();

  }

  showMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
    })
  }

}
