import {Desearizable} from './desearizable'

export enum Role {
  Owner = 1,
  Lessee = 2,
  Both = 3,
  Employee = 4
}

export class User implements Desearizable {

  public id: number
  public first_name: string
  public last_name: string
  public role: Role
  public phone: string
  public email: string
  public apartment_id: number
  public status: boolean

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getStatus(){
    return this.status ? "Activo" : "Inactivo"
  }

  getName(){
    return `${this.first_name} ${this.last_name}`
  }

  getType(){
    switch(this.role){
      case 1: return 'Arrendador'
      case 2: return 'Arrendatario'
      case 3: return 'Ambos'
      default: return '-'
    }
  }

}