import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppSettings } from '../appsettings';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Admin } from '../models/admin';
import { GlobalErrorHandler } from '../util/common';
import { MessageResponse } from '../reponse/data.response';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  userSelected = new EventEmitter<Admin>()
  errorHandler = new GlobalErrorHandler()


  httpOptions: {};
  /**
   * constructor
   * @param http cliente de manejo de peticiones de http.
   * @param auth referencia a la clase AuthService.
   * @param router librería de angular para manejar rutas.
   */
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '');
    });
    this.updateToken(localStorage.getItem('token'));
  }
  /**
   * updateToken
   * @param token llave que hace referencia al tipo de sesión activa.
   */
  updateToken(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    };
  }

  /**
   * getAmindID
   * @param id referencia del administrador al cual se le estan pidiendo los datos
   */
  getAdminID(id: number): Observable<Admin> {
    return this.http.get<any>(
      `${AppSettings.url}/admin/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp
      })
    );
  }

  getAdmins(id: number): Observable<Admin[]> {
    return this.http.get<Admin[]>(
      `${AppSettings.url}/admin/building/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp
      })
    );
  }

  /**
   * postAdmin
   * @param form  es el formulario que contiene la información del administrador a crear.
   * @param id hace referencia al edificio al cual esta relacionado el administrador.
   */
  createAdmin(form, id: number): Observable<Admin> {
    const data = {
      title: form.title,
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      building_id: id,
      status: form.state == 1 ? true : false,
      password: form.password,
    };
    return this.http.post<Admin>(
      AppSettings.url + '/admin/register', data, this.httpOptions
    ).pipe(
      map(data => {
        return new Admin().deserialize(data["admin"])
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  /**
   * putAdmin
   * @param form  es el formulario que contiene la información del administrador a modificar.
   * @param id hace referencia al administrador que se va a modificar..
   */
  updateAdmin(form, id: number): Observable<Admin> {
    const data = {
      title: form.title,
      first_name: form.first_name,
      last_name: form.last_name,
      status: form.state == 1 ? true : false,
    }
    return this.http.put<Admin>(
      `${AppSettings.url}/admin/${id}`, data, this.httpOptions
    ).pipe(
      map(data => {
        return new Admin().deserialize(data)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  sendActivation(admin: Admin, id: number): Observable<MessageResponse> {
    const data = {
      id: admin.id,
      building_id: id
    }
    return this.http.post<MessageResponse>(
      `${AppSettings.url}/admin/support/mail/activate`, data, this.httpOptions
    ).pipe(
      map(data => {
        return new MessageResponse(null, data.message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
