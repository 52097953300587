import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import Swal from 'sweetalert2'
import { ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { BlockService } from 'src/app/core/services/block.service'
import { Apartment } from 'src/app/core/models/apartment'
import { showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html'
})
export class BillComponent implements OnInit {

  @Input() apartment: Apartment
  id: number
  titleAlert: string = 'Este espacio es requerido'

  newForm = false
  loading = false

  displayedColumns: string[] = ['name', 'note', 'bill_type', 'created_at','delivery_at', 'delivery_date']
  dataSource = new MatTableDataSource()

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private fb: UntypedFormBuilder, private apartmentservice: ApartmentService, private route: ActivatedRoute, private blockservice: BlockService, private datePipe: DatePipe) {
    this.loading = true;
    //this.route.paramMap.pipe().subscribe(params => {
    //  this.id = Number(params.get('id'));
    //});
  }

  ngOnInit(): void {
  }

  createForm() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  newReceipts() {
    this.newForm = true;
  }

  onFilter(form) {
    form.date_end = this.datePipe.transform(form.date_to,"dd-MM-yyyy")
    form.date_begin = this.datePipe.transform(form.date_from,"dd-MM-yyyy")
    Swal.showLoading()
    this.apartmentservice.postApartmentDateFilter(form, this.id, 1).subscribe(
      data => {
        this.dataSource.data = data.visit;
        Swal.close();
      }, error => {
        showMessage("¡Error!", error, false)
      }
    )
  }

  onSubmit(form) {
    Swal.showLoading()
  }

  ngOnChanges(){
    this.dataSource.data = this.apartment.bills
  }

}
