import { Component, OnInit, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { City } from 'src/app/core/models/city'
import { BuildingService } from 'src/app/core/services/building.service'
import Swal from 'sweetalert2'
import { BuildingDialogData, DataType, showMessage } from 'src/app/core/util/common'
import { Building } from 'src/app/core/models/building'

@Component({
  selector: 'app-edit-building',
  templateUrl: './edit-building.component.html'
})
export class EditBuildingComponent implements OnInit {

  formGroup: UntypedFormGroup
  cities: City[]
  building: Building

  states = [
    {id: 1, name: 'Activo'},
    {id: 0, name: 'Inactivo'}
  ]

  types = [
    {id: 1, name: 'Residencial'},
    {id: 2, name: 'Negocio'}
  ]

  constructor(private fb: UntypedFormBuilder, private buildingService: BuildingService, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<EditBuildingComponent>) { 
    this.cities = Object.assign([], this.data.cities)
    this.cities.splice(0,1)
    this.building = this.data.building
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm() {
    this.formGroup = this.fb.group({
      'name':[this.data.building.name, [Validators.required, Validators.maxLength(60), Validators.minLength(3)] ],
      'city_id': [this.data.building.city_id, Validators.required],
      'type': [this.data.building.type, [Validators.required]],
      'email': [this.data.building.email, [Validators.email, Validators.maxLength(100)]],
      'address': [this.data.building.address, [Validators.required, Validators.maxLength(80), Validators.minLength(4)] ],
      'neighborhood':[this.data.building.neighborhood, [Validators.required, Validators.maxLength(60), Validators.minLength(5)] ],
      'state': [this.data.building.status ? 1 : 0, [Validators.required]],
      'space_allowed': [this.data.building.space_allowed, [Validators.required]]
    })
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  get city() {
    return this.formGroup.get('city_id') as UntypedFormControl
  }

  get email() {
    return this.formGroup.get('email') as UntypedFormControl
  }

  get address() {
    return this.formGroup.get('address') as UntypedFormControl
  }

  get neighborhood() {
    return this.formGroup.get('neighborhood') as UntypedFormControl
  }

  get state() {
    return this.formGroup.get('state') as UntypedFormControl
  }

  get type() {
    return this.formGroup.get('type') as UntypedFormControl
  }

  get space() {
    return this.formGroup.get('space_allowed') as UntypedFormControl
  }

  getSpace() {
    if (this.space.value >= 1000) {
      return Math.round(this.space.value / 1000) + 'Gb'
    }
    return this.space.value
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'Gb'
    }
    return value
  }

  onSubmit(form) {
    Swal.showLoading()
    this.buildingService.updateBuilding(form, this.data.building.id).subscribe(data => {
        showMessage('¡Completo!', data.message, true)
        this.dialogRef.close(data.building)
      }, error => {
        showMessage('¡Error!', error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}