import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DataType, showMessage } from 'src/app/core/util/common'
import { ServiceCompany } from 'src/app/core/models/service.company'
import { ContactService } from 'src/app/core/services/contact.service'
import { CreateServiceCompanyComponent } from './create-service-company/create-service-company.component'
import { EditServiceCompanyComponent } from './edit-service-company/edit-service-company.component'

@Component({
  selector: 'app-service-company',
  templateUrl: './service-company.component.html'
})
export class ServiceCompanyComponent implements OnInit {

  loading = true
  types: DataType[] = [
    { id: 1, name: 'Seguridad' },
    { id: 2, name: 'Salud' },
    { id: 3, name: 'Bomberos' },
    { id: 4, name: 'Constructora' }
  ]

  countries: any[] = [
    {id: 'co', name: 'Colombia'},
  ]

  displayedColumns: string[] = ['id', 'name', 'country', 'type', 'actions']
  dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator

  constructor(private contactService: ContactService, public dialog: MatDialog) { 
    this.loadData()
  }

  ngOnInit(): void {
  }

  loadData() {
    this.contactService.getServiceCompanies().subscribe(data => {
      this.loading = false
      this.dataSource.data = data
    }, error => {
      showMessage("¡Error!", error, false)
    })
  }

  createServiceCompany() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { types: this.types, countries: this.countries }
    const dialogRef = this.dialog.open(CreateServiceCompanyComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(serviceCompany => {
      if(serviceCompany) {
        const data = this.dataSource.data
        data.push(serviceCompany)
        this.dataSource.data = data
      }
    })
  }

  editServiceCompany(serviceCompany: ServiceCompany) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '450px'
    dialogConfig.data = { types: this.types, countries: this.countries, company: serviceCompany }
    const dialogRef = this.dialog.open(EditServiceCompanyComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(serviceCompany => {
      if(serviceCompany) {
        const data = this.dataSource.data
        let index = data.findIndex((item: ServiceCompany) => item.id == serviceCompany.id)
        data[index] = serviceCompany
        this.dataSource.data = data
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

}
