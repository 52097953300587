import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { AppSettings } from '../appsettings'
import { AuthService } from './auth.service'
import { Message } from 'src/app/core/models/message'
import { File } from 'src/app/core/models/file'
import { GlobalErrorHandler } from '../util/common'
import { MessageResponse } from '../reponse/data.response'

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  httpOptions: {}
  errorHandler = new GlobalErrorHandler()
  session = ""
  token = ""

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session);
    });
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'));
  }

  updateToken(token, role) {
    this.token = token
    this.session = String(role).replace('"','').replace('"','')
    console.log(this.session)
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getUploadFileToken() {
    return  {
      headers: new HttpHeaders({
        'access-token': this.token,
        'enctype': 'multipart/form-data'
      })
    };
  }

  getUploadToken() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': this.token
      })
    };
  }

  getMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(
      `${AppSettings.url}/message/admin`, this.httpOptions
    ).pipe(
      map(resp => {
        return resp.map(message => new Message().deserialize(message))
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getMessage(id: number): Observable<MessageResponse> {
    return this.http.get<Message>(
      `${AppSettings.url}/message/admin/${id}`, this.httpOptions
    ).pipe(
      map(data => {
        var mmessage = new Message().deserialize(data["mmessage"])
        var files = (data["files"]).map((file:any) => new File().deserialize(file))
        var message = data["message"]
        return new MessageResponse(mmessage, message, files)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  sendMessage(form, blocks: number[], apartments: number[], files: any[]): Observable<MessageResponse> {

    const formData = new FormData()
    formData.append('subject', form.subject)
    formData.append('message', form.message)
    formData.append('role', form.role)
    formData.append('user_type', form.type)
    formData.append('blocks', JSON.stringify(blocks))
    formData.append('apartments', JSON.stringify(apartments))
    files.forEach((file) => { formData.append('files', file) })

    return this.http.post<MessageResponse>(
      `${AppSettings.url}/message`, formData, this.getUploadFileToken()
    ).pipe(
      map(data => {
        var mmessage = new Message().deserialize(data["mmessage"])
        var message = data["message"]
        return new MessageResponse(mmessage, message)
      }),
      catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}
