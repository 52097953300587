import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {MatPaginator} from '@angular/material/paginator'
import {MatTableDataSource} from '@angular/material/table'
import {BookingService} from '../../../../core/services/booking.service'
import {BookingAdmin} from "../../../../core/models/booking.admin"

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html'
})
export class ReservationComponent implements OnInit {
  @Input() id: number;
  @Output() reservationEvent = new EventEmitter();
  session = JSON.parse(localStorage.getItem('session'));
  loading = false;
  
  displayedColumns: string[] = ['id', 'name', 'date', 'status', 'actions'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  getStatus(status): string {
    if (status) {
      return 'activo';
    } else {
      return 'inactivo';
    }
  }

  ngOnInit(): void {
  }
  /*
  constructor(private bookinservice: BookingService, private bookingadminservice: BookingAdminService ) {
  }

  ngOnInit(): void {
    this.loading = true;
    if(Object.keys(this.session)[0] === 'support') {
      this.bookinservice.getBooking(this.id).subscribe(
        data => {
          this.dataSource.data = data;
        }
      );
    }
    if(Object.keys(this.session)[0] === 'admin') {
      this.bookingadminservice.getBooking().subscribe(
        data => {
          this.dataSource.data = data;
        }
      );
    }
    this.loading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  newReservation() {
    this.title = 'Nueva Reserva';
    this.modalFormReservation = ModalFormReservation.createReservation;
  }

  editReservation() {
    this.title = 'Eitar Reserva';
    this.modalFormReservation = ModalFormReservation.editReservation;
  }*/

}
