import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GlobalErrorHandler } from '../util/common'
import { MessageResponse, ServiceRequestReponse } from '../reponse/data.response'
import { AuthService } from './auth.service'
import { AppSettings } from '../appsettings'
import { ServiceRequest } from '../models/service.request'
import { catchError, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { RequestMessage } from '../models/request.message'

@Injectable({
    providedIn: 'root'
  })
export class ServiceRequestService {
  
  httpOptions: {}
  errorHandler = new GlobalErrorHandler()
  session = ""
  token = ""
  
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
    auth.loginEvent.subscribe(session => {
      this.updateToken(session ? session.token : '', session.session)
    })
    this.updateToken(localStorage.getItem('token'), localStorage.getItem('type'))
  }

  updateToken(token, role) {
    this.token = token
    this.session = String(role).replace('"','').replace('"','')
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': token
      })
    }
  }

  getUploadFileToken() {
    return  {
      headers: new HttpHeaders({
        'access-token': this.token,
        'enctype': 'multipart/form-data'
      })
    }
  }

  getUploadToken() {
    return  {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': this.token
      })
    }
  }

  getRequests(type: number): Observable<ServiceRequestReponse> {
    return this.http.get<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/requests/${type}`, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const requests = resp["requests"].map(request => new ServiceRequest().deserialize(request))
        return new ServiceRequestReponse(message, requests)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getDoormanRequests(type: number): Observable<ServiceRequestReponse> {
    return this.http.get<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/requests/doorman/${type}`, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const requests = resp["requests"].map(request => new ServiceRequest().deserialize(request))
        return new ServiceRequestReponse(message, requests)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getRequest(id: number): Observable<ServiceRequestReponse> {
    return this.http.get<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const request = new ServiceRequest().deserialize(resp["request"])
        const messages = resp["messages"].map(message => new RequestMessage().deserialize(message))
        return new ServiceRequestReponse(message, request, messages)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  getDoormanRequest(id: number): Observable<ServiceRequestReponse> {
    return this.http.get<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/doorman/${id}`, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const request = new ServiceRequest().deserialize(resp["request"])
        const messages = resp["messages"].map(message => new RequestMessage().deserialize(message))
        return new ServiceRequestReponse(message, request, messages)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  closeRequest(id: number): Observable<ServiceRequestReponse> {
    const data = {
    }
    return this.http.post<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/close/${id}`, data, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const request = new ServiceRequest().deserialize(resp["request"])
        return new ServiceRequestReponse(message, request)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  closeDoormanRequest(id: number): Observable<ServiceRequestReponse> {
    const data = {
    }
    return this.http.post<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/doorman/close/${id}`, data, this.httpOptions
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const request = new ServiceRequest().deserialize(resp["request"])
        return new ServiceRequestReponse(message, request)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createMessage(form, id: number, files: any[]): Observable<ServiceRequestReponse> {
    const formData = new FormData()
    formData.append('message', form.message)
    files.forEach((file) => { formData.append('files', file) })

    return this.http.post<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/message/${id}`, formData, this.getUploadFileToken()
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const messages = resp["messages"].map(message => new RequestMessage().deserialize(message))
        return new ServiceRequestReponse(message, [], messages)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

  createDoormanMessage(form, id: number, files: any[]): Observable<ServiceRequestReponse> {
    const formData = new FormData()
    formData.append('message', form.message)
    files.forEach((file) => { formData.append('files', file) })

    return this.http.post<ServiceRequestReponse>(
      `${AppSettings.url}/admin/building/main/request/doorman/message/${id}`, formData, this.getUploadFileToken()
    ).pipe(
      map(resp => {
        const message = resp["message"]
        const messages = resp["messages"].map(message => new RequestMessage().deserialize(message))
        return new ServiceRequestReponse(message, [], messages)
      }), catchError(error => {
        return this.errorHandler.handleError(error)
      })
    )
  }

}