import { Component, OnInit, Inject } from '@angular/core'
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import Swal from 'sweetalert2'
import { BlockService } from 'src/app/core/services/block.service'
import { BuildingDialogData, showMessage } from 'src/app/core/util/common'

@Component({
  selector: 'app-create-block',
  templateUrl: './create-block.component.html'
})
export class CreateBlockComponent implements OnInit {

  formGroup: UntypedFormGroup
 
  constructor(private fb: UntypedFormBuilder, private blockService: BlockService, @Inject(MAT_DIALOG_DATA) public data: BuildingDialogData, public dialogRef: MatDialogRef<CreateBlockComponent>) { 
    this.createForm();
  }

  ngOnInit(): void {
  }
  
  createForm() {
    this.formGroup = this.fb.group({
      'name': [null, [Validators.required, Validators.maxLength(60), Validators.minLength(1)]],
    });
  }

  get name() {
    return this.formGroup.get('name') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    const send = {
      name: form.name,
      building_id: this.data.id
    }
    this.blockService.createBlock(send).subscribe(
      data => {
        this.formGroup.reset()
        Swal.close()
        showMessage('¡Guardado!', data.message, true)
        this.dialogRef.close(data.block)
      },
      error => {
        showMessage('¡Error!', error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
