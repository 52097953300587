import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Building } from 'src/app/core/models/building'
import { ContactService } from 'src/app/core/services/contact.service'
import { showMessage } from 'src/app/core/util/common'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-assign-service-company',
  templateUrl: './assign-service-company.component.html'
})
export class AssignServiceCompanyComponent implements OnInit {

  formGroup: UntypedFormGroup
  companies: any[]
  dependencies: any[]
  building: Building

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AssignServiceCompanyComponent>) {
    this.building = this.data.building
    this.loadServiceCompanies()
  }

  ngOnInit(): void {
    this.createForm()
  }

  loadServiceCompanies() {
    this.contactService.getServiceCompaniesByCountry(this.building.country_code).subscribe(
      data => {
        this.companies = data
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  selectCompany(company) {
    this.contactService.getServiceCompany(company.id).subscribe(
      data => {
        this.dependencies = data.dependencies
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  createForm() {
    this.formGroup = this.fb.group({
      'company':[null, [Validators.required]],
      'dependency':[null, [Validators.required]],
    })
  }

  get company() {
    return this.formGroup.get('company') as UntypedFormControl
  }

  get dependency() {
    return this.formGroup.get('dependency') as UntypedFormControl
  }

  onSubmit(form) {
    Swal.showLoading()
    this.contactService.assignDependency(form, this.building.id).subscribe(
      data => {
        Swal.close()
        showMessage("Completo!" , data.message, true)
        this.dialogRef.close(data.dependency)
      }, error => {
        showMessage("¡Error!" , error, false)
      }
    )
  }

  close(): void {
    this.dialogRef.close(false)
  }

}