<div class="container modal-ag">
    <h2>Detalle de la reserva</h2>

    
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Area Cómun</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ place.name }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Bloque</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.block }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Apartemnto</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.apartment }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Usuario</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.users }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Fecha</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.date }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Tiempo</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.time_begin }} - {{ booking.time_end }}</label>
        </div>
        <hr>
        <h3>Checkin</h3>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Hora</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.date_checkin }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Portero</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.date_checkin }}</label>
        </div>
        <hr>
        <h3>Checkout</h3>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Hora</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.date_checkout }}</label>
        </div>
        <div class="form-group">
            <label for="place" class="col-lg-4 col-md-5 col-sm-5 col-form-label label">Portero</label>
            <label id="place" class="col-lg-8 col-md-7 col-sm-12 col-form-label">{{ booking.date_checkout }}</label>
        </div>
</div>
