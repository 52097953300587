import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Apartment } from 'src/app/core/models/apartment'
import { Block } from 'src/app/core/models/block'
import { Building } from 'src/app/core/models/building'
import { ApartmentService } from 'src/app/core/services/apartment.service'
import { BuildingService } from 'src/app/core/services/building.service'
import Swal from 'sweetalert2'
import { CreateApartmentComponent } from '../../shared/apartment/create-apartment/create-apartment.component'
import { EditApartmentComponent } from '../../shared/apartment/edit-apartment/edit-apartment.component'
import { CreateBlockComponent } from '../../shared/block/create-block/create-block.component'
import { EditBlockComponent } from '../../shared/block/edit-block/edit-block.component'

@Component({
  selector: 'app-admin-aparment',
  templateUrl: './admin-aparment.component.html'
})
export class AdminApartmentComponent implements OnInit {

  apartments: Apartment[]
  selectedBlock: Block
  loadingApartments = false
  building: Building

  constructor(private buildingService: BuildingService, private apartmentService: ApartmentService, public dialog: MatDialog) {
    this.buildingService.getBuilding().subscribe(data => {
      this.building = data
    }, error => {
      this.showMessage("Error", error)
    })
  }

  ngOnInit(): void {
  }

  createBlock() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(CreateBlockComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.building.blocks.push(result)
      }
    })
  }

  editBlock(block: Block) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {id: this.building.id, block: block}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(EditBlockComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const data = this.building.blocks
        let index = data.findIndex((item: Block) => item.id == result.id)
        data[index] = result
        this.building.blocks = data
      }
    })
  }

  createApartment() {
    console.log("Crear apartamento")
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {building: this.building, block: this.selectedBlock}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(CreateApartmentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.apartments.push(result)
      }
    })
  }

  editApartment(apartment: Apartment) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {building: this.building, block: this.selectedBlock, apartment: apartment}
    dialogConfig.width = '400px'
    const dialogRef = this.dialog.open(EditApartmentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        let index = this.apartments.findIndex((item: Apartment) => item.id == result.id)
        this.apartments[index] = result
      }
    })
  }

  showApartments(block: Block) {
    this.selectedBlock = block
    this.loadingApartments = true
    this.apartments = []
    this.apartmentService.getApartmentBlock(this.selectedBlock.id).subscribe(
      data => {
        this.apartments = data
        this.loadingApartments = false
      }, error => {
        this.showMessage("", error)
        this.loadingApartments = false
      }
    )
  }

  showMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
    })
  }

}